import styled from "styled-components";

export const HeaderWrapper = styled.header`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 40px 20px 20px;
`

export const ProfileWrapper = styled.div`
    display: flex;;
    gap: 8px;
    cursor: pointer;
    
    img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        object-fit: cover;
    }
`

export const ProfileInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #485184;

    h1 {
        font-size: 14px;
    }

    span {
        font-size: 12px;
    }
`