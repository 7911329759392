import styled from "styled-components";

export const ProdutionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 20px;
`;

export const ProductionTitle = styled.h4`
    width: 100%;
    text-align: center;
    background-color: #485184;
    padding: 8px;
    color: #fff;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

`

export const ProductionDetails = styled.div`
    display: flex;
    width: 100%;
    gap: 30px;
    justify-content: center;
`;

export const ProductionDetail = styled.div`
    display: flex;
    flex-direction: column; 
    justify-content: space-between;

    span:not(:first-child) {
        text-align: center;
        font-weight: bold;
    }
`;

export const SlaWrapper = styled.div`
    width: 100%;
    display: flex;
    gap: 30px;
    align-items: center;
    justify-content: space-between;

    span {
        font-weight: bold;
    }
`;

export const FlexColumnWrapper = styled.div`
    display: flex;
    gap: 30px;
    align-items: center;
    flex-direction: column;
    flex: 20

    span {
        font-weight: bold;
    }
`

export const SlasWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;

    span {
        text-align: center;
    }
`;

export const SlaDetail = styled.div`
    display: flex;
    flex-direction: column; 
    justify-content: space-between;
    align-items: center;
    
    span:first-child {
        text-align: center;
        font-weight: bold;
    }

    span:last-child {
        font-weight: normal;
        
        text-align: center;
    }
`;


export const LineSection = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    gap: 30px;
`;

export const Monitor = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
`;