import styled from "styled-components";
// @ts-ignore
import nexyonLogo from '../../assets/nexyon_logo.png';

export const LoginContent = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    box-shadow: 0px 0px 10px 1px rgba(0,0,0,.2);
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    gap: 40px;
`;

export const WelcomeMessage = styled.h1`
    font-size: 20px;
    font-weight: 500;;
`

export const Logo = styled.div`
    background-image: url(${nexyonLogo});
    background-repeat: no-repeat;
    width: 210px;
    height: 53px;
`;

export const LoginForm = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 300px;
    
    & > *:last-child {
        margin-top: 20px;
    }
`

export const AuthWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
`

export const AuthForm = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 10px;
` 

export const AuthInput = styled.input`
    width: 2em;
    height: 2em;
    text-align: center;
    font-size: 1em;
    border: 1px solid #ccc;
`;

export const PasswordInfoWrapper = styled.div`
    font-size: 12px;
    color: red;
    text-align: left;
    width: 100%;

    p {
        text-align: center;
        padding-bottom: 8px;
    }
`