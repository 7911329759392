
import styled from 'styled-components'

export const Section = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
`

export const GraphicsWrapper = styled.div`
    display: flex;
    gap: 30px;
    width: 100%;
`

export const TodayWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    height: 300px;
    flex: 1;
`

export const MonthLine = styled.div`
    height: 300px;
    flex: 2;
`

export const FunnelWrapper = styled.div`
    display: flex;
    width: 100%;;
`

export const Funnel = styled.div`
    flex: 1;
    display: flex;
    text-align: center;
    flex-direction: column;
`

export const Title = styled.div`
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
`