import styled from "styled-components";

export const GraficWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`; 

export const ConsultaResultWrapper = styled.div`
    flex-grow: 1;
    background-color: #fff;
    border-radius: 8px;
    padding: 10px;
    overflow-y: auto;
    max-height: calc(100vh - 15vh);
    display: flex;
    flex-direction: column;
    gap: 20px;
`