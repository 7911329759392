import Chart, { ChartConfiguration } from 'chart.js/auto';
import React, { useEffect, useRef } from 'react';

interface LineChartProps {
  data: {
    total: number[];
  };
}

const VolumeDiarioHora: React.FC<LineChartProps> = ({ data }) => {
  const chartRef = useRef<HTMLCanvasElement>(null);
  const chartInstance = useRef<Chart<'bar', unknown> | null>(null);

  useEffect(() => {
    if (chartRef && chartRef.current) {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }

      const ctx = chartRef.current.getContext('2d');
      if (ctx) {
        chartInstance.current = new Chart(ctx, {
          type: 'bar',
          data: {
            labels: Array.from({ length: data?.total?.length }, (_, i) => (i).toString()),
            datasets: [
              {
                label: 'Volume de Sessão Por Hora',
                data: data.total,
                borderColor: '#009688',
                backgroundColor: '#009688',
                borderWidth: 2,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              y: {
                beginAtZero: true,
              },
            },
            plugins: {
              tooltip: {
                callbacks: {
                  label: (context) => {
                    const label = context.dataset.label || '';
                    const value = context.parsed.y || 0;
                    return ` ${value} sessões`;
                  },
                },
              },
              legend: {
                display: false,
              },
            },
          },
        } as ChartConfiguration<'bar', number[], string>);
      }
    }

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [data]);

  return <canvas ref={chartRef} />;
};

export default VolumeDiarioHora;
