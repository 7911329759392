import styled from "styled-components";

export const SideWrapper = styled.aside`
    height: 100vh;
    width: 270px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

export const SideContent = styled.div`

`

export const SideFooter = styled.div`
    color: #FF9E00;
    font-weight: bold;
    display: flex;
    font-size: 12px;
    justify-content: center;
    padding-bottom: 20px;
`

export const SideHeader = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;
`

export const MenuWrapper = styled.div`
    display: flex;
    flex-direction: column;
`