import { useEffect, useState } from "react";
import api from "../../service/api";
import BarChart from "../Chart/Bars";
import VolumeAndamentoConcluidas from "../Chart/Bars/volumeAndamentoConcluidas";
import LineChart from "../Chart/Lines";
import DropDown from "../Dropdown";
import { DropDownWrapper, GraphicsWrapper, HeaderLine, MonthLine, Section, TodayWrapper } from "./style";

type Item =
  | {
    datetime: string;
    quantity: number;
  }
  | {
    datetime: string;
    partners: {
      partner: string;
      quantity: number;
    }[];
  };

export const DashboardPage = () => {
  const data = { received: 0, downloaded: 0, finished: 0 };
  const [generalData, setGeneralData] = useState<any>(data);
  const [generalBFData, setBFGeneralData] = useState<any>(data);
  const [generalFordData, setGeneralFordData] = useState<any>(data);
  const [generalHarleyData, setGeneralHarleyData] = useState<any>(data);
  const [monthBFData, setMonthBFData] = useState<any>(data);
  const [monthFordData, setMonthFordData] = useState<any>(data);
  const [monthHarleyData, setMonthHarleyData] = useState<any>(data);
  const [generalMonthData, setGeneralMonthData] = useState<any>();
  const [mode, setMode] = useState("diario");

  const labels = ["Recebidos", "Baixados", "Finalizados"];
  const [lastMonth, setLastMonth] = useState<any>();
  const [bfData, setBfData] = useState<any>({
    received: 0,
    downloaded: 0,
    finished: 0,
  });
  const [bfMonthData, setBfMonthData] = useState<any>({
    received: 0,
    downloaded: 0,
    finished: 0,
  });
  const [fordData, setFordDate] = useState<any>({
    received: 0,
    downloaded: 0,
    finished: 0,
  });
  const [fordDataMonth, setFordDateMonth] = useState<any>({
    received: 0,
    downloaded: 0,
    finished: 0,
  });
  const [harleyData, setHarleyData] = useState<any>({
    received: 0,
    downloaded: 0,
    finished: 0,
  });
  const [harleyDataMonth, setHarleyDataMonth] = useState<any>({
    received: 0,
    downloaded: 0,
    finished: 0,
  });

  function compareDate(dateString: string) {
    const currentDate = new Date();

    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const day = currentDate.getDate().toString().padStart(2, "0");
    const currentDateString = `${year}-${month}-${day}`;

    return dateString === currentDateString;
  }

  function sumQuantities(items: Item[]): number[] {
    const sums: number[] = [];

    for (const item of items) {
      let sum = 0;

      if ("quantity" in item) {
        sum += item.quantity;
      } else if ("partners" in item) {
        for (const partner of item.partners) {
          sum += partner.quantity;
        }
      }

      sums.push(sum);
    }

    return sums;
  }

  const getValueByDay = (data: any) => {
    const day = new Date().getDate();

    if (!data || data.length === 0) {
      return 0;
    }

    return data[day - 1];
  }

  useEffect(() => {

    if (mode === "diario") {
      let dadosMensalTransformados: any = {
        running: [],
        done: [],
        not_done: [],
        received: [],
      };

      api
        .get(`/dashboard/v3/stared-finished-not-finished/None/True/None`)
        .then((response) => {
          response.data?.forEach((dados: any) => {
            dadosMensalTransformados.running = dados.running?.map((item: any) => item < 0 ? 0 : item);
            dadosMensalTransformados.done = dados.done?.map((item: any) => item < 0 ? 0 : item);
            dadosMensalTransformados.not_done = dados.not_done?.map((item: any) => item < 0 ? 0 : item);
            dadosMensalTransformados.received = dados.received?.map((item: any) => item < 0 ? 0 : item);
            setGeneralData({
              running: [getValueByDay(dados.running?.map((item: any) => item < 0 ? 0 : item))],
              done: [getValueByDay(dados.done?.map((item: any) => item < 0 ? 0 : item))],
              not_done: [getValueByDay(dados.not_done?.map((item: any) => item < 0 ? 0 : item))],
              received: [getValueByDay(dados.received?.map((item: any) => item < 0 ? 0 : item))],
            })
          });

          setGeneralMonthData(dadosMensalTransformados);
        });


      const dadosTransformadosBFMensal: any = {
        running: [],
        done: [],
        not_done: [],
        received: [],
      };

      api
        .get("/dashboard/v3/stared-finished-not-finished/bf/True/None")
        .then((response) => {
          response.data?.forEach((dados: any) => {
            dadosTransformadosBFMensal.running = dados.running?.map((item: any) => item < 0 ? 0 : item);
            dadosTransformadosBFMensal.done = dados.done?.map((item: any) => item < 0 ? 0 : item);
            dadosTransformadosBFMensal.not_done = dados.not_done?.map((item: any) => item < 0 ? 0 : item);
            dadosTransformadosBFMensal.received = dados.received?.map((item: any) => item < 0 ? 0 : item);

            setBFGeneralData({
              running: [getValueByDay(dados.running?.map((item: any) => item < 0 ? 0 : item))],
              done: [getValueByDay(dados.done?.map((item: any) => item < 0 ? 0 : item))],
              not_done: [getValueByDay(dados.not_done?.map((item: any) => item < 0 ? 0 : item))],
              received: [getValueByDay(dados.received?.map((item: any) => item < 0 ? 0 : item))],
            });
          });

          setMonthBFData(dadosTransformadosBFMensal);
        });




      const dadosTransformadosFordMensal: any = {
        running: [],
        done: [],
        not_done: [],
        received: [],
      };

      api
        .get("/dashboard/v3/stared-finished-not-finished/ford/True/None")
        .then((response) => {
          response.data?.forEach((dados: any) => {
            dadosTransformadosFordMensal.running = dados.running?.map((item: any) => item < 0 ? 0 : item);
            dadosTransformadosFordMensal.done = dados.done?.map((item: any) => item < 0 ? 0 : item);
            dadosTransformadosFordMensal.not_done = dados.not_done?.map((item: any) => item < 0 ? 0 : item);
            dadosTransformadosFordMensal.received = dados.received?.map((item: any) => item < 0 ? 0 : item);
            setGeneralFordData({
              running: [getValueByDay(dados.running?.map((item: any) => item < 0 ? 0 : item))],
              done: [getValueByDay(dados.done?.map((item: any) => item < 0 ? 0 : item))],
              not_done: [getValueByDay(dados.not_done?.map((item: any) => item < 0 ? 0 : item))],
              received: [getValueByDay(dados.received?.map((item: any) => item < 0 ? 0 : item))],
            });
          });

          setMonthFordData(dadosTransformadosFordMensal);
        });


      const dadosTransformadosHarleyMensal: any = {
        running: [],
        done: [],
        not_done: [],
        received: [],
      };

      api
        .get("/dashboard/v3/stared-finished-not-finished/harley/True/None")
        .then((response) => {
          response.data?.forEach((dados: any) => {
            dadosTransformadosHarleyMensal.running = dados.running?.map((item: any) => item < 0 ? 0 : item);
            dadosTransformadosHarleyMensal.done = dados.done?.map((item: any) => item < 0 ? 0 : item);
            dadosTransformadosHarleyMensal.not_done = dados.not_done?.map((item: any) => item < 0 ? 0 : item);
            dadosTransformadosHarleyMensal.received = dados.received?.map((item: any) => item < 0 ? 0 : item);

            setGeneralHarleyData({
              running: [getValueByDay(dados.running?.map((item: any) => item < 0 ? 0 : item))],
              done: [getValueByDay(dados.done?.map((item: any) => item < 0 ? 0 : item))],
              not_done: [getValueByDay(dados.not_done?.map((item: any) => item < 0 ? 0 : item))],
              received: [getValueByDay(dados.received?.map((item: any) => item < 0 ? 0 : item))],
            });
          });

          setMonthHarleyData(dadosTransformadosHarleyMensal);

        });
    } else if (mode === "mensal") {
      const initialData = { running: Array(12).fill(0), done: Array(12).fill(0), not_done: Array(12).fill(0), received: Array(12).fill(0) };
      let monthlyData: any = {
        general: { ...initialData },
        bf: { ...initialData },
        ford: { ...initialData },
        harley: { ...initialData }
      };

      setGeneralMonthData(monthlyData.general);
      setMonthBFData(monthlyData.bf);
      setMonthFordData(monthlyData.ford);
      setMonthHarleyData(monthlyData.harley);

      const fetchDataForMonth = async (month: any, type: any, setState: any) => {
        const response = await api.get(`/dashboard/v3/stared-finished-not-finished/${type}/True/${month}`);
        const json = await response.data;
        const newData = {
          running: json.reduce((acc: any, item: any) => acc + item.running.reduce((sum: any, num: any) => sum + num, 0), 0),
          done: json.reduce((acc: any, item: any) => acc + item.done, 0),
          not_done: json.reduce((acc: any, item: any) => acc + item.not_done.reduce((sum: any, num: any) => sum + num, 0), 0),
          received: json.reduce((acc: any, item: any) => acc + item.received.reduce((sum: any, num: any) => sum + num, 0), 0),
        };
        updateState(setState, newData, month - 1);
      };

      const types = ['None', 'bf', 'ford', 'harley'];
      const stateSetters = [setGeneralMonthData, setMonthBFData, setMonthFordData, setMonthHarleyData];

      const getAllMonthsData = async () => {
        for (let month = 1; month <= new Date().getMonth() + 1; month++) {
          types.forEach((type, index) => {
            fetchDataForMonth(month, type, stateSetters[index]);
          });
        }
      };

      getAllMonthsData();
    }

  }, [mode]);

  const updateState = (setState: any, newData: any, index: number) => {
    setState((prev: any) => ({
      running: [...prev.running.slice(0, index), newData.running, ...prev.running.slice(index + 1)],
      done: [...prev.done.slice(0, index), newData.done, ...prev.done.slice(index + 1)],
      not_done: [...prev.not_done.slice(0, index), newData.not_done, ...prev.not_done.slice(index + 1)],
      received: [...prev.received.slice(0, index), newData.received, ...prev.received.slice(index + 1)]
    }));
  };

  return (
    <>
      <Section>
        <HeaderLine>
          <h3>Geral</h3>
          <DropDownWrapper>
            <DropDown titleHeader="Modo de visualização" options={[{ label: 'Diário', value: 'diario' }, { label: 'Mensal', value: 'mensal' }]} onChange={setMode} />
          </DropDownWrapper>
        </HeaderLine>
        <GraphicsWrapper>
          <TodayWrapper>
            <VolumeAndamentoConcluidas data={generalData} />
          </TodayWrapper>
          <MonthLine>
            <LineChart data={generalMonthData || data} />
          </MonthLine>
        </GraphicsWrapper>
      </Section>
      <Section>
        <h3>Bradesco</h3>
        <GraphicsWrapper>
          <TodayWrapper>
            <BarChart data={generalBFData} label={["Hoje"]} />
          </TodayWrapper>
          <MonthLine>
            <LineChart data={monthBFData} />
          </MonthLine>
        </GraphicsWrapper>
      </Section>
      <Section>
        <h3>Ford</h3>
        <GraphicsWrapper>
          <TodayWrapper>
            <BarChart data={generalFordData} label={["Hoje"]} />
          </TodayWrapper>
          <MonthLine>
            <LineChart data={monthFordData} />
          </MonthLine>
        </GraphicsWrapper>
      </Section>
      <Section>
        <h3>Harley</h3>
        <GraphicsWrapper>
          <TodayWrapper>
            <BarChart data={generalHarleyData} label={["Hoje"]} />
          </TodayWrapper>
          <MonthLine>
            <LineChart data={monthHarleyData} />
          </MonthLine>
        </GraphicsWrapper>
      </Section>
    </>
  );
};
