import styled from "styled-components";

export const DropDownWrapper = styled.div`
  width: 100%;
  select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    &:focus {
      outline: none;
      border-color: #007bff;
    }
  }
`;