import { HeaderWrapper, ProfileInfo, ProfileWrapper } from "./style"

export const Header = () => {
    return <HeaderWrapper>
        <ProfileWrapper>
            <ProfileInfo>
                <h1>Olá, Bem-vindo!</h1>
                {/* <span>admin</span> */}
            </ProfileInfo>
            {/* <img src={ProfileImage} alt="" /> */}
        </ProfileWrapper>
    </HeaderWrapper>
}