import { useEffect, useMemo, useState } from "react";
import { FiDownload } from "react-icons/fi";
import { toast } from "react-toastify";
import Button from "../../../components/Button";
import CustomTable from "../../../components/CustomTable";
import DateRangeInput from "../../../components/DatePicker";
import Input from "../../../components/Input";
import { PageStructureWrapper } from "../../../components/PageStructureWrapper";
import api from "../../../service/api";
import { formatDate } from "../../../utils/Date";
import { toastStyle } from "../../../utils/toastStyles";
import { ConsultaPropostasWrapper, ConsultaResultWrapper, SearchFields, SearchWrapper } from "../../ConsutaDePropostas/style";

// const mock = [
//   {
//     user: "Proponente",
//     steps: [
//       {
//         status: "error",
//         stepName: "Proposta Iniciada"
//       },
//       {
//         status: "default",
//         stepName: "Login"
//       },
//       {
//         status: "done",
//         stepName: "Seleção de Proposta"
//       },
//       {
//         status: "done",
//         stepName: "Liveness/Biometria Facial"
//       },
//       {
//         status: "done",
//         stepName: "Upload de Documentos"
//       },
//       {
//         status: "done",
//         stepName: "Assinatura de Documentos"
//       },
//       {
//         status: "done",
//         stepName: "Conclusão da Jornada"
//       }
//     ]
//   },
//   {
//     user: "Avalista 1",
//     steps: [
//       {
//         status: "default",
//         stepName: "Proposta Iniciada"
//       },
//       {
//         status: "default",
//         stepName: "Login"
//       },
//       {
//         status: "default",
//         stepName: "Seleção de Proposta"
//       },
//       {
//         status: "default",
//         stepName: "Liveness/Biometria Facial"
//       },
//       {
//         status: "default",
//         stepName: "Upload de Documentos"
//       },
//       {
//         status: "default",
//         stepName: "Assinatura de Documentos"
//       },
//       {
//         status: "default",
//         stepName: "Conclusão da Jornada"
//       }
//     ]
//   },
//   {
//     user: "Avalista 2",
//     steps: [
//       {
//         status: "default",
//         stepName: "Proposta Iniciada"
//       },
//       {
//         status: "default",
//         stepName: "Login"
//       },
//       {
//         status: "default",
//         stepName: "Seleção de Proposta"
//       },
//       {
//         status: "default",
//         stepName: "Liveness/Biometria Facial"
//       },
//       {
//         status: "default",
//         stepName: "Upload de Documentos"
//       },
//       {
//         status: "default",
//         stepName: "Assinatura de Documentos"
//       },
//       {
//         status: "default",
//         stepName: "Conclusão da Jornada"
//       }
//     ]
//   },
//   {
//     user: "Upload New Space",
//     steps: [
//       {
//         status: "default",
//         stepName: "Documentos Assinados"
//       },
//       {
//         status: "default",
//         stepName: "Imagens"
//       }
//     ]
//   },
// ]

export const RelatorioSessaoPage = () => {
  const [openModal, setOpenModal] = useState(false);

  const columnsSelecaoProposta = useMemo(
    () => [
      {
        Header: 'Código da Sessão',
        accessor: 'session_id',
      },
      {
        Header: 'N. Documento',
        accessor: 'document',
      },
      {
        Header: 'CPF/CNPJ',
        accessor: 'document_type',
      },
      {
        Header: 'Telefone',
        accessor: 'phone',
      },
      {
        Header: 'Tipo',
        accessor: 'signator_type',
      },
      {
        Header: 'Contrato',
        accessor: 'contract',
      },
      {
        Header: 'Proposta',
        accessor: 'proposal',
      },
      {
        Header: 'Reinicio',
        accessor: 'restart',
      },
      {
        Header: 'Data Hora',
        accessor: 'datetime',
      },
      {
        Header: 'Filial',
        accessor: 'branch',
      },
      {
        Header: 'Loja',
        accessor: 'store',
      },
      {
        Header: 'IP',
        accessor: 'ip',
      },
      {
        Header: 'Localização',
        accessor: 'geo_location',
      },
      {
        Header: 'Newspace',
        accessor: 'newspace',
      },
      {
        Header: 'Token',
        accessor: 'token',
      },
      {
        Header: 'Tempo do Token',
        accessor: 'token_time',
      },
      {
        Header: 'Token Informado',
        accessor: 'token_informado',
      },
      {
        Header: 'Tempo do Token Informado',
        accessor: 'token_informado_time',
      },
      {
        Header: 'Selfie',
        accessor: 'selfie',
      },
      {
        Header: 'Tipo de Selfie',
        accessor: 'selfie_type',
      },
      {
        Header: 'Retorno da Selfie',
        accessor: 'selfie_return',
      },
      {
        Header: 'Serpro',
        accessor: 'serpro',
      },
      {
        Header: 'Paraíba',
        accessor: 'paraiba',
      },
      {
        Header: 'Tipo de Upload',
        accessor: 'upload_type',
      },
      {
        Header: 'Quantidade de Upload',
        accessor: 'upload_quantity',
      },
      {
        Header: 'Quantidade de Upload (Outros)',
        accessor: 'upload_quantity_others',
      },
      {
        Header: 'Termo',
        accessor: 'termo',
      },
      {
        Header: 'CET',
        accessor: 'cet',
      },
      {
        Header: 'CCB',
        accessor: 'ccb',
      },
      {
        Header: 'SPF',
        accessor: 'seguro',
      },
      {
        Header: 'SPF PJ',
        accessor: 'has_seguroparcela',
      },
      {
        Header: 'Tempo de Finalização',
        accessor: 'finish_time',
      },
      {
        Header: 'Suspeita',
        accessor: 'suspect',
      },
    ],
    []
  );


  const columnMinWidths = { firstName: '20px' };

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [dataSelecaoProposta, setDataSelecaoProposta] = useState([]);
  const [numeroProposta, setNumeroProposta] = useState<string>('');
  const [numeroContrato, setNumeroContrato] = useState<string>('');
  const [cpfCnpj, setCpfCnpj] = useState<string>('');
  const [filteredData, setFilteredData] = useState<any[]>([]);

  useEffect(() => {
    if ((startDate && endDate && dataSelecaoProposta) || numeroProposta) {
      setFilteredData(dataSelecaoProposta);
    }
  }, [startDate, endDate, dataSelecaoProposta, numeroProposta]);

  const applyFilters = () => {
    let filteredResult = dataSelecaoProposta;

    if (numeroProposta && numeroProposta.length === 8) {
      filteredResult = filteredResult.filter((item: any) =>
        item.proposal.includes(numeroProposta)
      );
    }

    if (numeroContrato && numeroContrato.length === 10) {
      filteredResult = filteredResult.filter((item: any) =>
        item.contract.includes(numeroContrato)
      );
    }

    if (cpfCnpj && (cpfCnpj.length === 11 || cpfCnpj.length === 14)) {
      filteredResult = filteredResult.filter((item: any) =>
        item.document.includes(cpfCnpj)
      );
    }
    console.log('filteredResult', filteredResult)
    setFilteredData(filteredResult);
  };

  const clearFilters = () => {
    setNumeroProposta('');
    setNumeroContrato('');
    setCpfCnpj('');
    setFilteredData(dataSelecaoProposta);
  }

  useEffect(() => {
    if (numeroContrato || numeroProposta || cpfCnpj) {
      applyFilters()
    } else {
      clearFilters()
    }

  }, [numeroContrato, numeroProposta, cpfCnpj])

  useEffect(() => {
    if (startDate && endDate && (numeroProposta || numeroContrato || cpfCnpj)) {
      applyFilters()
    } else if ((startDate && endDate) || numeroProposta) {
      toast.loading("Buscando dados...", { style: toastStyle })

      let endDateMoreOneDay = null;
      if (endDate) {
        endDateMoreOneDay = new Date(endDate)
        endDateMoreOneDay.setDate(endDate.getDate() + 1)
      }

      api.get(`/dashboard/v3/${startDate ? formatDate(startDate) : 'None'}/${endDate && endDateMoreOneDay ? formatDate(endDateMoreOneDay) : 'None'}/${numeroProposta ? numeroProposta : 'None'}/session-report`).then(response => {
        console.log(response.data)
        setDataSelecaoProposta(response.data)
        toast.dismiss()
      }).catch((err: any) => {
        toast.error("Erro ao buscar dados " + err)
      })
    }
  }, [startDate, endDate, numeroProposta, numeroContrato, cpfCnpj])

  const generateCSV = () => {
    const formatNumber = (value: any) => {
      if (!value)
        return value;

      return value.toLocaleString('pt-BR');
    };

    const csvContent =
      columnsSelecaoProposta.map((col: any) => col.Header).join(';') +
      '\n' +
      dataSelecaoProposta
        .map((row: any) =>
          columnsSelecaoProposta
            .map((col) => {
              const value = row[col.accessor];
              const formattedValue = formatNumber(value);
              const stringValue = typeof formattedValue === 'string' ? formattedValue : String(formattedValue);
              const encodedValue = stringValue.replace(/"/g, '""'); // Escapar as aspas
              return `"${encodedValue}"`;
            })
            .join(';')
        )
        .join('\n');

    const csvData = new Blob(['\ufeff', csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(csvData);

    link.setAttribute('href', url);
    link.setAttribute('download', `${startDate?.toLocaleDateString()}_${endDate?.toLocaleDateString()}.csv`);
    link.style.visibility = 'hidden';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };




  return <PageStructureWrapper title="Detalhamento de Sessões">
    <ConsultaPropostasWrapper>
      <SearchWrapper>
        <SearchFields>
          <DateRangeInput titleHeader="Datas:"
            startDate={startDate} setStartDate={setStartDate}
            endDate={endDate} setEndDate={setEndDate}
          />
          <Input titleHeader="Número da Proposta:" placeholder="Ex: 123456" onChange={(e) => setNumeroProposta(e.target.value)} />
          <Input titleHeader="Número do Contrato:" placeholder="Ex: 123456" onChange={(e) => setNumeroContrato(e.target.value)} disabled={!(startDate || endDate)} />
          <Input type="number" titleHeader="CPF/CNPJ:" placeholder="Ex: 1234567890" onChange={(e) => setCpfCnpj(e.target.value)} disabled={!(startDate || endDate)} />
        </SearchFields>
        <Button onClick={generateCSV} leftIcon={<FiDownload />} label="Download" theme="primary" height="40px" />
      </SearchWrapper>
      <ConsultaResultWrapper onClick={() => setOpenModal(!openModal)}>
        {(startDate && endDate && dataSelecaoProposta || numeroProposta) &&
          <CustomTable maxHeight="calc(100vh - 300px)" minWidth="5000px"
            columns={columnsSelecaoProposta}
            data={filteredData}
            columnMinWidths={columnMinWidths} />}
      </ConsultaResultWrapper>
    </ConsultaPropostasWrapper>
  </PageStructureWrapper>
}