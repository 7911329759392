import { useMemo, useState } from "react";
import { FiDownload } from "react-icons/fi";
import Modal from 'react-modal'
import { MdClose } from "react-icons/md";
import { ConsultaPropostasWrapper, ConsultaResultWrapper, SearchFields, SearchWrapper } from "../../ConsutaDePropostas/style";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import { PageStructureWrapper } from "../../../components/PageStructureWrapper";
import CustomTable from "../../../components/CustomTable";
import DateRangeInput from "../../../components/DatePicker";

const mock = [
    {
        user: "Proponente",
        steps: [
            {
                status: "error",
                stepName: "Proposta Iniciada"
            },
            {
                status: "default",
                stepName: "Login"
            },
            {
                status: "done",
                stepName: "Seleção de Proposta"
            },
            {
                status: "done",
                stepName: "Liveness/Biometria Facial"
            },
            {
                status: "done",
                stepName: "Upload de Documentos"
            },
            {
                status: "done",
                stepName: "Assinatura de Documentos"
            },
            {
                status: "done",
                stepName: "Conclusão da Jornada"
            }
        ]
    },
    {
        user: "Avalista 1",
        steps: [
            {
                status: "default",
                stepName: "Proposta Iniciada"
            },
            {
                status: "default",
                stepName: "Login"
            },
            {
                status: "default",
                stepName: "Seleção de Proposta"
            },
            {
                status: "default",
                stepName: "Liveness/Biometria Facial"
            },
            {
                status: "default",
                stepName: "Upload de Documentos"
            },
            {
                status: "default",
                stepName: "Assinatura de Documentos"
            },
            {
                status: "default",
                stepName: "Conclusão da Jornada"
            }
        ]
    },
    {
        user: "Avalista 2",
        steps: [
            {
                status: "default",
                stepName: "Proposta Iniciada"
            },
            {
                status: "default",
                stepName: "Login"
            },
            {
                status: "default",
                stepName: "Seleção de Proposta"
            },
            {
                status: "default",
                stepName: "Liveness/Biometria Facial"
            },
            {
                status: "default",
                stepName: "Upload de Documentos"
            },
            {
                status: "default",
                stepName: "Assinatura de Documentos"
            },
            {
                status: "default",
                stepName: "Conclusão da Jornada"
            }
        ]
    },
    {
        user: "Upload New Space",
        steps: [
            {
                status: "default",
                stepName: "Documentos Assinados"
            },
            {
                status: "default",
                stepName: "Imagens"
            }
        ]
    },
]

export const RelatorioLoginPage = () => {
    const [openModal, setOpenModal] = useState(false);

    const columnsSelecaoProposta = useMemo(
        () => [
            {
                Header: 'Código da Sessão',
                accessor: 'codigoDaSessao',
            },
            {
                Header: 'N. Documento',
                accessor: 'nDocumento',
            },
            {
                Header: 'CPF/CNPJ',
                accessor: 'cpfCnpj',
            },
            {
                Header: 'Tipo',
                accessor: 'tipo',
            },
            {
                Header: 'Contrato',
                accessor: 'contrato',
            },
            {
                Header: 'Reinicio',
                accessor: 'reinicio',
            },
            {
                Header: 'Data Hora',
                accessor: 'dataHora',
            },
            {
                Header: 'Retorno da NS',
                accessor: 'retornoNS',
            },
        ],
        []
    );

    const dataSelecaoProposta = useMemo(
        () => [
            {
                codigoDaSessao: 'ABC123',
                nDocumento: 'DOC001',
                cpfCnpj: '123.456.789-00',
                tipo: 'Tipo A',
                contrato: 'Contrato 1',
                reinicio: 'Sim',
                dataHora: '2023-12-31 10:00',
                retornoNS: 'OK',
            },
            {
                codigoDaSessao: 'DEF456',
                nDocumento: 'DOC002',
                cpfCnpj: '987.654.321-00',
                tipo: 'Tipo B',
                contrato: 'Contrato 2',
                reinicio: 'Não',
                dataHora: '2023-12-31 11:30',
                retornoNS: 'Erro',
            },
            {
                codigoDaSessao: 'GHI789',
                nDocumento: 'DOC003',
                cpfCnpj: '111.222.333-44',
                tipo: 'Tipo C',
                contrato: 'Contrato 3',
                reinicio: 'Sim',
                dataHora: '2023-12-31 13:45',
                retornoNS: 'OK',
            },
            {
                codigoDaSessao: 'JKL012',
                nDocumento: 'DOC004',
                cpfCnpj: '555.666.777-88',
                tipo: 'Tipo D',
                contrato: 'Contrato 4',
                reinicio: 'Não',
                dataHora: '2023-12-31 14:20',
                retornoNS: 'Erro',
            },
            {
                codigoDaSessao: 'MNO345',
                nDocumento: 'DOC005',
                cpfCnpj: '999.888.777-66',
                tipo: 'Tipo E',
                contrato: 'Contrato 5',
                reinicio: 'Sim',
                dataHora: '2023-12-31 15:55',
                retornoNS: 'OK',
            },
            {
                codigoDaSessao: 'MNO345',
                nDocumento: 'DOC005',
                cpfCnpj: '999.888.777-66',
                tipo: 'Tipo E',
                contrato: 'Contrato 5',
                reinicio: 'Sim',
                dataHora: '2023-12-31 15:55',
                retornoNS: 'OK',
            },
            {
                codigoDaSessao: 'MNO345',
                nDocumento: 'DOC005',
                cpfCnpj: '999.888.777-66',
                tipo: 'Tipo E',
                contrato: 'Contrato 5',
                reinicio: 'Sim',
                dataHora: '2023-12-31 15:55',
                retornoNS: 'OK',
            },
            {
                codigoDaSessao: 'MNO345',
                nDocumento: 'DOC005',
                cpfCnpj: '999.888.777-66',
                tipo: 'Tipo E',
                contrato: 'Contrato 5',
                reinicio: 'Sim',
                dataHora: '2023-12-31 15:55',
                retornoNS: 'OK',
            },
            {
                codigoDaSessao: 'MNO345',
                nDocumento: 'DOC005',
                cpfCnpj: '999.888.777-66',
                tipo: 'Tipo E',
                contrato: 'Contrato 5',
                reinicio: 'Sim',
                dataHora: '2023-12-31 15:55',
                retornoNS: 'OK',
            },
            {
                codigoDaSessao: 'MNO345',
                nDocumento: 'DOC005',
                cpfCnpj: '999.888.777-66',
                tipo: 'Tipo E',
                contrato: 'Contrato 5',
                reinicio: 'Sim',
                dataHora: '2023-12-31 15:55',
                retornoNS: 'OK',
            },
            {
                codigoDaSessao: 'MNO345',
                nDocumento: 'DOC005',
                cpfCnpj: '999.888.777-66',
                tipo: 'Tipo E',
                contrato: 'Contrato 5',
                reinicio: 'Sim',
                dataHora: '2023-12-31 15:55',
                retornoNS: 'OK',
            },
            {
                codigoDaSessao: 'MNO345',
                nDocumento: 'DOC005',
                cpfCnpj: '999.888.777-66',
                tipo: 'Tipo E',
                contrato: 'Contrato 5',
                reinicio: 'Sim',
                dataHora: '2023-12-31 15:55',
                retornoNS: 'OK',
            },
            {
                codigoDaSessao: 'MNO345',
                nDocumento: 'DOC005',
                cpfCnpj: '999.888.777-66',
                tipo: 'Tipo E',
                contrato: 'Contrato 5',
                reinicio: 'Sim',
                dataHora: '2023-12-31 15:55',
                retornoNS: 'OK',
            },
            {
                codigoDaSessao: 'MNO345',
                nDocumento: 'DOC005',
                cpfCnpj: '999.888.777-66',
                tipo: 'Tipo E',
                contrato: 'Contrato 5',
                reinicio: 'Sim',
                dataHora: '2023-12-31 15:55',
                retornoNS: 'OK',
            },
            {
                codigoDaSessao: 'MNO345',
                nDocumento: 'DOC005',
                cpfCnpj: '999.888.777-66',
                tipo: 'Tipo E',
                contrato: 'Contrato 5',
                reinicio: 'Sim',
                dataHora: '2023-12-31 15:55',
                retornoNS: 'OK',
            },
            {
                codigoDaSessao: 'MNO345',
                nDocumento: 'DOC005',
                cpfCnpj: '999.888.777-66',
                tipo: 'Tipo E',
                contrato: 'Contrato 5',
                reinicio: 'Sim',
                dataHora: '2023-12-31 15:55',
                retornoNS: 'OK',
            },
            {
                codigoDaSessao: 'MNO345',
                nDocumento: 'DOC005',
                cpfCnpj: '999.888.777-66',
                tipo: 'Tipo E',
                contrato: 'Contrato 5',
                reinicio: 'Sim',
                dataHora: '2023-12-31 15:55',
                retornoNS: 'OK',
            },
            {
                codigoDaSessao: 'MNO345',
                nDocumento: 'DOC005',
                cpfCnpj: '999.888.777-66',
                tipo: 'Tipo E',
                contrato: 'Contrato 5',
                reinicio: 'Sim',
                dataHora: '2023-12-31 15:55',
                retornoNS: 'OK',
            },
            {
                codigoDaSessao: 'MNO345',
                nDocumento: 'DOC005',
                cpfCnpj: '999.888.777-66',
                tipo: 'Tipo E',
                contrato: 'Contrato 5',
                reinicio: 'Sim',
                dataHora: '2023-12-31 15:55',
                retornoNS: 'OK',
            },
            {
                codigoDaSessao: 'MNO345',
                nDocumento: 'DOC005',
                cpfCnpj: '999.888.777-66',
                tipo: 'Tipo E',
                contrato: 'Contrato 5',
                reinicio: 'Sim',
                dataHora: '2023-12-31 15:55',
                retornoNS: 'OK',
            },
            {
                codigoDaSessao: 'MNO345',
                nDocumento: 'DOC005',
                cpfCnpj: '999.888.777-66',
                tipo: 'Tipo E',
                contrato: 'Contrato 5',
                reinicio: 'Sim',
                dataHora: '2023-12-31 15:55',
                retornoNS: 'OK',
            },
            {
                codigoDaSessao: 'MNO345',
                nDocumento: 'DOC005',
                cpfCnpj: '999.888.777-66',
                tipo: 'Tipo E',
                contrato: 'Contrato 5',
                reinicio: 'Sim',
                dataHora: '2023-12-31 15:55',
                retornoNS: 'OK',
            },
            {
                codigoDaSessao: 'MNO345',
                nDocumento: 'DOC005',
                cpfCnpj: '999.888.777-66',
                tipo: 'Tipo E',
                contrato: 'Contrato 5',
                reinicio: 'Sim',
                dataHora: '2023-12-31 15:55',
                retornoNS: 'OK',
            },
            {
                codigoDaSessao: 'MNO345',
                nDocumento: 'DOC005',
                cpfCnpj: '999.888.777-66',
                tipo: 'Tipo E',
                contrato: 'Contrato 5',
                reinicio: 'Sim',
                dataHora: '2023-12-31 15:55',
                retornoNS: 'OK',
            },
            {
                codigoDaSessao: 'MNO345',
                nDocumento: 'DOC005',
                cpfCnpj: '999.888.777-66',
                tipo: 'Tipo E',
                contrato: 'Contrato 5',
                reinicio: 'Sim',
                dataHora: '2023-12-31 15:55',
                retornoNS: 'OK',
            },
            {
                codigoDaSessao: 'MNO345',
                nDocumento: 'DOC005',
                cpfCnpj: '999.888.777-66',
                tipo: 'Tipo E',
                contrato: 'Contrato 5',
                reinicio: 'Sim',
                dataHora: '2023-12-31 15:55',
                retornoNS: 'OK',
            },
            {
                codigoDaSessao: 'MNO345',
                nDocumento: 'DOC005',
                cpfCnpj: '999.888.777-66',
                tipo: 'Tipo E',
                contrato: 'Contrato 5',
                reinicio: 'Sim',
                dataHora: '2023-12-31 15:55',
                retornoNS: 'OK',
            },
            {
                codigoDaSessao: 'MNO345',
                nDocumento: 'DOC005',
                cpfCnpj: '999.888.777-66',
                tipo: 'Tipo E',
                contrato: 'Contrato 5',
                reinicio: 'Sim',
                dataHora: '2023-12-31 15:55',
                retornoNS: 'OK',
            },
        ],
        []
    );



    const columnMinWidths = { firstName: '20px' };

    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);

    return <PageStructureWrapper title="Relatório - Tentativas de Login">
        <ConsultaPropostasWrapper>
            <SearchWrapper>
                <SearchFields>
                    <Input titleHeader="Número da Proposta:" placeholder="Ex: 123456" />
                    <Input titleHeader="Número do Contrato:" placeholder="Ex: 123456" />
                    <Input titleHeader="CPF/CNPJ:" placeholder="Ex: 1234567890" />
                    <DateRangeInput titleHeader="Datas:"
                    startDate={startDate} setStartDate={setStartDate}
                    endDate={endDate} setEndDate={setEndDate}
                />
                </SearchFields>
                <Button disabled={true} leftIcon={<FiDownload />} label="Download" theme="primary" height="40px" />
            </SearchWrapper>
            <ConsultaResultWrapper onClick={() => setOpenModal(!openModal)}>
                <CustomTable maxHeight="calc(100vh - 300px)"
                    columns={columnsSelecaoProposta}
                    data={dataSelecaoProposta}
                    columnMinWidths={columnMinWidths} />
            </ConsultaResultWrapper>
        </ConsultaPropostasWrapper>
    </PageStructureWrapper>
}