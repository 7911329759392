import { useEffect, useState } from "react";
import VolumeDeRiscos from "../../components/Chart/Bars/VolumeDeRiscos";
import VolumeLivenessMaiorQueMediaHistorica from "../../components/Chart/Bars/VolumeLivenessMaiorQueMediaHistorica";
import { PageStructureWrapper } from "../../components/PageStructureWrapper";
import api from "../../service/api";
import { ConsultaResultWrapper } from "../ConsutaDePropostas/style";
import { GraphicsWrapper, MonthLine, Section } from "../VolumeOperacoes/style";


export const VolumeDeRiscosPage = () => {

    type NameMapping = {
        acrofields: string;
        login: string;
        token: string;
        "proposal-selection": string;
        liveness: string;
        "facial-biometry": string;
        "document-upload": string;
        "others-upload": string;
        "cet-signature": string;
        "ccb-signature": string;
        finished: string;
    };


    const [suspectsByMonth, setSuspectsByMonth] = useState<any>([]);

    const [volumePropostasComMuitosLiveness, setVolumePropostasComMuitosLiveness] = useState<any>([]);

    const [volumeSaffeUnico, setVolumeSaffeUnico] = useState<any>([]);

   

    const processSuspectsByMonth = () => {
        const dadosTransformados: any = [];

        api.get('/dashboard/v3/suspects-volume-by-days').then((response) => {
            response.data?.forEach((dados: any) => {
                dadosTransformados.push(dados.suspects);
            });

            setSuspectsByMonth(dadosTransformados);
        })
    }

    const processVolumePropsotaLiveness = () => {
        api.get('/dashboard/v3/proposals-with-many-liveness').then((response) => {
            const dadosTransformados: any = [];
    
            response.data?.forEach((dados: any) => {
                dadosTransformados.push(dados.proposals_count);
            });
    
            setVolumePropostasComMuitosLiveness(dadosTransformados);
        })
    }

    
    const processVolumeLiveness = () => {
        const dadosTransformados: any = {
            saffe: [],
            unico: []
        };
    
        Promise.all([
            api.get('/dashboard/v3/2/volume-liveness'),
            api.get('/dashboard/v3/1/volume-liveness')
        ]).then(([saffeResponse, unicoResponse]) => {
            saffeResponse.data?.saffe?.forEach((dados: any) => {
                dadosTransformados.saffe.push(dados.value);
            });
    
            unicoResponse.data?.unico?.forEach((dados: any) => {
                dadosTransformados.unico.push(dados.value);
            });
    
            setVolumeSaffeUnico(dadosTransformados);
        }).catch(error => {
            console.error("Erro ao processar volume liveness:", error);
        });
    }

   

    useEffect(() => {
        processSuspectsByMonth();
        processVolumePropsotaLiveness();
        processVolumeLiveness();
    }, []);

    return <PageStructureWrapper title="Volume de Riscos">
        <ConsultaResultWrapper>
            <Section>
                <h3>Suspeitos e E-mail de Alerta Enviado</h3>
                <GraphicsWrapper>
                    <MonthLine>
                        <VolumeDeRiscos data={{liveness: volumePropostasComMuitosLiveness, suspeitos: suspectsByMonth}} />
                    </MonthLine>
                </GraphicsWrapper>
            </Section>
            <Section>
                <h3>Propostas com Mais Tentativas do que a Média Histórica (Média dos Últimos 3 Meses: Saffe: 2.18 e Unico: 1.62)</h3>
                <GraphicsWrapper>
                    <MonthLine>
                        <VolumeLivenessMaiorQueMediaHistorica data={volumeSaffeUnico} />
                    </MonthLine>
                </GraphicsWrapper>
            </Section>
            {/* <Section>
                <h3>Propostas com mais de 10 Tentativas de Liveness por Dia</h3>
                <GraphicsWrapper>
                    <MonthLine>
                        <VolumeDePropostaLiveness data={volumePropostaLiveness} />
                    </MonthLine>
                </GraphicsWrapper>
            </Section> */}
        </ConsultaResultWrapper>
    </PageStructureWrapper>
}