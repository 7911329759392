import styled from "styled-components";

export const PageWrapper = styled.div`
 display: flex;
`

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #ECF1F2;
    width: 100%;
    overflow: auto;
`

export const Content = styled.div`
    padding: 20px;
    gap: 20px;
    padding-top: 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
`

export const Title = styled.h2`
    text-align: center;
    color: #1b2666;
`