import { UpdatedAtWrapper } from "./styled";


interface UpdatedAtProps {
    updatedAt: string;
}

export const UpdatedAt = ({updatedAt}: UpdatedAtProps) => {
    return (
       <UpdatedAtWrapper>
              (Atualizado em {updatedAt})
       </UpdatedAtWrapper>
    )
}

export default UpdatedAt;