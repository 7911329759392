import styled from "styled-components";


export const InputWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 12px;
`

export const SearchFieldsWrapper = styled.div`
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`