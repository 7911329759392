import Chart, { ChartConfiguration } from 'chart.js/auto';
import React, { useEffect, useRef } from 'react';

interface BarChartProps {
  data: {
    received: any[];
    running: any[];
    done: any[];
    not_done: any[]
  };
  label: string[];
}

const BarChart: React.FC<BarChartProps> = ({ data, label }) => {
  const chartRef = useRef<HTMLCanvasElement>(null);
  const chartInstance = useRef<Chart<'bar', unknown> | null>(null);

  useEffect(() => {
    if (chartRef && chartRef.current) {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }

      const ctx = chartRef.current.getContext('2d');
      if (ctx) {
        const datasets = [
          {
            label: 'Recebidas',
            data: data.received,
            borderColor: '#485184',
            backgroundColor: '#485184',
            borderWidth: 2,
          },
          {
            label: 'Em andamento',
            data: data.running,
            borderColor: '#FF9E00',
            backgroundColor: '#FF9E00',
            borderWidth: 2,
          },
          {
            label: 'Concluidas',
            data: data.done,
            borderColor: '#4CAF50',
            backgroundColor: '#4CAF50',
            borderWidth: 2,
          },
          // {
          //   label: 'Não Concluidas',
          //   data: data.not_done,
          //   borderColor: '#b1b1b1',
          //   backgroundColor: '#b1b1b1',
          //   borderWidth: 2,
          // },
        ];

        chartInstance.current = new Chart(ctx, {
          type: 'bar',
          data: {
            labels: label,
            datasets: datasets,
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              y: {
                beginAtZero: true,
              },
            },
          },
        } as ChartConfiguration<'bar', number[], string>);
      }
    }

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [data]);

  return <canvas ref={chartRef} />;
};

export default BarChart;
