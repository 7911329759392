import styled from "styled-components";

interface MenuProps {
    isActived?: boolean;
    isSubItem?: boolean;
}

export const MenuButtonWrapper = styled.div<MenuProps>`
    display: flex;
    justify-content: flex-start;
    padding-left: 40px;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    cursor: pointer;
    width: 100%;
    height: 40px;
    background-color: ${({ isActived, isSubItem }) => isActived ? '#485184' : isSubItem ? '#2b3257' : '#fff'};
    color: ${({ isActived, isSubItem }) => isActived ? '#fff' : isSubItem ? '#fff' : '#485184'};
    font-weight: ${({ isActived }) => isActived ? '500' : 'normal'};
    stroke-width: 5px;
    transition: .2s ease-in-out;

    svg {
        stroke-width: ${({ isActived }) => isActived ? '2px' : ''};
    }

    &:hover {
        background-color: #485184de;
        color: #fff;
    }
` 

export const MenuItem = styled.div<MenuProps>`
    display: flex;
    gap: 10px;
    padding-left: ${({ isSubItem }) => isSubItem ? '16px' : '0'};
`