import styled from "styled-components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";

export const StyledDateRangePicker = styled(DatePicker)`
  width: 100%;
  display: block;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;

  .react-datepicker__day--in-range {
    background-color: red !important;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;

  span {
    font-size: 14px;
  }
`;