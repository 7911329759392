import Chart, { ChartConfiguration, ChartData } from 'chart.js/auto';
import React, { useEffect, useRef } from 'react';

interface BarChartProps {
  data: {
    liveness: number[];
    biometria: number[];
  };
}

const VolumeDeServicos: React.FC<BarChartProps> = ({ data }) => {
  const chartRef = useRef<HTMLCanvasElement>(null);
  const chartInstance = useRef<Chart<'bar', number[], string> | null>(null);

  useEffect(() => {
    if (chartRef && chartRef.current && data) {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }

      const ctx = chartRef.current.getContext('2d');
      if (ctx) {
        const chartData: ChartData<'bar', number[], string> = {
          labels: Array.from({ length: data.liveness?.length }, (_, i) => (i + 1).toString()), // Dias do mês
          datasets: [
            {
              label: 'Liveness',
              data: data.liveness,
              borderColor: '#485184',
              backgroundColor: '#485184',
              borderWidth: 2,
            },
            {
              label: 'Biometria',
              data: data.biometria,
              borderColor: '#9560eb',
              backgroundColor: '#9560eb',
              borderWidth: 2,
            },
          ],
        };

        chartInstance.current = new Chart(ctx, {
          type: 'bar',
          data: chartData,
          options: {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              y: {
                beginAtZero: true,
              },
            },
            plugins: {
              tooltip: {
               
              },
              legend: {
                // display: false,
              },
            },
          },
        } as ChartConfiguration<'bar', number[], string>);
      }
    }

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [data]);

  return <canvas ref={chartRef} />;
};

export default VolumeDeServicos;
