import Tippy from "@tippyjs/react";
import { FaCheck } from "react-icons/fa6";
import { IoMdClose } from "react-icons/io";
import { Step, StepWrapper } from "./style";

interface StepJourneyProps {
    status: "error" | "P" | "D" | "W";
    stepName: string;
    size?: string;
    align?: 'center' | 'start' | 'end';
    tooltipContent?: string;
}

export const StepJourney = ({ status, stepName, size, align, tooltipContent }: StepJourneyProps) => {
    const stepContent = (<StepWrapper align={align}>
        <Step status={status} size={size}>
            {status === "D" &&
                <FaCheck fontSize={size ? Number(size.replaceAll('px', '')) / 2 : 40} />
            }
            {status === "error" &&
                <IoMdClose fontSize={size ? Number(size.replaceAll('px', '')) / 2 : 40} />
            }
        </Step>
        <span>{stepName}</span>
    </StepWrapper>)
    return tooltipContent ? <Tippy content={tooltipContent}>
        {stepContent}
    </Tippy> :
        stepContent
}

export default StepJourney;