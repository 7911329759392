import { useEffect, useMemo, useState } from "react";
import { FiDownload } from "react-icons/fi";
import { toast } from "react-toastify";
import Button from "../../../components/Button";
import VolumeDeGeral from "../../../components/Chart/Bars/volumeDeGeral";
import CustomTable from "../../../components/CustomTable";
import DateRangeInput from "../../../components/DatePicker";
import DropDown from "../../../components/Dropdown";
import Input from "../../../components/Input";
import { PageStructureWrapper } from "../../../components/PageStructureWrapper";
import api from "../../../service/api";
import { formatDate } from "../../../utils/Date";
import { toastStyle } from "../../../utils/toastStyles";
import { ConsultaPropostasWrapper, SearchFields, SearchWrapper } from "../../ConsutaDePropostas/style";
import { ConsultaResultWrapper, GraficWrapper } from "../SlaPropostaTodos/style";

export const RelatorioPropostasPage = () => {
  const [openModal, setOpenModal] = useState(false);

  const columnsProposal = useMemo(
    () => [
      {
        Header: 'Proposta',
        accessor: 'proposal',
      },
      {
        Header: 'Contrato',
        accessor: 'contract',
      },
      {
        Header: 'Papel',
        accessor: 'type',
      },
      {
        Header: 'Horario Primeira Sessão',
        accessor: 'session_start',
      },
      {
        Header: 'Horario Conclusão Jornada',
        accessor: 'journey_end',
      },
      {
        Header: 'Duração',
        accessor: 'duration_hours',
      }
    ],
    []
  );


  const columnMinWidths = { firstName: '20px' };

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [time, setTime] = useState<any>(0);
  const [endTime, setEndtime] = useState<any>(999);
  const [proposalsReport, setProposalsReport] = useState([]);
  const [numeroProposta, setNumeroProposta] = useState<string>('');
  const [numeroContrato, setNumeroContrato] = useState<string>('');
  const [cpfCnpj, setCpfCnpj] = useState<string>('');
  const [filteredData, setFilteredData] = useState<any[]>([]);  
  const [graficData, setGraficData] = useState<any[]>([]);

  useEffect(() => {
    if (startDate && endDate && proposalsReport) {
      setFilteredData(proposalsReport);
    }
  }, [startDate, endDate, proposalsReport]);

  const applyFilters = () => {
    let filteredResult = proposalsReport;

    if (numeroProposta && numeroProposta.length === 8) {
      filteredResult = filteredResult.filter((item: any) =>
        item.proposal.includes(numeroProposta)
      );
    }

    // if (numeroContrato && numeroContrato.length === 10) {
    //   filteredResult = filteredResult.filter((item: any) =>
    //     item.contract.includes(numeroContrato)
    //   );
    // }

    // if (cpfCnpj && (cpfCnpj.length === 11 || cpfCnpj.length === 14)) {
    //   filteredResult = filteredResult.filter((item: any) =>
    //     item.document.includes(cpfCnpj)
    //   );
    // }

    setFilteredData(filteredResult);
  };

  const clearFilters = () => {
    setNumeroProposta('');
    setNumeroContrato('');
    setCpfCnpj('');
    setFilteredData(proposalsReport);
  }

  useEffect(() => {
    if (numeroContrato || numeroProposta || cpfCnpj) {
      applyFilters()
    } else {
      clearFilters()
    }

  }, [numeroContrato, numeroProposta, cpfCnpj])

  useEffect(() => {
    if (startDate && endDate) {
      toast.loading("Buscando dados...", { style: toastStyle })
      api.get(`/dashboard/v3/get_sessions_and_proposals_more_than/${formatDate(startDate)}/${formatDate(endDate)}/${time}/${endTime}`).then(response => {
        console.log(response.data)
        setProposalsReport(response.data)
        setGraficData(
          calculateDailyAverage(sortDatas(dataWithNumericDuration(response.data)))
        )
        console.log('aaaa',  calculateDailyAverage(sortDatas(dataWithNumericDuration(response.data))))
        toast.dismiss()
      }).catch((err: any) => {
        toast.error("Erro ao buscar dados " + err)
      })
    }
  }, [startDate, endDate, time, endTime])

  const generateCSV = () => {
    const formatNumber = (value: any) => {
      if (!value)
        return value;

      return value.toLocaleString('pt-BR');
    };

    const csvContent =
      columnsProposal.map((col: any) => col.Header).join(';') +
      '\n' +
      proposalsReport
        .map((row: any) =>
          columnsProposal
            .map((col) => {
              const value = row[col.accessor];
              const formattedValue = formatNumber(value);
              const stringValue = typeof formattedValue === 'string' ? formattedValue : String(formattedValue);
              const encodedValue = stringValue.replace(/"/g, '""'); // Escapar as aspas
              return `"${encodedValue}"`;
            })
            .join(';')
        )
        .join('\n');

    const csvData = new Blob(['\ufeff', csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(csvData);

    link.setAttribute('href', url);
    link.setAttribute('download', `${startDate?.toLocaleDateString()}_${endDate?.toLocaleDateString()}.csv`);
    link.style.visibility = 'hidden';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const dataWithNumericDuration = (data: any) => {
    return data.map((item: any) => {
      const durationParts = item.duration_hours.split(' ');

      let durationInHours = 0;

      for (let i = 0; i < durationParts.length; i += 2) {
        const value = parseInt(durationParts[i]);
        const unit = durationParts[i + 1];

        if (unit.includes('Hora')) {
          durationInHours += value;
        } else if (unit.includes('Min')) {
          durationInHours += value / 60;
        } else if (unit.includes('Seg')) {
          durationInHours += value / 3600;
        }
      }

      return {
        ...item,
        duration_hours_numeric: durationInHours
      };
    });
  }

  const sortDatas = (data: any) => {
    return data.sort((a: any, b: any) => {
      const dateA = new Date(a.session_start);
      const dateB = new Date(b.session_start);
      return dateA.getTime() - dateB.getTime();
    });
  }

  function calculateDailyAverage(dataList: any[]): { date: string, average_duration_hours: number, proposalCount: number }[] {
    const groupedData: { [key: string]: any[] } = {};
  
    dataList.forEach(data => {
      const dateKey = data.session_start.split(' ')[0]; 
      if (!groupedData[dateKey]) {
        groupedData[dateKey] = [];
      }
      groupedData[dateKey].push(data);
    });
  
    const averageData: { date: string, average_duration_hours: number, proposalCount: number }[] = [];
    
    for (const dateKey in groupedData) {
      const dataForDay = groupedData[dateKey];
      let totalDuration = 0;
      dataForDay.forEach(data => {
        totalDuration += data.duration_hours_numeric; 
      });
      const averageDuration = totalDuration / dataForDay.length;
      averageData.push({ 
        date: dateKey, 
        average_duration_hours: averageDuration, 
        proposalCount: dataForDay.length 
      });
    }
  
    return averageData;
  }

  return <PageStructureWrapper title="Sla por Função">
    <ConsultaPropostasWrapper>
      <SearchWrapper>
        <SearchFields>
          <DateRangeInput titleHeader="Datas:"
            startDate={startDate} setStartDate={setStartDate}
            endDate={endDate} setEndDate={setEndDate}
          />
          <DropDown titleHeader="De:" options={[
            { label: '0 horas', value: '0' },
            { label: '12 horas', value: '12' },
            { label: '24 horas', value: '24' },
            { label: '36 horas', value: '36' },
            { label: '48 horas', value: '48' },
          ]} onChange={(e) => {console.log(e); setTime(e)}} />
           <DropDown titleHeader="Até:" options={[
            { label: 'Sem limite', value: '999' },
            { label: '12 horas', value: '12' },
            { label: '24 horas', value: '24' },
            { label: '36 horas', value: '36' },
            { label: '48 horas', value: '48' },
            { label: '60 horas', value: '60' },
            { label: '72 horas', value: '72' },
            { label: '84 horas', value: '84' },
            { label: '96 horas', value: '96' },
          ]} onChange={(e) => {console.log(e); setEndtime(e)}} />
          <Input titleHeader="Número da Proposta:" placeholder="Ex: 123456" onChange={(e) => setNumeroProposta(e.target.value)} disabled={!(startDate || endDate)} />
          {/* <Input titleHeader="Número do Contrato:" placeholder="Ex: 123456" onChange={(e) => setNumeroContrato(e.target.value)} disabled={!(startDate || endDate)} />
          <Input titleHeader="CPF/CNPJ:" placeholder="Ex: 1234567890" onChange={(e) => setCpfCnpj(e.target.value)} disabled={!(startDate || endDate)} /> */}
        </SearchFields>
        <Button onClick={generateCSV} leftIcon={<FiDownload />} label="Download" theme="primary" height="40px" />
      </SearchWrapper>
      <ConsultaResultWrapper onClick={() => setOpenModal(!openModal)}>
      {startDate && endDate && proposalsReport && <>
          <GraficWrapper>
            <VolumeDeGeral data={graficData} />
          </GraficWrapper>
          <CustomTable maxHeight="calc(100vh - 300px)" minWidth="100%"
            columns={columnsProposal}
            data={filteredData}
            columnMinWidths={columnMinWidths} /> </>}
      </ConsultaResultWrapper>
    </ConsultaPropostasWrapper>
  </PageStructureWrapper>
}