
import { Header } from "../Header";
import { SideMenu } from "../SideMenu";
import { Content, ContentWrapper, PageWrapper, Title } from "./style";

interface PageStructureWrapperProps {
    title?: string;
    children: React.ReactNode;
}

export const PageStructureWrapper = (props: PageStructureWrapperProps) => {
    return <PageWrapper>
        <SideMenu />
        <ContentWrapper>
            <Header/>
            <Content>
                <Title>{props.title}</Title>
                {props.children}
            </Content>
        </ContentWrapper>
    </PageWrapper>
}