
import styled from 'styled-components'

export const Section = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`

export const GraphicsWrapper = styled.div`
    display: flex;
    gap: 30px;
    width: 100%;
`

export const TodayWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    height: 300px;
    flex: 1;
`

export const MonthLine = styled.div`
    height: 300px;
    flex: 2;
`

export const HeaderLine = styled.div`
    width: 100%;
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
`;

export const DropDownWrapper = styled.div`
    width: 300px;
`;