import { NotFoundWrapper } from "./style"
import NexyonLogo from '../../assets/nexyon_logo.png'
import Button from "../../components/Button"

export const NotFoundPage = () => {
    return <NotFoundWrapper>
        <img src={NexyonLogo} alt="404" />
        <span>Página não encontrada</span>
        <Button
            label="Voltar"
            theme="primary"
            onClick={() => { window.history.back() }}/>
    </NotFoundWrapper>
}