import { useEffect, useRef, useState } from "react";
import { ResendLink } from "./style";

interface ResendTokenProps {
    onResend: () => void;
    clicked?: boolean;
}

const ResendToken: React.FC<ResendTokenProps> = ({ onResend, clicked }) => {
    const [counter, setCounter] = useState(60); 
    const counterRef = useRef(counter);

    useEffect(() => {
        counterRef.current = counter;
      }, [counter]);

    useEffect(() => {
       
        const interval = setInterval(() => {
            if(counterRef.current == 0) 
                return;
            else 
                setCounter((currentCounter) => currentCounter - 1);
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (counter === 0) {
            setCounter(60);
        }
    }, [onResend, clicked]);

    return (
        <ResendLink onClick={onResend} disabled={!(counter === 0)}>
            {counter === 0 ? 'Clique para reenviar' : `Aguarde para reenviar... ${counter} segundos`}
        </ResendLink>
    );
};

export default ResendToken;