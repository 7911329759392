import { AiOutlineLoading as Loading } from 'react-icons/ai';
import styled, { DefaultTheme } from 'styled-components';

const themes: Record<string, DefaultTheme> = {
  primary: {
    background: '#1b2666',
    color: 'white',
    border: 'none',
    padding: '10px 20px;',
    display: 'flex',
  },
  secondary: {
    background: 'green',
    color: 'white',
    border: 'none',
    padding: '10px 20px;',

    display: 'flex',
  },
  text: {
    background: 'transparent',
    color: 'blue',
    border: 'none',
    padding: '0',
  },
};

interface ButtonProps {
  theme: 'primary' | 'secondary' | 'text';
  wdith?: string;
  height?: string;
  align?: string;
}

export const CustomButtom = styled.button<ButtonProps>`
  padding: ${(props) => themes[props.theme].padding};
  border-radius: 5px;
  gap: 10px;
  cursor: pointer;
  width: ${(props) => props.wdith || 'auto'};
  height: ${(props) => props.height || 'auto'};
  text-align: ${(props) => props.align || 'none'};

  background: ${(props) => themes[props.theme].background};
  color: ${(props) => themes[props.theme].color};
  border: ${(props) => themes[props.theme].border};
  display: ${(props) => themes[props.theme].display};
  align-items: center;

  transition: .4s ease-in-out;
  justify-content: center;
  
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    transition: .2s ease-in-out;
  }
`;

export const LoadingIcon = styled(Loading)`
  animation: spin .5s linear infinite;
  margin-right: 5px;
  transition: 2s ease-in-out;
  transform: scale(5);
  color: white;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg)
    }
  }
`;
