import Tippy from "@tippyjs/react";
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import MediaSlas from "../../components/Chart/Bars/mediasSlas";
import VolumeProducaoDiaria from "../../components/Chart/Bars/volumeProducaoDiaria";
import ProducaoPorHora from "../../components/Chart/Lines/producaoPorHora";
import { PageStructureWrapper } from "../../components/PageStructureWrapper";
import StepJourney from "../../components/StepJourney";
import UpdatedAt from "../../components/UpdatedAt";
import api from "../../service/api";
import { formatDateAndHour, formatHour } from "../../utils/Date";
import { GraphicsWrapper, MonthLine } from "../AnaliseOperacaoes/style";
import { ConsultaResultWrapper } from "../ConsutaDePropostas/style";
import { FlexColumnWrapper, LineSection, Monitor, ProductionDetail, ProductionDetails, ProductionTitle, ProdutionWrapper, SlaDetail, SlaWrapper, SlasWrapper } from "./style";


export const HomePage = () => {

    type NameMapping = {
        acrofields: string;
        login: string;
        token: string;
        "proposal-selection": string;
        liveness: string;
        "facial-biometry": string;
        "document-upload": string;
        "others-upload": string;
        "cet-signature": string;
        "ccb-signature": string;
        finished: string;
    };

    const order = [
        "acrofields",
        "login",
        "token",
        "proposal-selection",
        "liveness",
        "facial-biometry",
        "document-upload",
        "others-upload",
        "cet-signature",
        "ccb-signature",
        "finished"
    ];

    const nameMapping: NameMapping = {
        acrofields: "Cargas Processadas",
        login: "Login",
        token: "Token",
        "proposal-selection": "Proposta Selecionadas",
        liveness: "Liveness",
        "facial-biometry": "Biometria Facial",
        "document-upload": "Upload de Documentos",
        "others-upload": "Upload Outros",
        "cet-signature": "CET",
        "ccb-signature": "CCB",
        finished: "Finalizado"
    };

    const [producaoDiaria, setProducaoDiaria] = useState<any>({ total: [], targetWeek: 714, targetWeekend: 98 });
    const [producaoDiariaGrafico, setProducaoDiariaGrafico] = useState<any>({ done: [], running: [] });
    const [producaoAnual, setProducaoAnual] = useState<any>({ done: 0, running: 0 });
    const [producaoAnualGrafico, setProducaoAnualGrafico] = useState<any>([]);
    const [slaPorDia, setSlaPorDia] = useState<any>(0);
    const [mediaSlaDia, setMediaSlaDia] = useState<any>(0);
    const [mediaSlasGeral, setMediaSlasGeral] = useState<any>(0);
    const [recebidosDia, setRecebidosDia] = useState<any>(0);
    const [recebidosMes, setRecebidosMes] = useState<any>(0);
    const [emAndamentoDia, setEmAndamentoDia] = useState<any>(0);
    const [emAndamentoMes, setEmAndamentoMes] = useState<any>(0);
    const [finalizadosDia, setFinalizadosDia] = useState<any>(0);
    const [conclusaoDia, setConclusaoDia] = useState<any>(0);
    const currentDate = useMemo(() => {
        return `${new Date().getFullYear()}-${(new Date().getMonth() + 1).toString().padStart(2, '0')}-${new Date().getDate().toString().padStart(2, '0')}`;
    }, []);
    const [funnelResponse, setFunnelResponse] = useState<any>();
    const [proponente, setProponente] = useState<any>([]);
    const [monitor, setMonitor] = useState<any>([]);
    const [mediaRiscos, setMediaRiscos] = useState<any>({});
    const [mediaSlas, setMediaSlas] = useState<any>({});
    const [finalizadoAteUmaHora, setFinalizadoAteUmaHora] = useState<any>(0);
    const [atualizadoEm, setAtualizadoEm] = useState<any>();


    const calculateMonthlyChange = (total: number[]): number[] => {
        const monthlyChange: number[] = [0];
        for (let i = 1; i < total.length; i++) {
            const change = ((total[i] - total[i - 1]) / total[i - 1]) * 100;
            if (isNaN(change)) {
                monthlyChange.push(0);
            } else {
                monthlyChange.push(change);
            }
        }
        return monthlyChange;
    };

    const processarProducaoDiaria = () => {
        api.get('/dashboard/v3/home').then(response => {
            console.log(response.data?.finished?.map((item: any) => item.quantity));
            setProducaoDiaria({ total: response.data?.finished?.map((item: any) => item.quantity), targetWeek: 714, targetWeekend: 98 });
            setRecebidosDia(response.data?.received?.find((item: any) => item.datetime === currentDate)?.quantity);
            setRecebidosMes(response.data?.received?.reduce((sum: any, item: { quantity: any; }) => sum + item.quantity, 0));
            setEmAndamentoDia(response.data?.downloaded?.find((item: any) => item.datetime === currentDate)?.quantity);
            setEmAndamentoMes(response.data?.downloaded?.map((item: any) => item.quantity).reduce((acc: any, item: any) => acc + item, 0));
            setFinalizadosDia(response.data?.finished?.find((item: any) => item.datetime === currentDate)?.quantity);
            setProducaoDiariaGrafico({ done: response.data?.daily_production?.finalizados?.map((item: any) => item.quantity), running: response.data?.daily_production?.em_andamento?.map((item: any) => item.quantity) });
            setProducaoAnualGrafico({ total: response.data?.daily_production?.meses, monthlyChange: calculateMonthlyChange(response.data?.daily_production?.meses) });
            console.log('calculado', JSON.stringify({ total: response.data?.daily_production?.meses, monthlyChange: calculateMonthlyChange(response.data?.daily_production?.meses) }))
            setProducaoAnual({ done: response.data?.daily_production?.meses[new Date().getMonth()] })
        }).catch((error) => {
            toast.error('Ocorreu um erro ao consultar informações do dashboard');
            console.log('error', error);
        })
    }

    const getMediaSlasInterval = () => {
        api.get('/dashboard/v3/get_sla_by_intervals').then(response => {
            const data = [];
            setFinalizadoAteUmaHora(response.data?.up_to_1)
            data.push(response.data?.from_1_to_2)
            data.push(response.data?.from_2_to_3)
            data.push(response.data?.from_3_to_6)
            data.push(response.data?.from_6_to_9)
            data.push(response.data?.from_9_to_12)
            data.push(response.data?.from_12_to_24)
            data.push(response.data?.from_24_to_48)
            data.push(response.data?.above_48)
            setMediaSlas(data);
        }).catch((error) => {
            toast.error('Ocorreu um erro ao consultar informações do SLA');
            console.log('error', error);
        })
    }

    const getStatusServices = () => {
        const status: any = {};
        api.get('/dashboard/v3/status-service').then(response => {


            if (response.data?.portal) {
                const portal = response.data?.portal;
                status.newspace = portal.backend?.responsive && portal.database?.responsive && portal.newspace?.responsive ? 'D' : 'error';
                const portalStatus = (
                    portal.backend?.responsive
                );
                status.portal = portalStatus ? 'D' : 'error';
            } else {
                status.portal = 'P';
            }

            status.saffe = response.data?.saffe?.status === 200 ? 'D' : 'error';
            status.serpro = response.data?.serpro?.statusCode === 200 ? 'D' : 'error';
            status.zenvia = response.data?.zenvia === 1 ? 'D' : 'error';

            setMonitor(status);
        })
        console.log('monitor', monitor)
    }


    const getSlaByDays = () => {
        api.get('/dashboard/v3/sla-by-journey-by-days').then(response => {
            setSlaPorDia(response.data?.find((item: any) => item.data === currentDate)?.sla);

            const dadosAteHoje = response.data.filter((item: any) => new Date(item.data) <= new Date(currentDate));
            const somaDados = dadosAteHoje.reduce((acc: any, item: any) => acc + item.sla, 0);
            const media = dadosAteHoje.length > 0 ? somaDados / dadosAteHoje.length : 0;
            setMediaSlaDia(media);
        })
    }

    const processSlaGeralJorney = () => {
        const dadosTransformados: any = {
            sla: [],
        };
        const dados: any = [];

        api.get("/dashboard/v3/get_sla_for_current_month").then((response) => {
            response.data?.forEach((dados: any) => {
                dadosTransformados.sla.push(dados.average_sla_hours);
            });
            dadosTransformados.sla.forEach((dado: any) => {
                if (dado !== null && dado !== undefined && dado !== 0)
                    dados.push(dado);
            })

            setConclusaoDia(response.data?.find((item: any) => item.day === currentDate)?.average_sla_hours);
            const somaDados = dados.reduce((a: any, b: any) => a + b, 0);
            const media = somaDados / dados.length;
            console.log('media', media)
            console.log('length', dados.length)
            setMediaSlasGeral(media);
        });
    };

    const formatarDoisDigitos = (numero: number, incluirHoras: boolean = false): string => {
        return numero.toString().padStart(2, '0');
    };

    const getSlaPorDia = (): string => {
        const minutos = Math.floor(slaPorDia);
        const segundos = Math.round((slaPorDia - minutos) * 60);

        const minutosFormatados = formatarDoisDigitos(minutos);
        const segundosFormatados = formatarDoisDigitos(segundos);

        return `00:${minutosFormatados}:${segundosFormatados}`;
    };

    const getMediaGeral = (): string => {
        const minutos = Math.floor(mediaSlaDia);
        const segundos = Math.round((mediaSlaDia - minutos) * 60);

        const minutosFormatados = formatarDoisDigitos(minutos);
        const segundosFormatados = formatarDoisDigitos(segundos);

        return `00:${minutosFormatados}:${segundosFormatados}`;
    };

    const getMediaConclusaoGeral = (): string => {
        return formatHour(mediaSlasGeral);
    };

    const processDataFunnel = () => {
        api.get('/newdashboard/funnel').then((response) => {
            setFunnelResponse(response.data);
            let json = response.data.continued.day;
            console.log(order.map((key) => ({
                'name': (nameMapping as any)[key],
                'value': json[key][0]
            })))

            setProponente(order.map((key) => ({
                'name': (nameMapping as any)[key],
                'value': json[key][0]
            })));
        }).catch((error) => {
            toast.error('Ocorreu um erro ao consultar informações do funil');
            console.log('error', error);
        })
    }

    const getMediaRiscos = () => {
        api.get('/dashboard/v3/medias-riscos').then((response) => {
            setMediaRiscos(response.data)
            setAtualizadoEm(new Date());
        });
    }



    useEffect(() => {
        processarProducaoDiaria();
        processDataFunnel();
        getSlaByDays();
        processSlaGeralJorney();
        getStatusServices();
        getMediaRiscos();
        getMediaSlasInterval();

        const intervalId = setInterval(getMediaRiscos, 300000);
        return () => {
            clearInterval(intervalId);
        };
    }, [])


    const statusServices = useMemo(() => ({
        newspace: monitor.newspace,
        saffe: monitor.saffe,
        serpro: monitor.serpro,
        zenvia: monitor.zenvia,
        portal: monitor.portal
    }), [monitor]);


    const mediaSlasMemos = useMemo(() => {
        return mediaSlas;
    }, [mediaSlas]);

    const dataForGraph = useMemo(() => {
        return {
            total: mediaSlasMemos,
            media: [1239, 567, 697, 224, 96, 954, 479, 703]
        };
    }, [mediaSlasMemos]);



    return <PageStructureWrapper>
        <ConsultaResultWrapper>
            <LineSection>
                <ProdutionWrapper>
                    <ProductionTitle>Monitor dos Serviços <UpdatedAt updatedAt={formatDateAndHour(atualizadoEm)} /></ProductionTitle>
                    <Monitor>
                        <Tippy content='Status do serviço do AppAcrofield'>
                            <span>
                                <StepJourney size="24px" status={statusServices?.newspace && 'D'} stepName="Cargas Processadas" />
                            </span>
                        </Tippy>
                        <Tippy content='Status do serviço Newspace'>
                            <span>
                                <StepJourney size="24px" status={statusServices?.newspace} stepName="ValidarDados" />
                            </span>
                        </Tippy>
                        <Tippy content='Status do serviço SMS'>
                            <span>
                                <StepJourney size="24px" status={statusServices?.zenvia} stepName="Token" />
                            </span>
                        </Tippy>
                        <Tippy content='Status do serviço UNICO'>
                            <span>
                                <StepJourney size="24px" status={statusServices?.saffe} stepName="Liveness PF" />
                            </span>
                        </Tippy>
                        <Tippy content='Status do serviço SAFFE'>
                            <span>
                                <StepJourney size="24px" status={statusServices?.saffe} stepName="Liveness PJ" />
                            </span>
                        </Tippy>
                        <Tippy content='Status do serviço DENATRAN'>
                            <span>
                                <StepJourney size="24px" status={statusServices?.serpro} stepName="Biometria" />
                            </span>
                        </Tippy>
                        <Tippy content='Status do ambiente de assinatura'>
                            <span>
                                <StepJourney size="24px" status={statusServices?.portal} stepName="Ambiente" />
                            </span>
                        </Tippy>
                    </Monitor>
                </ProdutionWrapper>
            </LineSection>
            <LineSection>
                <ProdutionWrapper>
                    <ProductionTitle>Volume Diário</ProductionTitle>
                    <ProductionDetails>
                        <Tippy content="Quantidade de propostas recebidas hoje">
                            <ProductionDetail>
                                <span>Recebidas</span>
                                <span>{recebidosDia?.toLocaleString('pt-BR')}</span>
                            </ProductionDetail>
                        </Tippy>
                        <Tippy content="Propostas que foram selecionadas durante a jornada">
                            <ProductionDetail>
                                <span>Em Andamento</span>
                                <span>{emAndamentoDia?.toLocaleString('pt-BR')}</span>
                            </ProductionDetail>
                        </Tippy>
                        <Tippy content="Quantidade de propostas finalizadas hoje">
                            <ProductionDetail>
                                <span>Finalizadas</span>
                                <span>{finalizadosDia?.toLocaleString('pt-BR')}</span>
                            </ProductionDetail>
                        </Tippy>
                        {/* <ProductionDetail>
                            <span>Meta</span>
                            <span>{1400?.toLocaleString('pt-BR')}</span>
                        </ProductionDetail> */}
                    </ProductionDetails>
                    <GraphicsWrapper>
                        <MonthLine>
                            <VolumeProducaoDiaria data={producaoDiaria} />
                        </MonthLine>
                    </GraphicsWrapper>
                </ProdutionWrapper>
                <ProdutionWrapper>
                    <ProductionTitle>Volume Mensal</ProductionTitle>
                    <ProductionDetails>
                        <ProductionDetail>
                            <span>Recebidas</span>
                            <span>{recebidosMes}</span>
                        </ProductionDetail>
                        <ProductionDetail>
                            <span>Em Andamento</span>
                            <span>{emAndamentoMes?.toLocaleString('pt-BR')}</span>
                        </ProductionDetail>
                        <ProductionDetail>
                            <span>Finalizadas</span>
                            <span>{producaoAnual.done?.toLocaleString('pt-BR')}</span>
                        </ProductionDetail>
                        {/* <ProductionDetail>
                            <span>Meta</span>
                            <span>{30000?.toLocaleString('pt-BR')}</span>
                        </ProductionDetail> */}
                    </ProductionDetails>
                    <GraphicsWrapper>
                        <MonthLine>
                            <VolumeProducaoDiaria data={producaoAnualGrafico} />
                        </MonthLine>
                    </GraphicsWrapper>
                </ProdutionWrapper>
            </LineSection>
            <LineSection>
                <ProdutionWrapper>
                    <ProductionTitle>Volume x Horário</ProductionTitle>
                    <GraphicsWrapper>
                        <MonthLine>
                            <ProducaoPorHora data={producaoDiariaGrafico} />
                        </MonthLine>
                    </GraphicsWrapper>
                </ProdutionWrapper>
                <ProdutionWrapper>
                    <ProductionTitle>SLA's</ProductionTitle>
                    <SlaWrapper>
                        <MonthLine>
                            <MediaSlas data={dataForGraph} />
                        </MonthLine>
                        <FlexColumnWrapper>
                            <SlasWrapper>
                                <span>Qtde. Finalizados</span>
                                <ProductionDetails>
                                    <SlaDetail>
                                        <span>Até 1 hora</span>
                                        <span>{finalizadoAteUmaHora?.toLocaleString('pt-BR')}</span>
                                    </SlaDetail>
                                </ProductionDetails>
                            </SlasWrapper>
                            <SlasWrapper>
                                <span>Diário</span>
                                <ProductionDetails>
                                    <SlaDetail>
                                        <span>Jornada</span>
                                        <span>{getSlaPorDia()}</span>
                                    </SlaDetail>
                                    <SlaDetail>
                                        <span>Conclusão</span>
                                        <span>{formatHour(conclusaoDia)}</span>
                                    </SlaDetail>
                                </ProductionDetails>
                            </SlasWrapper>
                            <SlasWrapper>
                                <span>Mensal</span>
                                <ProductionDetails>
                                    <SlaDetail>
                                        <span>Jornada</span>
                                        <span>{getMediaGeral()}</span>
                                    </SlaDetail>
                                    <SlaDetail>
                                        <span>Conclusão</span>
                                        <span>{getMediaConclusaoGeral()}</span>
                                    </SlaDetail>
                                </ProductionDetails>
                            </SlasWrapper>
                        </FlexColumnWrapper>
                    </SlaWrapper>
                </ProdutionWrapper>
            </LineSection>
            <LineSection>
                <ProdutionWrapper>
                    <ProductionTitle>Status Funil Diário</ProductionTitle>
                    <SlaWrapper>
                        <ProductionDetails>
                            <SlaDetail>
                                <span>Cargas Processadas</span>
                                <span>{proponente && proponente[0]?.value}</span>
                            </SlaDetail>
                            <SlaDetail>
                                <span>Login/Token</span>
                                <span>{proponente && proponente[1]?.value + proponente && proponente[2]?.value}</span>
                            </SlaDetail>
                            <SlaDetail>
                                <span>Propostas Selec.</span>
                                <span>{proponente && proponente[3]?.value}</span>
                            </SlaDetail>
                            <SlaDetail>
                                <span>Liveness</span>
                                <span>{proponente && proponente[4]?.value}</span>
                            </SlaDetail>
                        </ProductionDetails>
                    </SlaWrapper>
                    <SlaWrapper>
                        <ProductionDetails>
                            <SlaDetail>
                                <span>Biometria</span>
                                <span>{proponente && proponente[5]?.value}</span>
                            </SlaDetail>
                            <SlaDetail>
                                <span>Upload Documentos</span>
                                <span>{proponente && proponente[6]?.value}</span>
                            </SlaDetail>
                            <SlaDetail>
                                <span>Upload Outros</span>
                                <span>{proponente && proponente[7]?.value}</span>
                            </SlaDetail>
                        </ProductionDetails>
                    </SlaWrapper>
                    <SlaWrapper>
                        <ProductionDetails>
                            <SlaDetail>
                                <span>CET</span>
                                <span>{proponente && proponente[8]?.value}</span>
                            </SlaDetail>
                            <SlaDetail>
                                <span>CCB</span>
                                <span>{proponente && proponente[9]?.value}</span>
                            </SlaDetail>
                        </ProductionDetails>
                    </SlaWrapper>
                    <SlaWrapper>
                        <ProductionDetails>
                            <SlaDetail>
                                <span>Finalizados</span>
                                <span>{proponente && proponente[10]?.value}</span>
                            </SlaDetail>
                        </ProductionDetails>
                    </SlaWrapper>
                </ProdutionWrapper>
                <ProdutionWrapper>
                    <ProductionTitle>Monitor de Riscos x Média Histórica de 3 meses</ProductionTitle>
                    <SlaWrapper>
                        <SlasWrapper>
                            <span>Mais de 10 Liveness</span>
                            <ProductionDetails>
                                <Tippy content="Qtde propostas hoje">
                                    <SlaDetail>
                                        <span>Dia</span>
                                        <span>{mediaRiscos?.liveness?.daily_count?.toLocaleString('pt-BR') || 0}</span>
                                    </SlaDetail>
                                </Tippy>
                                <SlaDetail>
                                    <span>Média</span>
                                    <span>{mediaRiscos?.liveness?.last_three_month_average?.toLocaleString('pt-BR') || 0}</span>
                                </SlaDetail>
                            </ProductionDetails>
                        </SlasWrapper>
                        <SlasWrapper>
                            <span>Biometria Inferior a 70%</span>
                            <ProductionDetails>
                                <Tippy content="Qtde propostas hoje">
                                    <SlaDetail>
                                        <span>Dia</span>
                                        <span>{mediaRiscos?.biometira?.daily_count?.toLocaleString('pt-BR') || 0}</span>
                                    </SlaDetail>
                                </Tippy>
                                <SlaDetail>
                                    <span>Média</span>
                                    <span>{mediaRiscos?.biometira?.last_three_month_average?.toLocaleString('pt-BR') || 0}</span>
                                </SlaDetail>
                            </ProductionDetails>
                        </SlasWrapper>
                        <SlasWrapper>
                            <span>Volume de Suspeitos</span>
                            <ProductionDetails>
                                <Tippy content="Qtde propostas hoje">
                                    <SlaDetail>
                                        <span>Dia</span>
                                        <span>{mediaRiscos?.suspect?.daily_count?.toLocaleString('pt-BR') || 0}</span>
                                    </SlaDetail>
                                </Tippy>
                                <SlaDetail>
                                    <span>Média</span>
                                    <span>{mediaRiscos?.suspect?.last_three_month_average?.toLocaleString('pt-BR') || 0}</span>
                                </SlaDetail>
                            </ProductionDetails>
                        </SlasWrapper>
                    </SlaWrapper>
                </ProdutionWrapper>
            </LineSection>
        </ConsultaResultWrapper>
    </PageStructureWrapper>
}