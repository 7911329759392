import { useEffect, useState } from "react";
import AnalysysChart from "../../components/Chart/Lines/analisys";
import SlaGeral from "../../components/Chart/Lines/slaGeral";
import SlaJourney from "../../components/Chart/Lines/slaJourney";
import { PageStructureWrapper } from "../../components/PageStructureWrapper";
import api from "../../service/api";
import { compararDatas, formatHourToString } from "../../utils/Date";
import { ConsultaResultWrapper } from "../ConsutaDePropostas/style";
import { GraphicsWrapper, MonthLine, Section } from "./style";

export const AnaliseOperacoesPage = () => {
  type NameMapping = {
    acrofields: string;
    login: string;
    token: string;
    "proposal-selection": string;
    liveness: string;
    "facial-biometry": string;
    "document-upload": string;
    "others-upload": string;
    "cet-signature": string;
    "ccb-signature": string;
    finished: string;
  };

  const order = [
    "acrofields",
    "login",
    "token",
    "proposal-selection",
    "liveness",
    "facial-biometry",
    "document-upload",
    "others-upload",
    "cet-signature",
    "ccb-signature",
    "finished",
  ];

  const nameMapping: NameMapping = {
    acrofields: "Cargas Processadas",
    login: "Login",
    token: "Token",
    "proposal-selection": "Proposta Selecionadas",
    liveness: "Liveness",
    "facial-biometry": "Biometria Facial",
    "document-upload": "Upload de Documentos",
    "others-upload": "Upload Outros",
    "cet-signature": "CET",
    "ccb-signature": "CCB",
    finished: "Finalizado",
  };

  const [data, setData] = useState<any>({
    first_time: [],
    second_time: [],
    third_time: [],
    fourth_time: [],
    other_time: [],
  });

  const [receivedStartedAndNotStarted, setReceivedStartedAndNotStarted] =
    useState<any>({
      received: [],
      started: [],
      not_started: [],
    });

  const processData = () => {
    const intervalOne: any[] = [];
    const intervalTwo: any[] = [];
    const intervalThree: any[] = [];
    const intervalFour: any[] = [];
    const otherTime: any[] = [];

    api.get("/dashboard/v3/timeline-days").then((response: any) => {
      response.data?.forEach((dados: any) => {
        switch (dados.interval) {
          case "1":
            intervalOne.push(dados.total);
            break;
          case "2":
            intervalTwo.push(dados.total);
            break;
          case "3":
            intervalThree.push(dados.total);
            break;
          case "4":
            intervalFour.push(dados.total);
            break;
          case "Outro":
            otherTime.push(dados.total);
            break;
          default:
            break;
        }
      });

      setData({
        first_time: intervalOne,
        second_time: intervalTwo,
        third_time: intervalThree,
        fourth_time: intervalFour,
        other_time: otherTime,
      });
    });
  };

  const processDataReceivedStartedAndNotStarted = () => {
    const dadosTransformados: any = {
      received: [],
      started: [],
      not_started: [],
    };

    api.get("/dashboard/v3/received-no-started-finished").then((response) => {
      response.data?.forEach((dados: any) => {
        dadosTransformados.received.push(dados.received);
        dadosTransformados.started.push(dados.started);
        dadosTransformados.not_started.push(dados.not_started);
      });
      setReceivedStartedAndNotStarted(dadosTransformados);
    });
  };

  const [slaJornada, setSlaJornada] = useState<any>({ sla: [] });
  const [slaGeralJornada, setGeralSlaJornada] = useState<any>({ sla: [] });
  const [mediaSlas, setMediaSlas] = useState<any>(0);
  const [mediaSlasGeral, setMediaSlasGeral] = useState<any>(0);

  const [volumeDiarioPorSessao, setVolumeDiarioPorSessao] = useState<any>([]);

  const [volumeDiarioPorHoraSessao, setVolumeDiarioPorHoraSessao] =
    useState<any>([
      {
        data: "2024-02-01",
        times: [
          {
            hour: 0,
            volume: 0,
          },
          {
            hour: 1,
            volume: 0,
          },
          {
            hour: 2,
            volume: 0,
          },
          {
            hour: 3,
            volume: 0,
          },
          {
            hour: 4,
            volume: 0,
          },
          {
            hour: 5,
            volume: 0,
          },
          {
            hour: 6,
            volume: 0,
          },
          {
            hour: 7,
            volume: 0,
          },
          {
            hour: 8,
            volume: 0,
          },
          {
            hour: 9,
            volume: 0,
          },
          {
            hour: 10,
            volume: 0,
          },
          {
            hour: 11,
            volume: 0,
          },
          {
            hour: 12,
            volume: 0,
          },
          {
            hour: 13,
            volume: 0,
          },
          {
            hour: 14,
            volume: 0,
          },
          {
            hour: 15,
            volume: 0,
          },
          {
            hour: 16,
            volume: 0,
          },
          {
            hour: 17,
            volume: 0,
          },
          {
            hour: 18,
            volume: 0,
          },
          {
            hour: 19,
            volume: 0,
          },
          {
            hour: 20,
            volume: 0,
          },
          {
            hour: 21,
            volume: 0,
          },
          {
            hour: 22,
            volume: 0,
          },
          {
            hour: 23,
            volume: 0,
          },
        ],
      },
      {
        data: "2024-02-02",
        times: [
          {
            hour: 0,
            volume: 0,
          },
          {
            hour: 1,
            volume: 0,
          },
          {
            hour: 2,
            volume: 0,
          },
          {
            hour: 3,
            volume: 0,
          },
          {
            hour: 4,
            volume: 0,
          },
          {
            hour: 5,
            volume: 0,
          },
          {
            hour: 6,
            volume: 0,
          },
          {
            hour: 7,
            volume: 0,
          },
          {
            hour: 8,
            volume: 0,
          },
          {
            hour: 9,
            volume: 0,
          },
          {
            hour: 10,
            volume: 0,
          },
          {
            hour: 11,
            volume: 0,
          },
          {
            hour: 12,
            volume: 0,
          },
          {
            hour: 13,
            volume: 0,
          },
          {
            hour: 14,
            volume: 0,
          },
          {
            hour: 15,
            volume: 0,
          },
          {
            hour: 16,
            volume: 0,
          },
          {
            hour: 17,
            volume: 0,
          },
          {
            hour: 18,
            volume: 0,
          },
          {
            hour: 19,
            volume: 0,
          },
          {
            hour: 20,
            volume: 0,
          },
          {
            hour: 21,
            volume: 0,
          },
          {
            hour: 22,
            volume: 0,
          },
          {
            hour: 23,
            volume: 0,
          },
        ],
      },
      {
        data: "2024-02-03",
        times: [
          {
            hour: 0,
            volume: 0,
          },
          {
            hour: 1,
            volume: 0,
          },
          {
            hour: 2,
            volume: 0,
          },
          {
            hour: 3,
            volume: 0,
          },
          {
            hour: 4,
            volume: 0,
          },
          {
            hour: 5,
            volume: 0,
          },
          {
            hour: 6,
            volume: 0,
          },
          {
            hour: 7,
            volume: 0,
          },
          {
            hour: 8,
            volume: 0,
          },
          {
            hour: 9,
            volume: 0,
          },
          {
            hour: 10,
            volume: 0,
          },
          {
            hour: 11,
            volume: 0,
          },
          {
            hour: 12,
            volume: 0,
          },
          {
            hour: 13,
            volume: 0,
          },
          {
            hour: 14,
            volume: 0,
          },
          {
            hour: 15,
            volume: 0,
          },
          {
            hour: 16,
            volume: 0,
          },
          {
            hour: 17,
            volume: 0,
          },
          {
            hour: 18,
            volume: 0,
          },
          {
            hour: 19,
            volume: 0,
          },
          {
            hour: 20,
            volume: 0,
          },
          {
            hour: 21,
            volume: 0,
          },
          {
            hour: 22,
            volume: 0,
          },
          {
            hour: 23,
            volume: 0,
          },
        ],
      },
      {
        data: "2024-02-04",
        times: [
          {
            hour: 0,
            volume: 0,
          },
          {
            hour: 1,
            volume: 0,
          },
          {
            hour: 2,
            volume: 0,
          },
          {
            hour: 3,
            volume: 0,
          },
          {
            hour: 4,
            volume: 0,
          },
          {
            hour: 5,
            volume: 0,
          },
          {
            hour: 6,
            volume: 0,
          },
          {
            hour: 7,
            volume: 0,
          },
          {
            hour: 8,
            volume: 0,
          },
          {
            hour: 9,
            volume: 0,
          },
          {
            hour: 10,
            volume: 0,
          },
          {
            hour: 11,
            volume: 0,
          },
          {
            hour: 12,
            volume: 0,
          },
          {
            hour: 13,
            volume: 0,
          },
          {
            hour: 14,
            volume: 0,
          },
          {
            hour: 15,
            volume: 0,
          },
          {
            hour: 16,
            volume: 0,
          },
          {
            hour: 17,
            volume: 0,
          },
          {
            hour: 18,
            volume: 0,
          },
          {
            hour: 19,
            volume: 0,
          },
          {
            hour: 20,
            volume: 0,
          },
          {
            hour: 21,
            volume: 0,
          },
          {
            hour: 22,
            volume: 0,
          },
          {
            hour: 23,
            volume: 0,
          },
        ],
      },
      {
        data: "2024-02-05",
        times: [
          {
            hour: 0,
            volume: 0,
          },
          {
            hour: 1,
            volume: 0,
          },
          {
            hour: 2,
            volume: 0,
          },
          {
            hour: 3,
            volume: 0,
          },
          {
            hour: 4,
            volume: 0,
          },
          {
            hour: 5,
            volume: 0,
          },
          {
            hour: 6,
            volume: 0,
          },
          {
            hour: 7,
            volume: 0,
          },
          {
            hour: 8,
            volume: 0,
          },
          {
            hour: 9,
            volume: 0,
          },
          {
            hour: 10,
            volume: 0,
          },
          {
            hour: 11,
            volume: 0,
          },
          {
            hour: 12,
            volume: 0,
          },
          {
            hour: 13,
            volume: 0,
          },
          {
            hour: 14,
            volume: 0,
          },
          {
            hour: 15,
            volume: 0,
          },
          {
            hour: 16,
            volume: 0,
          },
          {
            hour: 17,
            volume: 0,
          },
          {
            hour: 18,
            volume: 0,
          },
          {
            hour: 19,
            volume: 0,
          },
          {
            hour: 20,
            volume: 0,
          },
          {
            hour: 21,
            volume: 0,
          },
          {
            hour: 22,
            volume: 0,
          },
          {
            hour: 23,
            volume: 0,
          },
        ],
      },
      {
        data: "2024-02-06",
        times: [
          {
            hour: 0,
            volume: 0,
          },
          {
            hour: 1,
            volume: 0,
          },
          {
            hour: 2,
            volume: 0,
          },
          {
            hour: 3,
            volume: 0,
          },
          {
            hour: 4,
            volume: 0,
          },
          {
            hour: 5,
            volume: 0,
          },
          {
            hour: 6,
            volume: 0,
          },
          {
            hour: 7,
            volume: 0,
          },
          {
            hour: 8,
            volume: 0,
          },
          {
            hour: 9,
            volume: 0,
          },
          {
            hour: 10,
            volume: 0,
          },
          {
            hour: 11,
            volume: 0,
          },
          {
            hour: 12,
            volume: 0,
          },
          {
            hour: 13,
            volume: 0,
          },
          {
            hour: 14,
            volume: 0,
          },
          {
            hour: 15,
            volume: 0,
          },
          {
            hour: 16,
            volume: 0,
          },
          {
            hour: 17,
            volume: 0,
          },
          {
            hour: 18,
            volume: 0,
          },
          {
            hour: 19,
            volume: 0,
          },
          {
            hour: 20,
            volume: 0,
          },
          {
            hour: 21,
            volume: 0,
          },
          {
            hour: 22,
            volume: 0,
          },
          {
            hour: 23,
            volume: 0,
          },
        ],
      },
      {
        data: "2024-02-07",
        times: [
          {
            hour: 0,
            volume: 0,
          },
          {
            hour: 1,
            volume: 0,
          },
          {
            hour: 2,
            volume: 0,
          },
          {
            hour: 3,
            volume: 0,
          },
          {
            hour: 4,
            volume: 0,
          },
          {
            hour: 5,
            volume: 0,
          },
          {
            hour: 6,
            volume: 0,
          },
          {
            hour: 7,
            volume: 0,
          },
          {
            hour: 8,
            volume: 0,
          },
          {
            hour: 9,
            volume: 0,
          },
          {
            hour: 10,
            volume: 0,
          },
          {
            hour: 11,
            volume: 0,
          },
          {
            hour: 12,
            volume: 0,
          },
          {
            hour: 13,
            volume: 0,
          },
          {
            hour: 14,
            volume: 0,
          },
          {
            hour: 15,
            volume: 0,
          },
          {
            hour: 16,
            volume: 0,
          },
          {
            hour: 17,
            volume: 0,
          },
          {
            hour: 18,
            volume: 0,
          },
          {
            hour: 19,
            volume: 0,
          },
          {
            hour: 20,
            volume: 0,
          },
          {
            hour: 21,
            volume: 0,
          },
          {
            hour: 22,
            volume: 0,
          },
          {
            hour: 23,
            volume: 0,
          },
        ],
      },
      {
        data: "2024-02-08",
        times: [
          {
            hour: 0,
            volume: 0,
          },
          {
            hour: 1,
            volume: 0,
          },
          {
            hour: 2,
            volume: 0,
          },
          {
            hour: 3,
            volume: 0,
          },
          {
            hour: 4,
            volume: 0,
          },
          {
            hour: 5,
            volume: 0,
          },
          {
            hour: 6,
            volume: 0,
          },
          {
            hour: 7,
            volume: 0,
          },
          {
            hour: 8,
            volume: 0,
          },
          {
            hour: 9,
            volume: 0,
          },
          {
            hour: 10,
            volume: 0,
          },
          {
            hour: 11,
            volume: 0,
          },
          {
            hour: 12,
            volume: 0,
          },
          {
            hour: 13,
            volume: 0,
          },
          {
            hour: 14,
            volume: 0,
          },
          {
            hour: 15,
            volume: 0,
          },
          {
            hour: 16,
            volume: 0,
          },
          {
            hour: 17,
            volume: 0,
          },
          {
            hour: 18,
            volume: 0,
          },
          {
            hour: 19,
            volume: 0,
          },
          {
            hour: 20,
            volume: 0,
          },
          {
            hour: 21,
            volume: 0,
          },
          {
            hour: 22,
            volume: 0,
          },
          {
            hour: 23,
            volume: 0,
          },
        ],
      },
      {
        data: "2024-02-09",
        times: [
          {
            hour: 0,
            volume: 0,
          },
          {
            hour: 1,
            volume: 0,
          },
          {
            hour: 2,
            volume: 0,
          },
          {
            hour: 3,
            volume: 0,
          },
          {
            hour: 4,
            volume: 0,
          },
          {
            hour: 5,
            volume: 0,
          },
          {
            hour: 6,
            volume: 0,
          },
          {
            hour: 7,
            volume: 0,
          },
          {
            hour: 8,
            volume: 0,
          },
          {
            hour: 9,
            volume: 0,
          },
          {
            hour: 10,
            volume: 0,
          },
          {
            hour: 11,
            volume: 0,
          },
          {
            hour: 12,
            volume: 0,
          },
          {
            hour: 13,
            volume: 0,
          },
          {
            hour: 14,
            volume: 0,
          },
          {
            hour: 15,
            volume: 0,
          },
          {
            hour: 16,
            volume: 0,
          },
          {
            hour: 17,
            volume: 0,
          },
          {
            hour: 18,
            volume: 0,
          },
          {
            hour: 19,
            volume: 0,
          },
          {
            hour: 20,
            volume: 0,
          },
          {
            hour: 21,
            volume: 0,
          },
          {
            hour: 22,
            volume: 0,
          },
          {
            hour: 23,
            volume: 0,
          },
        ],
      },
      {
        data: "2024-02-10",
        times: [
          {
            hour: 0,
            volume: 0,
          },
          {
            hour: 1,
            volume: 0,
          },
          {
            hour: 2,
            volume: 0,
          },
          {
            hour: 3,
            volume: 0,
          },
          {
            hour: 4,
            volume: 0,
          },
          {
            hour: 5,
            volume: 0,
          },
          {
            hour: 6,
            volume: 0,
          },
          {
            hour: 7,
            volume: 0,
          },
          {
            hour: 8,
            volume: 0,
          },
          {
            hour: 9,
            volume: 0,
          },
          {
            hour: 10,
            volume: 0,
          },
          {
            hour: 11,
            volume: 0,
          },
          {
            hour: 12,
            volume: 0,
          },
          {
            hour: 13,
            volume: 0,
          },
          {
            hour: 14,
            volume: 0,
          },
          {
            hour: 15,
            volume: 0,
          },
          {
            hour: 16,
            volume: 0,
          },
          {
            hour: 17,
            volume: 0,
          },
          {
            hour: 18,
            volume: 0,
          },
          {
            hour: 19,
            volume: 0,
          },
          {
            hour: 20,
            volume: 0,
          },
          {
            hour: 21,
            volume: 0,
          },
          {
            hour: 22,
            volume: 0,
          },
          {
            hour: 23,
            volume: 0,
          },
        ],
      },
      {
        data: "2024-02-11",
        times: [
          {
            hour: 0,
            volume: 0,
          },
          {
            hour: 1,
            volume: 0,
          },
          {
            hour: 2,
            volume: 0,
          },
          {
            hour: 3,
            volume: 0,
          },
          {
            hour: 4,
            volume: 0,
          },
          {
            hour: 5,
            volume: 0,
          },
          {
            hour: 6,
            volume: 0,
          },
          {
            hour: 7,
            volume: 0,
          },
          {
            hour: 8,
            volume: 0,
          },
          {
            hour: 9,
            volume: 0,
          },
          {
            hour: 10,
            volume: 0,
          },
          {
            hour: 11,
            volume: 0,
          },
          {
            hour: 12,
            volume: 0,
          },
          {
            hour: 13,
            volume: 0,
          },
          {
            hour: 14,
            volume: 0,
          },
          {
            hour: 15,
            volume: 0,
          },
          {
            hour: 16,
            volume: 0,
          },
          {
            hour: 17,
            volume: 0,
          },
          {
            hour: 18,
            volume: 0,
          },
          {
            hour: 19,
            volume: 0,
          },
          {
            hour: 20,
            volume: 0,
          },
          {
            hour: 21,
            volume: 0,
          },
          {
            hour: 22,
            volume: 0,
          },
          {
            hour: 23,
            volume: 0,
          },
        ],
      },
      {
        data: "2024-02-12",
        times: [
          {
            hour: 0,
            volume: 0,
          },
          {
            hour: 1,
            volume: 0,
          },
          {
            hour: 2,
            volume: 0,
          },
          {
            hour: 3,
            volume: 0,
          },
          {
            hour: 4,
            volume: 0,
          },
          {
            hour: 5,
            volume: 0,
          },
          {
            hour: 6,
            volume: 0,
          },
          {
            hour: 7,
            volume: 0,
          },
          {
            hour: 8,
            volume: 0,
          },
          {
            hour: 9,
            volume: 0,
          },
          {
            hour: 10,
            volume: 0,
          },
          {
            hour: 11,
            volume: 0,
          },
          {
            hour: 12,
            volume: 0,
          },
          {
            hour: 13,
            volume: 0,
          },
          {
            hour: 14,
            volume: 0,
          },
          {
            hour: 15,
            volume: 0,
          },
          {
            hour: 16,
            volume: 0,
          },
          {
            hour: 17,
            volume: 0,
          },
          {
            hour: 18,
            volume: 0,
          },
          {
            hour: 19,
            volume: 0,
          },
          {
            hour: 20,
            volume: 0,
          },
          {
            hour: 21,
            volume: 0,
          },
          {
            hour: 22,
            volume: 0,
          },
          {
            hour: 23,
            volume: 0,
          },
        ],
      },
      {
        data: "2024-02-13",
        times: [
          {
            hour: 0,
            volume: 0,
          },
          {
            hour: 1,
            volume: 0,
          },
          {
            hour: 2,
            volume: 0,
          },
          {
            hour: 3,
            volume: 0,
          },
          {
            hour: 4,
            volume: 0,
          },
          {
            hour: 5,
            volume: 0,
          },
          {
            hour: 6,
            volume: 0,
          },
          {
            hour: 7,
            volume: 0,
          },
          {
            hour: 8,
            volume: 0,
          },
          {
            hour: 9,
            volume: 0,
          },
          {
            hour: 10,
            volume: 0,
          },
          {
            hour: 11,
            volume: 0,
          },
          {
            hour: 12,
            volume: 0,
          },
          {
            hour: 13,
            volume: 0,
          },
          {
            hour: 14,
            volume: 0,
          },
          {
            hour: 15,
            volume: 0,
          },
          {
            hour: 16,
            volume: 0,
          },
          {
            hour: 17,
            volume: 0,
          },
          {
            hour: 18,
            volume: 0,
          },
          {
            hour: 19,
            volume: 0,
          },
          {
            hour: 20,
            volume: 0,
          },
          {
            hour: 21,
            volume: 0,
          },
          {
            hour: 22,
            volume: 0,
          },
          {
            hour: 23,
            volume: 0,
          },
        ],
      },
      {
        data: "2024-02-14",
        times: [
          {
            hour: 0,
            volume: 0,
          },
          {
            hour: 1,
            volume: 0,
          },
          {
            hour: 2,
            volume: 0,
          },
          {
            hour: 3,
            volume: 0,
          },
          {
            hour: 4,
            volume: 0,
          },
          {
            hour: 5,
            volume: 0,
          },
          {
            hour: 6,
            volume: 0,
          },
          {
            hour: 7,
            volume: 0,
          },
          {
            hour: 8,
            volume: 0,
          },
          {
            hour: 9,
            volume: 0,
          },
          {
            hour: 10,
            volume: 0,
          },
          {
            hour: 11,
            volume: 0,
          },
          {
            hour: 12,
            volume: 0,
          },
          {
            hour: 13,
            volume: 0,
          },
          {
            hour: 14,
            volume: 0,
          },
          {
            hour: 15,
            volume: 0,
          },
          {
            hour: 16,
            volume: 0,
          },
          {
            hour: 17,
            volume: 0,
          },
          {
            hour: 18,
            volume: 0,
          },
          {
            hour: 19,
            volume: 0,
          },
          {
            hour: 20,
            volume: 0,
          },
          {
            hour: 21,
            volume: 0,
          },
          {
            hour: 22,
            volume: 0,
          },
          {
            hour: 23,
            volume: 0,
          },
        ],
      },
      {
        data: "2024-02-15",
        times: [
          {
            hour: 0,
            volume: 0,
          },
          {
            hour: 1,
            volume: 0,
          },
          {
            hour: 2,
            volume: 0,
          },
          {
            hour: 3,
            volume: 0,
          },
          {
            hour: 4,
            volume: 0,
          },
          {
            hour: 5,
            volume: 0,
          },
          {
            hour: 6,
            volume: 0,
          },
          {
            hour: 7,
            volume: 0,
          },
          {
            hour: 8,
            volume: 0,
          },
          {
            hour: 9,
            volume: 0,
          },
          {
            hour: 10,
            volume: 0,
          },
          {
            hour: 11,
            volume: 0,
          },
          {
            hour: 12,
            volume: 0,
          },
          {
            hour: 13,
            volume: 0,
          },
          {
            hour: 14,
            volume: 0,
          },
          {
            hour: 15,
            volume: 0,
          },
          {
            hour: 16,
            volume: 0,
          },
          {
            hour: 17,
            volume: 0,
          },
          {
            hour: 18,
            volume: 0,
          },
          {
            hour: 19,
            volume: 0,
          },
          {
            hour: 20,
            volume: 0,
          },
          {
            hour: 21,
            volume: 0,
          },
          {
            hour: 22,
            volume: 0,
          },
          {
            hour: 23,
            volume: 0,
          },
        ],
      },
      {
        data: "2024-02-16",
        times: [
          {
            hour: 0,
            volume: 0,
          },
          {
            hour: 1,
            volume: 0,
          },
          {
            hour: 2,
            volume: 0,
          },
          {
            hour: 3,
            volume: 0,
          },
          {
            hour: 4,
            volume: 0,
          },
          {
            hour: 5,
            volume: 0,
          },
          {
            hour: 6,
            volume: 0,
          },
          {
            hour: 7,
            volume: 0,
          },
          {
            hour: 8,
            volume: 0,
          },
          {
            hour: 9,
            volume: 0,
          },
          {
            hour: 10,
            volume: 0,
          },
          {
            hour: 11,
            volume: 0,
          },
          {
            hour: 12,
            volume: 0,
          },
          {
            hour: 13,
            volume: 0,
          },
          {
            hour: 14,
            volume: 0,
          },
          {
            hour: 15,
            volume: 0,
          },
          {
            hour: 16,
            volume: 0,
          },
          {
            hour: 17,
            volume: 0,
          },
          {
            hour: 18,
            volume: 0,
          },
          {
            hour: 19,
            volume: 0,
          },
          {
            hour: 20,
            volume: 0,
          },
          {
            hour: 21,
            volume: 0,
          },
          {
            hour: 22,
            volume: 0,
          },
          {
            hour: 23,
            volume: 0,
          },
        ],
      },
      {
        data: "2024-02-17",
        times: [
          {
            hour: 0,
            volume: 0,
          },
          {
            hour: 1,
            volume: 0,
          },
          {
            hour: 2,
            volume: 0,
          },
          {
            hour: 3,
            volume: 0,
          },
          {
            hour: 4,
            volume: 0,
          },
          {
            hour: 5,
            volume: 0,
          },
          {
            hour: 6,
            volume: 0,
          },
          {
            hour: 7,
            volume: 0,
          },
          {
            hour: 8,
            volume: 0,
          },
          {
            hour: 9,
            volume: 0,
          },
          {
            hour: 10,
            volume: 0,
          },
          {
            hour: 11,
            volume: 0,
          },
          {
            hour: 12,
            volume: 0,
          },
          {
            hour: 13,
            volume: 0,
          },
          {
            hour: 14,
            volume: 0,
          },
          {
            hour: 15,
            volume: 0,
          },
          {
            hour: 16,
            volume: 0,
          },
          {
            hour: 17,
            volume: 0,
          },
          {
            hour: 18,
            volume: 0,
          },
          {
            hour: 19,
            volume: 0,
          },
          {
            hour: 20,
            volume: 0,
          },
          {
            hour: 21,
            volume: 0,
          },
          {
            hour: 22,
            volume: 0,
          },
          {
            hour: 23,
            volume: 0,
          },
        ],
      },
      {
        data: "2024-02-18",
        times: [
          {
            hour: 0,
            volume: 0,
          },
          {
            hour: 1,
            volume: 0,
          },
          {
            hour: 2,
            volume: 0,
          },
          {
            hour: 3,
            volume: 0,
          },
          {
            hour: 4,
            volume: 0,
          },
          {
            hour: 5,
            volume: 0,
          },
          {
            hour: 6,
            volume: 0,
          },
          {
            hour: 7,
            volume: 0,
          },
          {
            hour: 8,
            volume: 0,
          },
          {
            hour: 9,
            volume: 0,
          },
          {
            hour: 10,
            volume: 0,
          },
          {
            hour: 11,
            volume: 0,
          },
          {
            hour: 12,
            volume: 0,
          },
          {
            hour: 13,
            volume: 0,
          },
          {
            hour: 14,
            volume: 0,
          },
          {
            hour: 15,
            volume: 0,
          },
          {
            hour: 16,
            volume: 0,
          },
          {
            hour: 17,
            volume: 0,
          },
          {
            hour: 18,
            volume: 0,
          },
          {
            hour: 19,
            volume: 0,
          },
          {
            hour: 20,
            volume: 0,
          },
          {
            hour: 21,
            volume: 0,
          },
          {
            hour: 22,
            volume: 0,
          },
          {
            hour: 23,
            volume: 0,
          },
        ],
      },
      {
        data: "2024-02-19",
        times: [
          {
            hour: 0,
            volume: 0,
          },
          {
            hour: 1,
            volume: 0,
          },
          {
            hour: 2,
            volume: 0,
          },
          {
            hour: 3,
            volume: 0,
          },
          {
            hour: 4,
            volume: 0,
          },
          {
            hour: 5,
            volume: 0,
          },
          {
            hour: 6,
            volume: 0,
          },
          {
            hour: 7,
            volume: 0,
          },
          {
            hour: 8,
            volume: 0,
          },
          {
            hour: 9,
            volume: 0,
          },
          {
            hour: 10,
            volume: 0,
          },
          {
            hour: 11,
            volume: 3,
          },
          {
            hour: 12,
            volume: 0,
          },
          {
            hour: 13,
            volume: 0,
          },
          {
            hour: 14,
            volume: 0,
          },
          {
            hour: 15,
            volume: 0,
          },
          {
            hour: 16,
            volume: 0,
          },
          {
            hour: 17,
            volume: 0,
          },
          {
            hour: 18,
            volume: 0,
          },
          {
            hour: 19,
            volume: 0,
          },
          {
            hour: 20,
            volume: 0,
          },
          {
            hour: 21,
            volume: 0,
          },
          {
            hour: 22,
            volume: 0,
          },
          {
            hour: 23,
            volume: 0,
          },
        ],
      },
      {
        data: "2024-02-20",
        times: [
          {
            hour: 0,
            volume: 0,
          },
          {
            hour: 1,
            volume: 0,
          },
          {
            hour: 2,
            volume: 0,
          },
          {
            hour: 3,
            volume: 0,
          },
          {
            hour: 4,
            volume: 0,
          },
          {
            hour: 5,
            volume: 0,
          },
          {
            hour: 6,
            volume: 0,
          },
          {
            hour: 7,
            volume: 0,
          },
          {
            hour: 8,
            volume: 0,
          },
          {
            hour: 9,
            volume: 0,
          },
          {
            hour: 10,
            volume: 0,
          },
          {
            hour: 11,
            volume: 0,
          },
          {
            hour: 12,
            volume: 0,
          },
          {
            hour: 13,
            volume: 0,
          },
          {
            hour: 14,
            volume: 0,
          },
          {
            hour: 15,
            volume: 0,
          },
          {
            hour: 16,
            volume: 0,
          },
          {
            hour: 17,
            volume: 0,
          },
          {
            hour: 18,
            volume: 0,
          },
          {
            hour: 19,
            volume: 0,
          },
          {
            hour: 20,
            volume: 0,
          },
          {
            hour: 21,
            volume: 0,
          },
          {
            hour: 22,
            volume: 0,
          },
          {
            hour: 23,
            volume: 0,
          },
        ],
      },
      {
        data: "2024-02-21",
        times: [
          {
            hour: 0,
            volume: 0,
          },
          {
            hour: 1,
            volume: 0,
          },
          {
            hour: 2,
            volume: 0,
          },
          {
            hour: 3,
            volume: 0,
          },
          {
            hour: 4,
            volume: 0,
          },
          {
            hour: 5,
            volume: 0,
          },
          {
            hour: 6,
            volume: 0,
          },
          {
            hour: 7,
            volume: 0,
          },
          {
            hour: 8,
            volume: 0,
          },
          {
            hour: 9,
            volume: 0,
          },
          {
            hour: 10,
            volume: 0,
          },
          {
            hour: 11,
            volume: 0,
          },
          {
            hour: 12,
            volume: 0,
          },
          {
            hour: 13,
            volume: 0,
          },
          {
            hour: 14,
            volume: 0,
          },
          {
            hour: 15,
            volume: 0,
          },
          {
            hour: 16,
            volume: 0,
          },
          {
            hour: 17,
            volume: 0,
          },
          {
            hour: 18,
            volume: 0,
          },
          {
            hour: 19,
            volume: 0,
          },
          {
            hour: 20,
            volume: 0,
          },
          {
            hour: 21,
            volume: 0,
          },
          {
            hour: 22,
            volume: 0,
          },
          {
            hour: 23,
            volume: 0,
          },
        ],
      },
      {
        data: "2024-02-22",
        times: [
          {
            hour: 0,
            volume: 0,
          },
          {
            hour: 1,
            volume: 0,
          },
          {
            hour: 2,
            volume: 0,
          },
          {
            hour: 3,
            volume: 0,
          },
          {
            hour: 4,
            volume: 0,
          },
          {
            hour: 5,
            volume: 0,
          },
          {
            hour: 6,
            volume: 0,
          },
          {
            hour: 7,
            volume: 0,
          },
          {
            hour: 8,
            volume: 0,
          },
          {
            hour: 9,
            volume: 0,
          },
          {
            hour: 10,
            volume: 0,
          },
          {
            hour: 11,
            volume: 0,
          },
          {
            hour: 12,
            volume: 0,
          },
          {
            hour: 13,
            volume: 0,
          },
          {
            hour: 14,
            volume: 0,
          },
          {
            hour: 15,
            volume: 0,
          },
          {
            hour: 16,
            volume: 0,
          },
          {
            hour: 17,
            volume: 0,
          },
          {
            hour: 18,
            volume: 0,
          },
          {
            hour: 19,
            volume: 0,
          },
          {
            hour: 20,
            volume: 0,
          },
          {
            hour: 21,
            volume: 0,
          },
          {
            hour: 22,
            volume: 0,
          },
          {
            hour: 23,
            volume: 0,
          },
        ],
      },
      {
        data: "2024-02-23",
        times: [
          {
            hour: 0,
            volume: 0,
          },
          {
            hour: 1,
            volume: 0,
          },
          {
            hour: 2,
            volume: 0,
          },
          {
            hour: 3,
            volume: 0,
          },
          {
            hour: 4,
            volume: 0,
          },
          {
            hour: 5,
            volume: 0,
          },
          {
            hour: 6,
            volume: 0,
          },
          {
            hour: 7,
            volume: 0,
          },
          {
            hour: 8,
            volume: 0,
          },
          {
            hour: 9,
            volume: 0,
          },
          {
            hour: 10,
            volume: 0,
          },
          {
            hour: 11,
            volume: 0,
          },
          {
            hour: 12,
            volume: 0,
          },
          {
            hour: 13,
            volume: 0,
          },
          {
            hour: 14,
            volume: 0,
          },
          {
            hour: 15,
            volume: 0,
          },
          {
            hour: 16,
            volume: 0,
          },
          {
            hour: 17,
            volume: 0,
          },
          {
            hour: 18,
            volume: 0,
          },
          {
            hour: 19,
            volume: 0,
          },
          {
            hour: 20,
            volume: 0,
          },
          {
            hour: 21,
            volume: 0,
          },
          {
            hour: 22,
            volume: 0,
          },
          {
            hour: 23,
            volume: 0,
          },
        ],
      },
      {
        data: "2024-02-24",
        times: [
          {
            hour: 0,
            volume: 0,
          },
          {
            hour: 1,
            volume: 0,
          },
          {
            hour: 2,
            volume: 0,
          },
          {
            hour: 3,
            volume: 0,
          },
          {
            hour: 4,
            volume: 0,
          },
          {
            hour: 5,
            volume: 0,
          },
          {
            hour: 6,
            volume: 0,
          },
          {
            hour: 7,
            volume: 0,
          },
          {
            hour: 8,
            volume: 0,
          },
          {
            hour: 9,
            volume: 0,
          },
          {
            hour: 10,
            volume: 0,
          },
          {
            hour: 11,
            volume: 0,
          },
          {
            hour: 12,
            volume: 0,
          },
          {
            hour: 13,
            volume: 0,
          },
          {
            hour: 14,
            volume: 0,
          },
          {
            hour: 15,
            volume: 0,
          },
          {
            hour: 16,
            volume: 0,
          },
          {
            hour: 17,
            volume: 0,
          },
          {
            hour: 18,
            volume: 0,
          },
          {
            hour: 19,
            volume: 0,
          },
          {
            hour: 20,
            volume: 0,
          },
          {
            hour: 21,
            volume: 0,
          },
          {
            hour: 22,
            volume: 0,
          },
          {
            hour: 23,
            volume: 0,
          },
        ],
      },
      {
        data: "2024-02-25",
        times: [
          {
            hour: 0,
            volume: 0,
          },
          {
            hour: 1,
            volume: 0,
          },
          {
            hour: 2,
            volume: 0,
          },
          {
            hour: 3,
            volume: 0,
          },
          {
            hour: 4,
            volume: 0,
          },
          {
            hour: 5,
            volume: 0,
          },
          {
            hour: 6,
            volume: 0,
          },
          {
            hour: 7,
            volume: 0,
          },
          {
            hour: 8,
            volume: 0,
          },
          {
            hour: 9,
            volume: 0,
          },
          {
            hour: 10,
            volume: 0,
          },
          {
            hour: 11,
            volume: 0,
          },
          {
            hour: 12,
            volume: 0,
          },
          {
            hour: 13,
            volume: 0,
          },
          {
            hour: 14,
            volume: 0,
          },
          {
            hour: 15,
            volume: 0,
          },
          {
            hour: 16,
            volume: 0,
          },
          {
            hour: 17,
            volume: 0,
          },
          {
            hour: 18,
            volume: 0,
          },
          {
            hour: 19,
            volume: 0,
          },
          {
            hour: 20,
            volume: 0,
          },
          {
            hour: 21,
            volume: 0,
          },
          {
            hour: 22,
            volume: 0,
          },
          {
            hour: 23,
            volume: 0,
          },
        ],
      },
      {
        data: "2024-02-26",
        times: [
          {
            hour: 0,
            volume: 0,
          },
          {
            hour: 1,
            volume: 0,
          },
          {
            hour: 2,
            volume: 0,
          },
          {
            hour: 3,
            volume: 0,
          },
          {
            hour: 4,
            volume: 0,
          },
          {
            hour: 5,
            volume: 0,
          },
          {
            hour: 6,
            volume: 0,
          },
          {
            hour: 7,
            volume: 0,
          },
          {
            hour: 8,
            volume: 0,
          },
          {
            hour: 9,
            volume: 0,
          },
          {
            hour: 10,
            volume: 1,
          },
          {
            hour: 11,
            volume: 0,
          },
          {
            hour: 12,
            volume: 0,
          },
          {
            hour: 13,
            volume: 0,
          },
          {
            hour: 14,
            volume: 0,
          },
          {
            hour: 15,
            volume: 0,
          },
          {
            hour: 16,
            volume: 0,
          },
          {
            hour: 17,
            volume: 0,
          },
          {
            hour: 18,
            volume: 0,
          },
          {
            hour: 19,
            volume: 0,
          },
          {
            hour: 20,
            volume: 0,
          },
          {
            hour: 21,
            volume: 0,
          },
          {
            hour: 22,
            volume: 0,
          },
          {
            hour: 23,
            volume: 0,
          },
        ],
      },
      {
        data: "2024-02-27",
        times: [
          {
            hour: 0,
            volume: 0,
          },
          {
            hour: 1,
            volume: 0,
          },
          {
            hour: 2,
            volume: 0,
          },
          {
            hour: 3,
            volume: 0,
          },
          {
            hour: 4,
            volume: 0,
          },
          {
            hour: 5,
            volume: 0,
          },
          {
            hour: 6,
            volume: 0,
          },
          {
            hour: 7,
            volume: 0,
          },
          {
            hour: 8,
            volume: 0,
          },
          {
            hour: 9,
            volume: 0,
          },
          {
            hour: 10,
            volume: 0,
          },
          {
            hour: 11,
            volume: 0,
          },
          {
            hour: 12,
            volume: 0,
          },
          {
            hour: 13,
            volume: 0,
          },
          {
            hour: 14,
            volume: 0,
          },
          {
            hour: 15,
            volume: 0,
          },
          {
            hour: 16,
            volume: 0,
          },
          {
            hour: 17,
            volume: 0,
          },
          {
            hour: 18,
            volume: 0,
          },
          {
            hour: 19,
            volume: 0,
          },
          {
            hour: 20,
            volume: 0,
          },
          {
            hour: 21,
            volume: 0,
          },
          {
            hour: 22,
            volume: 0,
          },
          {
            hour: 23,
            volume: 0,
          },
        ],
      },
      {
        data: "2024-02-28",
        times: [
          {
            hour: 0,
            volume: 0,
          },
          {
            hour: 1,
            volume: 0,
          },
          {
            hour: 2,
            volume: 0,
          },
          {
            hour: 3,
            volume: 0,
          },
          {
            hour: 4,
            volume: 0,
          },
          {
            hour: 5,
            volume: 0,
          },
          {
            hour: 6,
            volume: 0,
          },
          {
            hour: 7,
            volume: 0,
          },
          {
            hour: 8,
            volume: 0,
          },
          {
            hour: 9,
            volume: 0,
          },
          {
            hour: 10,
            volume: 0,
          },
          {
            hour: 11,
            volume: 0,
          },
          {
            hour: 12,
            volume: 0,
          },
          {
            hour: 13,
            volume: 0,
          },
          {
            hour: 14,
            volume: 0,
          },
          {
            hour: 15,
            volume: 0,
          },
          {
            hour: 16,
            volume: 0,
          },
          {
            hour: 17,
            volume: 0,
          },
          {
            hour: 18,
            volume: 0,
          },
          {
            hour: 19,
            volume: 0,
          },
          {
            hour: 20,
            volume: 0,
          },
          {
            hour: 21,
            volume: 0,
          },
          {
            hour: 22,
            volume: 0,
          },
          {
            hour: 23,
            volume: 0,
          },
        ],
      },
      {
        data: "2024-02-29",
        times: [
          {
            hour: 0,
            volume: 0,
          },
          {
            hour: 1,
            volume: 0,
          },
          {
            hour: 2,
            volume: 0,
          },
          {
            hour: 3,
            volume: 0,
          },
          {
            hour: 4,
            volume: 0,
          },
          {
            hour: 5,
            volume: 0,
          },
          {
            hour: 6,
            volume: 0,
          },
          {
            hour: 7,
            volume: 0,
          },
          {
            hour: 8,
            volume: 0,
          },
          {
            hour: 9,
            volume: 0,
          },
          {
            hour: 10,
            volume: 0,
          },
          {
            hour: 11,
            volume: 0,
          },
          {
            hour: 12,
            volume: 0,
          },
          {
            hour: 13,
            volume: 0,
          },
          {
            hour: 14,
            volume: 0,
          },
          {
            hour: 15,
            volume: 0,
          },
          {
            hour: 16,
            volume: 0,
          },
          {
            hour: 17,
            volume: 0,
          },
          {
            hour: 18,
            volume: 0,
          },
          {
            hour: 19,
            volume: 0,
          },
          {
            hour: 20,
            volume: 0,
          },
          {
            hour: 21,
            volume: 0,
          },
          {
            hour: 22,
            volume: 0,
          },
          {
            hour: 23,
            volume: 0,
          },
        ],
      },
      {
        data: "2024-02-30",
        times: [
          {
            hour: 0,
            volume: 0,
          },
          {
            hour: 1,
            volume: 0,
          },
          {
            hour: 2,
            volume: 0,
          },
          {
            hour: 3,
            volume: 0,
          },
          {
            hour: 4,
            volume: 0,
          },
          {
            hour: 5,
            volume: 0,
          },
          {
            hour: 6,
            volume: 0,
          },
          {
            hour: 7,
            volume: 0,
          },
          {
            hour: 8,
            volume: 0,
          },
          {
            hour: 9,
            volume: 0,
          },
          {
            hour: 10,
            volume: 0,
          },
          {
            hour: 11,
            volume: 0,
          },
          {
            hour: 12,
            volume: 0,
          },
          {
            hour: 13,
            volume: 0,
          },
          {
            hour: 14,
            volume: 0,
          },
          {
            hour: 15,
            volume: 0,
          },
          {
            hour: 16,
            volume: 0,
          },
          {
            hour: 17,
            volume: 0,
          },
          {
            hour: 18,
            volume: 0,
          },
          {
            hour: 19,
            volume: 0,
          },
          {
            hour: 20,
            volume: 0,
          },
          {
            hour: 21,
            volume: 0,
          },
          {
            hour: 22,
            volume: 0,
          },
          {
            hour: 23,
            volume: 0,
          },
        ],
      },
      {
        data: "2024-02-31",
        times: [
          {
            hour: 0,
            volume: 0,
          },
          {
            hour: 1,
            volume: 0,
          },
          {
            hour: 2,
            volume: 0,
          },
          {
            hour: 3,
            volume: 0,
          },
          {
            hour: 4,
            volume: 0,
          },
          {
            hour: 5,
            volume: 0,
          },
          {
            hour: 6,
            volume: 0,
          },
          {
            hour: 7,
            volume: 0,
          },
          {
            hour: 8,
            volume: 0,
          },
          {
            hour: 9,
            volume: 0,
          },
          {
            hour: 10,
            volume: 0,
          },
          {
            hour: 11,
            volume: 0,
          },
          {
            hour: 12,
            volume: 0,
          },
          {
            hour: 13,
            volume: 0,
          },
          {
            hour: 14,
            volume: 0,
          },
          {
            hour: 15,
            volume: 0,
          },
          {
            hour: 16,
            volume: 0,
          },
          {
            hour: 17,
            volume: 0,
          },
          {
            hour: 18,
            volume: 0,
          },
          {
            hour: 19,
            volume: 0,
          },
          {
            hour: 20,
            volume: 0,
          },
          {
            hour: 21,
            volume: 0,
          },
          {
            hour: 22,
            volume: 0,
          },
          {
            hour: 23,
            volume: 0,
          },
        ],
      },
    ]);

  const [volumeDiarioPorHora, setVolumeDiarioPorHora] = useState<any>([]);

  const [suspectsByMonth, setSuspectsByMonth] = useState<any>([
    {
      day: "2024-02-01",
      suspects: 0,
    },
    {
      day: "2024-02-02",
      suspects: 0,
    },
    {
      day: "2024-02-03",
      suspects: 0,
    },
    {
      day: "2024-02-04",
      suspects: 0,
    },
    {
      day: "2024-02-05",
      suspects: 0,
    },
    {
      day: "2024-02-06",
      suspects: 0,
    },
    {
      day: "2024-02-07",
      suspects: 0,
    },
    {
      day: "2024-02-08",
      suspects: 0,
    },
    {
      day: "2024-02-09",
      suspects: 0,
    },
    {
      day: "2024-02-10",
      suspects: 0,
    },
    {
      day: "2024-02-11",
      suspects: 0,
    },
    {
      day: "2024-02-12",
      suspects: 0,
    },
    {
      day: "2024-02-13",
      suspects: 0,
    },
    {
      day: "2024-02-14",
      suspects: 0,
    },
    {
      day: "2024-02-15",
      suspects: 0,
    },
    {
      day: "2024-02-16",
      suspects: 0,
    },
    {
      day: "2024-02-17",
      suspects: 0,
    },
    {
      day: "2024-02-18",
      suspects: 0,
    },
    {
      day: "2024-02-19",
      suspects: 0,
    },
    {
      day: "2024-02-20",
      suspects: 0,
    },
    {
      day: "2024-02-21",
      suspects: 0,
    },
    {
      day: "2024-02-22",
      suspects: 0,
    },
    {
      day: "2024-02-23",
      suspects: 0,
    },
    {
      day: "2024-02-24",
      suspects: 0,
    },
    {
      day: "2024-02-25",
      suspects: 0,
    },
    {
      day: "2024-02-26",
      suspects: 0,
    },
    {
      day: "2024-02-27",
      suspects: 0,
    },
    {
      day: "2024-02-28",
      suspects: 0,
    },
    {
      day: "2024-02-29",
      suspects: 0,
    },
    {
      day: "2024-02-30",
      suspects: 0,
    },
    {
      day: "2024-02-31",
      suspects: 0,
    },
  ]);

  const [
    volumePropostasComMuitosLiveness,
    setVolumePropostasComMuitosLiveness,
  ] = useState<any>([
    {
      date: "2024-02-01",
      total: 1,
    },
    {
      date: "2024-02-02",
      total: 2,
    },
    {
      date: "2024-02-03",
      total: 1,
    },
    {
      date: "2024-02-04",
      total: 1,
    },
    {
      date: "2024-02-05",
      total: 4,
    },
    {
      date: "2024-02-06",
      total: 3,
    },
    {
      date: "2024-02-07",
      total: 0,
    },
    {
      date: "2024-02-08",
      total: 0,
    },
    {
      date: "2024-02-09",
      total: 0,
    },
    {
      date: "2024-02-10",
      total: 0,
    },
    {
      date: "2024-02-11",
      total: 8,
    },
    {
      date: "2024-02-12",
      total: 7,
    },
    {
      date: "2024-02-13",
      total: 6,
    },
    {
      date: "2024-02-14",
      total: 5,
    },
    {
      date: "2024-02-15",
      total: 4,
    },
    {
      date: "2024-02-16",
      total: 1,
    },
    {
      date: "2024-02-17",
      total: 2,
    },
    {
      date: "2024-02-18",
      total: 5,
    },
    {
      date: "2024-02-19",
      total: 12,
    },
    {
      date: "2024-02-20",
      total: 9,
    },
    {
      date: "2024-02-21",
      total: 6,
    },
    {
      date: "2024-02-22",
      total: 1,
    },
    {
      date: "2024-02-23",
      total: 12,
    },
    {
      date: "2024-02-24",
      total: 123,
    },
    {
      date: "2024-02-25",
      total: 54,
    },
    {
      date: "2024-02-26",
      total: 2,
    },
    {
      date: "2024-02-27",
      total: 12,
    },
    {
      date: "2024-02-28",
      total: 20,
    },
    {
      date: "2024-02-29",
      total: 15,
    },
  ]);

  const [volumeAndamentoConcluido, setVolumeAndamentoConcluido] = useState<any>(
    {
      total: [],
      done: [],
      not_done: [],
    }
  );

  const processSlaJorney = () => {
    const dadosTransformados: any = {
      sla: [],
    };
    const dados: any = [];

    api.get("/dashboard/v3/sla-by-journey-by-days").then((response) => {
      response.data?.forEach((dados: any) => {
        dadosTransformados.sla.push(dados.sla);
      });
      dadosTransformados.sla.forEach((dado: any) => {
        if(dado !== null && dado !== undefined && dado !== 0)
          dados.push(dado);
      })
      const somaDados = dados.reduce((a: any, b: any) => a + b, 0);
      const media = somaDados / dados.length;
      setMediaSlas(media);

      setSlaJornada(dadosTransformados);
    });
  };

  const processSlaGeralJorney = () => {
    const dadosTransformados: any = {
      sla: [],
    };
    const dados: any = [];

    api.get("/dashboard/v3/get_sla_for_current_month").then((response) => {
      response.data?.forEach((dados: any) => {
        dadosTransformados.sla.push(dados.average_sla_hours);
      });
      dadosTransformados.sla.forEach((dado: any) => {
        if (dado !== null && dado !== undefined && dado !== 0)
          dados.push(dado);
      })
      const somaDados = dados.reduce((a: any, b: any) => a + b, 0);
      const media = somaDados / dados.length;
      setMediaSlasGeral(media);

      setGeralSlaJornada(dadosTransformados);
    });
  };

  const processSessionVolume = () => {
    const dadosTransformados: any = {
      total: [],
    };

    volumeDiarioPorSessao?.forEach((dados: any) => {
      dadosTransformados.total.push(dados.total);
    });

    setVolumeDiarioPorSessao(dadosTransformados);
  };

  const processVolumeDiarioPorHoraSessao = () => {
    const dadosTransformados: any = {
      total: [],
    };
    const volumeDiario: any = {
      total: [],
    };

    volumeDiarioPorHoraSessao?.forEach((dados: any) => {
      if (compararDatas(dados.data)) {
        volumeDiario.total = dados.times.map((time: any) => time.volume);
        setVolumeDiarioPorHora(volumeDiario);
      }
      dadosTransformados.total.push(
        dados.times.reduce(
          (acumulador: any, time: { volume: any }) =>
            acumulador + (time.volume || 0),
          0
        )
      );
    });

    setVolumeDiarioPorHoraSessao(dadosTransformados);
  };

  const processSuspectsByMonth = () => {
    const dadosTransformados: any = {
      total: [],
    };

    suspectsByMonth?.forEach((dados: any) => {
      dadosTransformados.total.push(dados.suspects);
    });

    setSuspectsByMonth(dadosTransformados);
  };

  const processVolumePropostasComMuitosLiveness = () => {
    const dadosTransformados: any = {
      total: [],
    };

    volumePropostasComMuitosLiveness?.forEach((dados: any) => {
      dadosTransformados.total.push(dados.total);
    });

    setVolumePropostasComMuitosLiveness(dadosTransformados);
  };

  const processVolumeAndamentoConcluidas = () => {
    const dadosTransformados: any = {
      total: [],
      done: [],
      not_done: [],
    };
    api.get("/dashboard/v3/stared-finished-not-finished").then((response) => {
      response.data?.forEach((dados: any) => {
        dadosTransformados.total.push(dados.total);
        dadosTransformados.done.push(dados.done);
        dadosTransformados.not_done.push(dados.not_done);
      });

      setVolumeAndamentoConcluido(dadosTransformados);
    });
  };

  useEffect(() => {
    processSlaGeralJorney();
    processData();
    processDataReceivedStartedAndNotStarted();
    processSlaJorney();
    processSessionVolume();
    processVolumeDiarioPorHoraSessao();
    processSuspectsByMonth();
    processVolumePropostasComMuitosLiveness();
    processVolumeAndamentoConcluidas();
  }, []);

  const getMediaGeral = () => {
    let minutos = Math.floor(mediaSlas);
    let segundos = Math.round((mediaSlas - minutos) * 60);
    
    // Monta a string no formato desejado
    return `${minutos} minutos e ${segundos} segundos`;
  }

  const getMediaGeralGeral = () => {
   return formatHourToString(mediaSlasGeral)
  }

  return (
    <PageStructureWrapper title="Gráfico de Desempenho">
      <ConsultaResultWrapper>
        <Section>
          <h3>Geral</h3>
          <GraphicsWrapper>
            <MonthLine>
              <AnalysysChart data={data} />
            </MonthLine>
          </GraphicsWrapper>
        </Section>
        <Section>
          <h3>SLA de Jornada por Dia (Média Geral: {getMediaGeral()})</h3>
          <GraphicsWrapper>
            <MonthLine>
              <SlaJourney data={slaJornada} />
            </MonthLine>
          </GraphicsWrapper>
        </Section>
        <Section>
          <h3>SLA Conclusão de Jornada (Média Geral: {getMediaGeralGeral()})</h3>
          <GraphicsWrapper>
            <MonthLine>
              <SlaGeral data={slaGeralJornada} />
            </MonthLine>
          </GraphicsWrapper>
        </Section>
      </ConsultaResultWrapper>
    </PageStructureWrapper>
  );
};
