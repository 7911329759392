import Chart, { ChartConfiguration } from "chart.js/auto";
import React, { useEffect, useRef } from "react";

interface LineChartProps {
  data: {
    first_time: number[];
    second_time: number[];
    third_time: number[];
    fourth_time: number[];
    other_time: number[];
  };
}

const AnalysysChart: React.FC<LineChartProps> = ({ data }) => {
  const chartRef = useRef<HTMLCanvasElement>(null);
  const chartInstance = useRef<Chart<"line", unknown> | null>(null);

  useEffect(() => {
    if (chartRef && chartRef.current) {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }

      const ctx = chartRef.current.getContext("2d");
      if (ctx) {
        chartInstance.current = new Chart(ctx, {
          type: "line",
          data: {
            labels: Array.from({ length: data?.first_time?.length }, (_, i) =>
              (i + 1).toString()
            ), // Dias do mês
            datasets: [
              {
                label: "08:00 - 10:59",
                data: data.first_time,
                borderColor: "#ac0929",
                backgroundColor: "#cc092f",
                borderWidth: 2,
                fill: false,
              },
              {
                label: "11:00 - 13:59",
                data: data.second_time,
                borderColor: "#262c69",
                backgroundColor: "#333b8f",
                borderWidth: 2,
                fill: false,
              },
              {
                label: "14:00 - 16:59",
                data: data.third_time,
                borderColor: "#9e9e9e",
                backgroundColor: "#bebebe",
                borderWidth: 2,
                fill: false,
              },
              {
                label: "17:00 - 19:59",
                data: data.fourth_time,
                borderColor: "#5c0516",
                backgroundColor: "#7a051c",
                borderWidth: 2,
                fill: false,
              },
              {
                label: "Outros",
                data: data.other_time,
                borderColor: "#cc5200",
                backgroundColor: "#cc5200",
                borderWidth: 2,
                fill: false,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              y: {
                beginAtZero: true,
              },
            },
            plugins: {
              tooltip: {
                callbacks: {
                  label: (context) => {
                    const label = context.dataset.label || "";
                    const value = context.parsed.y || 0;
                    return ` ${value}`;
                  },
                },
              },
            },
          },
        } as ChartConfiguration<"line", number[], string>);
      }
    }

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [data]);

  return <canvas ref={chartRef} />;
};

export default AnalysysChart;
