import { debounce } from 'lodash';
import { useEffect, useMemo, useState } from "react";
import { FiDownload } from "react-icons/fi";
import { toast } from "react-toastify";
import Button from "../../../components/Button";
import CustomTable from "../../../components/CustomTable";
import DateRangeInput from "../../../components/DatePicker";
import DropDown from '../../../components/Dropdown';
import Input from "../../../components/Input";
import { PageStructureWrapper } from "../../../components/PageStructureWrapper";
import api from "../../../service/api";
import { formatDate } from "../../../utils/Date";
import { toastStyle } from "../../../utils/toastStyles";
import { ConsultaPropostasWrapper, ConsultaResultWrapper, SearchFields, SearchWrapper } from "../../ConsutaDePropostas/style";

export const RelatorioLivenessPage = () => {
  const [openModal, setOpenModal] = useState(false);
  const [typeFilter, setTypeFilter] = useState<string>('maior_que');

  const columnsLiveness = useMemo(
    () => [
      {
        Header: 'Sessão',
        accessor: 'session_id',
      },
      {
        Header: 'Data',
        accessor: 'datetime',
      },
      {
        Header: 'Tentativa',
        accessor: 'attempt_order',
      },
      {
        Header: 'Proposta',
        accessor: 'proposal_number',
      },
      {
        Header: 'Serviço',
        accessor: 'service',
      },
      {
        Header: 'Mensagem',
        accessor: 'human_message',
      },
      {
        Header: 'Resposta',
        accessor: 'response',
      }
    ],
    []
  );


  const columnMinWidths = { firstName: '20px' };

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [dataLiveness, setDataLiveness] = useState([]);
  const [numeroProposta, setNumeroProposta] = useState<string>('');
  const [qtdTentativas, setQtdTentativas] = useState<string>('10');
  const [debouncedQtdTentativas, setDebouncedQtdTentativas] = useState('');
  const [numeroContrato, setNumeroContrato] = useState<string>('');
  const [cpfCnpj, setCpfCnpj] = useState<string>('');
  const [filteredData, setFilteredData] = useState<any[]>([]);

  useEffect(() => {
    if ((startDate && endDate && dataLiveness) || numeroProposta) {
      setFilteredData(dataLiveness);
    }
  }, [startDate, endDate, dataLiveness, numeroProposta]);

  // const applyFilters = () => {
  //   let filteredResult = dataLiveness;

  //   if (numeroProposta && numeroProposta.length === 8) {
  //     filteredResult = filteredResult.filter((item: any) =>
  //       item.proposal_number.includes(numeroProposta)
  //     );
  //   }

  //   // if (numeroContrato && numeroContrato.length === 10) {
  //   //   filteredResult = filteredResult.filter((item: any) =>
  //   //     item.contract.includes(numeroContrato)
  //   //   );
  //   // }

  //   // if (cpfCnpj && (cpfCnpj.length === 11 || cpfCnpj.length === 14)) {
  //   //   filteredResult = filteredResult.filter((item: any) =>
  //   //     item.document.includes(cpfCnpj)
  //   //   );
  //   // }

  //   setFilteredData(filteredResult);
  // };

  useEffect(() => {
    const updateDebouncedQtdTentativas = debounce(() => {
      setDebouncedQtdTentativas(qtdTentativas);
    }, 1000);

    updateDebouncedQtdTentativas();

    return () => {
      updateDebouncedQtdTentativas.cancel();
    };
  }, [qtdTentativas]);

  const clearFilters = () => {
    setNumeroProposta('');
    setNumeroContrato('');
    setCpfCnpj('');
    setFilteredData(dataLiveness);
  }

  // useEffect(() => {
  //   if (numeroContrato || numeroProposta || cpfCnpj) {
  //     applyFilters()
  //   } else {
  //     clearFilters()
  //   }

  // }, [numeroContrato, numeroProposta, cpfCnpj])

  useEffect(() => {
    if (startDate && endDate && Number(debouncedQtdTentativas) >= 0 || numeroProposta) {
      toast.loading("Buscando dados...", { style: toastStyle })
      api.get(`/dashboard/v3/${startDate ? formatDate(startDate) : 'None'}/${endDate ? formatDate(endDate) : 'None'}/${qtdTentativas}/${numeroProposta == '' || numeroProposta == ' ' || !numeroProposta ? 'None' : numeroProposta}/${typeFilter}/liveness`).then(response => {
        console.log(response.data)
        setDataLiveness(response.data)
        toast.dismiss()
      }).catch((err: any) => {
        toast.error("Erro ao buscar dados " + err)
      })
    }
  }, [startDate, endDate, debouncedQtdTentativas, numeroProposta, typeFilter])

  const generateCSV = () => {
    const formatNumber = (value: any) => {
      if (!value)
        return value;

      return value.toLocaleString('pt-BR');
    };

    const csvContent =
      columnsLiveness.map((col: any) => col.Header).join(';') +
      '\n' +
      dataLiveness
        .map((row: any) =>
          columnsLiveness
            .map((col) => {
              const value = row[col.accessor];
              const formattedValue = formatNumber(value);
              const stringValue = typeof formattedValue === 'string' ? formattedValue : String(formattedValue);
              const encodedValue = stringValue.replace(/"/g, '""'); // Escapar as aspas
              return `"${encodedValue}"`;
            })
            .join(';')
        )
        .join('\n');

    const csvData = new Blob(['\ufeff', csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(csvData);

    link.setAttribute('href', url);
    link.setAttribute('download', `${startDate?.toLocaleDateString()}_${endDate?.toLocaleDateString()}.csv`);
    link.style.visibility = 'hidden';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

  };




  return <PageStructureWrapper title="Propostas com mais de N Liveness">
    <ConsultaPropostasWrapper>
      <SearchWrapper>
        <SearchFields>
          <DateRangeInput titleHeader="Datas:"
            startDate={startDate} setStartDate={setStartDate}
            endDate={endDate} setEndDate={setEndDate}
          />
          <DropDown titleHeader="Tipo" options={[
            { label: 'Maior que', value: 'maior_que' },
            { label: 'Maior igual que', value: 'maior_igual_que' },
            { label: 'Igual', value: 'igual' },
            { label: 'Menor que', value: 'menor_que' },
            { label: 'Menor igual que', value: 'menor_igual_que' },
          ]} onChange={(e) => { console.log(e); setTypeFilter(e) }} />
          <Input titleHeader="Quantidade de tentativas:" value={qtdTentativas} placeholder="Ex: 123456" onChange={(e) => setQtdTentativas(e.target.value)} type="number" />
          <Input titleHeader="Número da Proposta:" placeholder="Ex: 123456" onChange={(e) => setNumeroProposta(e.target.value)} />


          {/* <Input titleHeader="Número do Contrato:" placeholder="Ex: 123456" onChange={(e) => setNumeroContrato(e.target.value)} disabled={!(startDate || endDate)} />
          <Input titleHeader="CPF/CNPJ:" placeholder="Ex: 1234567890" onChange={(e) => setCpfCnpj(e.target.value)} disabled={!(startDate || endDate)} /> */}
        </SearchFields>
        <Button onClick={generateCSV} leftIcon={<FiDownload />} label="Download" theme="primary" height="40px" />
      </SearchWrapper>
      <ConsultaResultWrapper onClick={() => setOpenModal(!openModal)}>

        <CustomTable maxHeight="calc(100vh - 300px)" minWidth="100%"
          columns={columnsLiveness}
          data={filteredData}
          columnMinWidths={columnMinWidths} />
      </ConsultaResultWrapper>
    </ConsultaPropostasWrapper>
  </PageStructureWrapper>
}