import styled from "styled-components";

export const ConsultaPropostasWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 10px;
`

export const SearchWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;
`

export const SearchFields = styled.div`
    display: flex;
    gap: 12px;
    align-items: flex-end;
`

export const ConsultaResultWrapper = styled.div`
    flex-grow: 1;
    background-color: #fff;
    border-radius: 8px;
    padding: 10px;
    overflow-y: auto;
    max-height: calc(100vh - 15vh);
    display: flex;
    flex-direction: column;
    /* gap: 60px; */
`

export const UserStepWrapper = styled.div`
    padding-top: 30px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
`

export const Title = styled.h4`
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
    color: #333;
`

export const Steps = styled.div`
    display: flex;
    justify-content: center;
    gap: 40px;
    padding: 10px;
    border-radius: 8px;
    background-color: #fff;
`

export const ModalHeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ccc;
`

export const ModalHeaderTitle = styled.h3`
    font-weight: normal;
`

export const ModalBodyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 10px;
    gap: 10px;
    max-width: 100%;
    overflow: auto;
`

export const Subtitle = styled.h4`
    margin-top: 20px;
    display: flex;
    justify-content: center;
    font-weight: normal;
    font-size: 14px;
`

export const ActionsWrapper = styled.div`
    margin-top: 20px;
    display: flex;
    justify-content: center;
    gap: 20px;
`

export const VersaoWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 12px;
    color: #333;
    width: 300px;
    text-align: center;
    font-weight: bold;
    margin: 0 auto;
`