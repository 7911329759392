import { useEffect, useState } from "react";
import VolumeDiarioHora from "../../components/Chart/Bars/volumeDiarioHora";
import VolumeSessao from "../../components/Chart/Bars/volumeSessaoDia";
import { PageStructureWrapper } from "../../components/PageStructureWrapper";
import api from "../../service/api";
import { ConsultaResultWrapper } from "../ConsutaDePropostas/style";
import { GraphicsWrapper, MonthLine, Section } from "../VolumeOperacoes/style";


export const VolumeDeSessoesPage = () => {

    type NameMapping = {
        acrofields: string;
        login: string;
        token: string;
        "proposal-selection": string;
        liveness: string;
        "facial-biometry": string;
        "document-upload": string;
        "others-upload": string;
        "cet-signature": string;
        "ccb-signature": string;
        finished: string;
    };

    const [volumeDiarioPorSessao, setVolumeDiarioPorSessao] = useState<any>({ total: [] });


    const [volumeDiarioPorHora, setVolumeDiarioPorHora] = useState<any>({ total: [] });

    const [suspectsByMonth, setSuspectsByMonth] = useState<any>({ total: [] });


    const [volumePropostasComMuitosLiveness, setVolumePropostasComMuitosLiveness] = useState<any>({ total: [] });
    const [volumePropostaLiveness, setVolumePropostaLiveness] = useState<any>({ total: [] });

    const processSessionVolume = () => {
        api.get('/dashboard/v3/session-volume-by-day').then((response) => {

            const dadosTransformados: any = {
                total: []
            };

            response.data?.forEach((dados: any) => {
                dadosTransformados?.total.push(dados?.total);
            });

            setVolumeDiarioPorSessao(dadosTransformados);
        })

    }

    const processVolumeDiarioPorHoraSessao = () => {
        const volumeDiario: any = {
            total: []
        };

        api.get('/dashboard/v3/session-volume-by-hour').then((response) => {
            console.log(response.data)
            volumeDiario.total = response.data.map((time: any) => time.volume)
            setVolumeDiarioPorHora(volumeDiario)
            console.log('volumeDiario', volumeDiario)

        })
    }

    const processSuspectsByMonth = () => {
        const dadosTransformados: any = {
            total: []
        };

        api.get('/dashboard/v3/suspects-volume-by-days').then((response) => {
            response.data?.forEach((dados: any) => {
                dadosTransformados.total.push(dados.suspects);
            });

            setSuspectsByMonth(dadosTransformados);
        })
    }

    const processVolumePropostasComMuitosLiveness = () => {
        api.get('dashboard/v3/liveness-volume-by-days').then((response) => {
            const dadosTransformados: any = {
                total: []
            };

            response.data?.forEach((dados: any) => {
                dadosTransformados.total.push(dados.total);
            });

            setVolumePropostasComMuitosLiveness(dadosTransformados);
        })
    }

    const processVolumePropsotaLiveness = () => {
        api.get('dashboard/v3/proposals-with-many-liveness').then((response) => {
            const dadosTransformados: any = {
                total: []
            };

            response.data?.forEach((dados: any) => {
                dadosTransformados.total.push(dados.proposals_count);
            });

            setVolumePropostaLiveness(dadosTransformados);
        })
    }



    useEffect(() => {
        processSessionVolume();
        processVolumeDiarioPorHoraSessao();
        processSuspectsByMonth();
        processVolumePropostasComMuitosLiveness();
        processVolumePropsotaLiveness();
    }, []);

    return <PageStructureWrapper title="Volume de Sessões">
        <ConsultaResultWrapper>
            <Section>
                <h3>Por Dia</h3>
                <GraphicsWrapper>
                    <MonthLine>
                        <VolumeSessao data={volumeDiarioPorSessao} />
                    </MonthLine>
                </GraphicsWrapper>
            </Section>
            <Section>
                <h3>Por Hora</h3>
                <GraphicsWrapper>

                    <MonthLine>
                        <VolumeDiarioHora data={volumeDiarioPorHora} />
                    </MonthLine>
                </GraphicsWrapper>
            </Section>
        </ConsultaResultWrapper>
    </PageStructureWrapper>
}