import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "../../components/Button";
import Input from "../../components/Input";
import ResendToken from "../../components/ResendToken";
import api from "../../service/api";
import { AuthForm, AuthInput, AuthWrapper, LoginContent, LoginForm, Logo, PasswordInfoWrapper, WelcomeMessage } from "./style";


const LoginPage = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [code, setCode] = useState(['', '', '', '']);
    const inputRefs = [useRef<HTMLInputElement>(null), useRef<HTMLInputElement>(null), useRef<HTMLInputElement>(null), useRef<HTMLInputElement>(null)];
    const [authCodeMode, setAuthCodeMode] = useState(false);
    const [newPasswordForm, setNewPasswordForm] = useState(false);
    const [loginResponse, setLoginResponse] = useState<any>({});
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordRequirements, setPasswordRequirements] = useState<any>([]);
    const [forgotPasswordForm, setForgotPasswordForm] = useState(false);
    const [validToken, setValidToken] = useState(false);
    const { token } = useParams();

    useEffect(() => {
        if (!token) return;
        api.get('/dashboard/check-token/' + token).then(() => {
            localStorage.setItem('token', token);
            setValidToken(true);
        }).catch(() => {
            setValidToken(false);
        })

    }, [token])

    const checkPasswordRequirements = (password: string) => {
        const requirements = [];
        if (!/[A-Z]/.test(password)) requirements.push("1 letra maiúscula");
        if (!/[a-z]/.test(password)) requirements.push("1 letra minúscula");
        if (!/[0-9]/.test(password)) requirements.push("1 número");
        if (!/[^A-Za-z0-9]/.test(password)) requirements.push("1 caractere especial");

        setPasswordRequirements(requirements);
    };

    const handleNewPasswordChange = (e: any) => {
        const newPasswordValue = e.target.value;
        setNewPassword(newPasswordValue);
        checkPasswordRequirements(newPasswordValue);
    };


    useEffect(() => {
        localStorage.clear();
    }, []);

    const Login = () => {
        setIsLoading(true);
        api.post('/dashboard/login', null, {
            headers: {
                'Authorization': 'Basic ' + btoa(email + ':' + btoa(password)),
            }
        }).then((response) => {
            localStorage.setItem('fs', new Date().toString())
            localStorage.setItem('token', response.data['session-token']);
            if (response.data['change-password']) {
                setNewPasswordForm(true);
                return;
            }
            setAuthCodeMode(true);
            setLoginResponse(response.data);
        }).catch((error) => {
            toast.error('Usuário ou senha incorretos!');
            console.log('error', error)
        }).finally(() => {
            setIsLoading(false);
        })

    }

    const handleChange = (index: number, value: string) => {
        if (/^\d*$/.test(value) && value.length <= 1) {
            const newCode = [...code];
            newCode[index] = value;
            setCode(newCode);

            if (value.length === 1 && index < inputRefs.length - 1) {
                inputRefs[index + 1].current?.focus();
            }
        }
    };

    const handleBackspace = (index: number) => {
        if (index > 0 && code[index] === '') {
            inputRefs[index - 1].current?.focus();
        }
    };

    const validateCode = () => {
        if (code.join('') === '' || code.join('').length < 4 || code.join('') !== loginResponse.token) {
            toast.error('Código inválido!');
            return;
        }

        if (code.join('') === loginResponse.token) {
            localStorage.setItem('token', loginResponse['session-token']);
            localStorage.setItem('roles', loginResponse.roles);
            toast.success('Bem-vindo!');
            if (loginResponse.roles.includes('TRACKING') && loginResponse.roles.length === 1) {
                navigate('/relatorios/tracking');
            } else {
                navigate('/dashboard');
            }
        }

    };

    const [onResend, setOnResend] = useState(false);
    const resendToken = () => {
        api.post('dashboard/resend-token', null, {
            headers: {
                'Session-Token': localStorage.getItem('token')
            }
        }).then((response) => {
            toast.success('Token reenviado!');
            setOnResend(!onResend);
        }).catch((error) => {
            toast.error('Erro ao reenviar token!');
        })
    }

    const updatePassword = () => {
        if (newPassword !== confirmPassword) {
            toast.error('Senhas não conferem!');
            return;
        }
        setIsLoading(true);
        const formData = new FormData();
        formData.append('new-password', btoa(newPassword))
        api.post('/dashboard/change-password', formData, {
            headers: {
                'session-token': localStorage.getItem('token')
            }
        }).then((response) => {
            setNewPasswordForm(false);
            setAuthCodeMode(false);
            toast.success('Senha atualizada com sucesso!');
            navigate('/');
        }).catch((error) => {
            toast.error('Erro ao atualizar senha!');
        }).finally(() => {
            setIsLoading(false);
        })
    }


    const handleForgotPassword = () => {
        setIsLoading(true);
        const formData = new FormData();
        formData.append('email', email);
        api.post('/dashboard/forgot-password', formData, {
        }).then((response) => {
            setForgotPasswordForm(false);
            setLoginResponse(response.data);
            toast.success('Email enviado com sucesso!');
        }).catch((error) => {
            toast.error('Erro ao enviar email!');
        }).finally(() => {
            setIsLoading(false);
        })
    }

    return <LoginContent>
        <WelcomeMessage>{token && !validToken ? 'Token Expirado' : token && validToken ? 'Cadastre uma nova senha' : !newPasswordForm && !forgotPasswordForm ? 'Bem-vindo!' : newPasswordForm ? 'Sua senha expirou' : forgotPasswordForm ? 'Informe o email para recuperação' : ''}</WelcomeMessage>
        <Logo />
        {(!authCodeMode && !newPasswordForm && !forgotPasswordForm && !token) && <LoginForm>
            <Input autoComplete="off" placeholder="Usuário" type="email" name="email" onChange={(e: any) => setEmail(e.target.value)} />
            <Input autoComplete="off" placeholder="Senha" type="password" name="password" onChange={(e: any) => setPassword(e.target.value)} />
            <Button
                align="right"
                width="100%"
                label="Esqueci minha senha"
                onClick={() => setForgotPasswordForm(true)}
                theme='text' />
            <Button
                onClick={Login}
                isLoading={isLoading}
                theme="primary"
                label="Entrar"
                width="100px"
            />
        </LoginForm>}
        {forgotPasswordForm && <LoginForm>
            <Input autoComplete="off" placeholder="Email" type="email" name="email" onChange={(e: any) => setEmail(e.target.value)} />
            <Button
                onClick={handleForgotPassword}
                isLoading={isLoading}
                theme="primary"
                label="Enviar"
                width="100px"
            />
        </LoginForm>}
        {(newPasswordForm || (token && validToken)) && <LoginForm>
            <Input autoComplete="off" placeholder="Nova Senha" type="password" name="new_password" onChange={handleNewPasswordChange} />
            {passwordRequirements.length > 0 && <PasswordInfoWrapper>

                <p>Sua senha deve ter pelo menos:</p>
                <ul>
                    {passwordRequirements?.map((requirement: any, index: any) => (
                        <li key={index}>{requirement}</li>
                    ))}
                </ul>
            </PasswordInfoWrapper>}
            <Input autoComplete="off" placeholder="Repita Nova Senha" type="password" name="password" onChange={(e: any) => setConfirmPassword(e.target.value)} />
            <Button
                onClick={updatePassword}
                isLoading={isLoading}
                theme="primary"
                label="Confirmar"
                width="100px"
            />
        </LoginForm>}
        {authCodeMode && <>
            <AuthWrapper>
                <AuthForm>
                    {code.map((value, index) => (
                        <AuthInput
                            key={index}
                            type="text"
                            value={value}
                            maxLength={1}
                            ref={inputRefs[index]}
                            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                                if (e.key === 'Backspace') {
                                    handleBackspace(index);
                                }
                            }}
                            onChange={(e) => handleChange(index, e.target.value)}
                        />
                    ))}
                </AuthForm>
                <ResendToken onResend={resendToken} clicked={onResend} />
            </AuthWrapper>
            <Button
                onClick={validateCode}
                isLoading={isLoading}
                theme="primary"
                label="Validar"
                width="100px"
            />
        </>
        }
    </LoginContent>
}

export default LoginPage;