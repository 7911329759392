import { useEffect, useState } from "react";
import CustomFunnel from "../../components/Chart/Lines/customFunnel";
import { PageStructureWrapper } from "../../components/PageStructureWrapper";
import api from "../../service/api";
import { ConsultaResultWrapper } from "../ConsutaDePropostas/style";
import { Funnel, FunnelWrapper, GraphicsWrapper, Section, Title } from "./style";
import { toast } from "react-toastify";

export const FunisPage = () => {

    type NameMapping = {
        acrofields: string;
        login: string;
        token: string;
        "proposal-selection": string;
        liveness: string;
        "facial-biometry": string;
        "document-upload": string;
        "others-upload": string;
        "cet-signature": string;
        "ccb-signature": string;
        finished: string;
    };

    const order = [
        "acrofields",
        "login",
        "token",
        "proposal-selection",
        "liveness",
        "facial-biometry",
        "document-upload",
        "others-upload",
        "cet-signature",
        "ccb-signature",
        "finished"
    ];

    const nameMapping: NameMapping = {
        acrofields: "Cargas Processadas",
        login: "Login",
        token: "Token",
        "proposal-selection": "Proposta Selecionadas",
        liveness: "Liveness",
        "facial-biometry": "Biometria Facial",
        "document-upload": "Upload de Documentos",
        "others-upload": "Upload Outros",
        "cet-signature": "CET",
        "ccb-signature": "CCB",
        finished: "Finalizado"
    };


    const [funnelResponse, setFunnelResponse] = useState<any>();

    const [proponente, setProponente] = useState<any>([]);
    const [avalista1, setAvalista1] = useState<any>([]);
    const [avalista2, setAvalista2] = useState<any>([]);

    const [proponenteMonth, setProponenteMonth] = useState<any>([]);
    const [avalista1Month, setAvalista1Month] = useState<any>([]);
    const [avalista2Month, setAvalista2Month] = useState<any>([]);



    const processDataFunnel = () => {
        api.get('/newdashboard/funnel').then((response) => {
            setFunnelResponse(response.data);
            let json = response.data.continued.day;
            console.log(order.map((key) => ({
                'name': (nameMapping as any)[key],
                'value': json[key][0]
            })))

            setProponente(order.map((key) => ({
                'name': (nameMapping as any)[key],
                'value': json[key][0]
            })));
            setAvalista1(order.map((key) => ({
                'name': (nameMapping as any)[key],
                'value': json[key][1]
            })));
            setAvalista2(order.map((key) => ({
                'name': (nameMapping as any)[key],
                'value': json[key][2]
            })));

            let jsonMonth = response.data.cumulative.month;
            setProponenteMonth(order.map((key) => ({
                'name': (nameMapping as any)[key],
                'value': jsonMonth[key][0]
            })));
            setAvalista1Month(order.map((key) => ({
                'name': (nameMapping as any)[key],
                'value': jsonMonth[key][1]
            })));
            setAvalista2Month(order.map((key) => ({
                'name': (nameMapping as any)[key],
                'value': jsonMonth[key][2]
            })));
        }).catch((error) => {
            toast.error('Ocorreu um erro ao consultar informações do funil');
            console.log('error', error);
        })

    }


    useEffect(() => {
        processDataFunnel();
    }, []);

    return <PageStructureWrapper title="Funis">
        <ConsultaResultWrapper>
            <Section>
                <h3>Funil Diário</h3>
                <GraphicsWrapper>
                    <FunnelWrapper>
                        <Funnel>
                            <Title>Proponente</Title>
                            <CustomFunnel data={proponente} />
                        </Funnel>
                        <Funnel>
                            <Title>Avalista 1</Title>
                            <CustomFunnel data={avalista1} />
                        </Funnel>
                        <Funnel>
                            <Title>Avalista 2</Title>
                            <CustomFunnel data={avalista2} />
                        </Funnel>
                    </FunnelWrapper>
                </GraphicsWrapper>
            </Section>
            <Section>
                <h3>Funil Mensal</h3>
                <GraphicsWrapper>
                    <FunnelWrapper>
                        <Funnel>
                            <Title>Proponente</Title>
                            <CustomFunnel data={proponenteMonth} />
                        </Funnel>
                        <Funnel>
                            <Title>Avalista 1</Title>
                            <CustomFunnel data={avalista1Month} />
                        </Funnel>
                        <Funnel>
                            <Title>Avalista 2</Title>
                            <CustomFunnel data={avalista2Month} />
                        </Funnel>
                    </FunnelWrapper>
                </GraphicsWrapper>
            </Section>
        </ConsultaResultWrapper>
    </PageStructureWrapper>
}