import { DropDownWrapper } from "./style";

interface DropDownProps {
  titleHeader?: string;
  options: { value: string; label: string }[];
  onChange: (value: string) => void;
  defaultValue?: string;
}

// Componente DropDown
const DropDown: React.FC<DropDownProps> = ({ options, onChange, defaultValue, titleHeader }) => {
  return (
    <DropDownWrapper>
      {titleHeader && <span>{titleHeader}</span>}
      <select defaultValue={defaultValue} onChange={(e) => onChange(e.target.value)}>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </DropDownWrapper>
  );
};

export default DropDown;