import Chart, { ChartConfiguration } from 'chart.js/auto';
import React, { useEffect, useRef } from 'react';

interface LineChartProps {
  data: {
    running: number[];
    done: number[];
  };
}

const ProducaoPorHora: React.FC<LineChartProps> = ({ data }) => {
  const chartRef = useRef<HTMLCanvasElement>(null);
  const chartInstance = useRef<Chart<'line', unknown> | null>(null);

  useEffect(() => {
    if (chartRef && chartRef.current) {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }

      const ctx = chartRef.current.getContext('2d');
      if (ctx) {
        chartInstance.current = new Chart(ctx, {
          type: 'line',
          data: {
            labels: Array.from({ length: data?.running?.length }, (_, i) => (i).toString()),
            datasets: [
              {
                label: 'Em andamento',
                data: data.running,
                borderColor: '#eb7d37',
                backgroundColor: '#eb7d37',
                borderWidth: 2,
                fill: false,
              },
              {
                label: 'Finalizados',
                data: data.done,
                borderColor: '#a5a5a5',
                backgroundColor: '#a5a5a5',
                borderWidth: 2,
                fill: false,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              y: {
                beginAtZero: true,
              },
            },
            plugins: {
              legend: {
                display: true,
              },
            },
          },
        } as ChartConfiguration<'line', number[], string>);
      }
    }

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [data]);

  return <canvas ref={chartRef} />;
};

export default ProducaoPorHora;
