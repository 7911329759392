import JSZip from "jszip"
import { useEffect, useMemo, useState } from "react"
import { FiDownload } from "react-icons/fi"
import { GrInsecure } from "react-icons/gr"
import { MdClose } from "react-icons/md"
import Modal from 'react-modal'
import Button from "../../components/Button"
import CustomTable from "../../components/CustomTable"
import DateRangeInput from "../../components/DatePicker"
import Input from "../../components/Input"
import { PageStructureWrapper } from "../../components/PageStructureWrapper"
import TextArea from "../../components/TextArea"
import api from "../../service/api"
import { UserContainsRole } from "../../service/auth.service"
import { ActionsWrapper, ConsultaResultWrapper, ModalBodyWrapper, ModalHeaderTitle, ModalHeaderWrapper, SearchFields, SearchWrapper, Subtitle } from "../ConsutaDePropostas/style"
import { Image, ImageContent, ImagesWrapper, Info, InfoWrapper, WarningMessage } from "./style"
import { toast } from "react-toastify"

export const RiscosPage = () => {
    const columnRisks = useMemo(
        () => [
            {
                Header: 'CPF/CNPJ',
                accessor: 'cpfCnpj',
            },
            {
                Header: 'Proposta',
                accessor: 'proposta',
            },
            {
                Header: 'Telefone',
                accessor: 'telefone',
            },
            {
                Header: 'Data Hora',
                accessor: 'dataHora',
            },
            {
                Header: 'Motivo',
                accessor: 'motivo',
            },
            {
                Header: 'Ação',
                Cell: (data: any) => (
                    <Button leftIcon={<GrInsecure />} label="Liberar" theme={"primary"} onClick={() => setOpenModal(true)} disabled={!UserContainsRole(['RISKS'])}/>
                ),
            },
        ],
        []
    );

    const columnMinWidths = {}

    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [risks, setRisks] = useState<any[]>([])
    const [numeroProposta, setNumeroProposta] = useState("");
    const [numeroContrato, setNumeroContrato] = useState("");
    const [cpfCnpj, setCpfCnpj] = useState("");
    const [openModal, setOpenModal] = useState(false)
    const [openModalDetails, setOpenModalDetails] = useState(false)
    const [risksSelected, setRisksSelected] = useState<any>(null)


    useEffect(() => {
        if (startDate && endDate) {
            api.get(`https://assinaturabradesco.nexyon.com.br/api/dashboard/suspect/${startDate.getTime() / 1000}/${endDate.getTime() / 1000}`).then((response) => {
                setRisks(response.data)
                setFilteredRisks(response.data)
                setNumeroProposta("")
                setNumeroContrato("")
                setCpfCnpj("")

            })
        }
    }, [startDate, endDate])

    const [filteredRisks, setFilteredRisks] = useState<any[]>([]);
    const applyFilters = () => {

        let filteredData = [...risks]
        if (numeroProposta) {
            filteredData = filteredData?.filter(
                (item) => item.proposta?.toLowerCase().includes(numeroProposta?.toLowerCase())
            );
        }

        if (cpfCnpj) {
            filteredData = filteredData?.filter(
                (item) => item.cpfCnpj?.toLowerCase().includes(cpfCnpj?.toLowerCase())
            );
        }

        setFilteredRisks(filteredData);
    };

    const downloadAll = () => {
        const csvSections = [
            {
              title: 'Riscos',
              columns: columnRisks,
              data: filteredRisks,
            },
          ];
          
          let csvContent = '';
          
          csvSections.forEach((section) => {
            const formatNumber = (value: any) => {
                return value?.toLocaleString('pt-BR');
            };
            csvContent += `${section.title}\n`;
            csvContent += section.columns?.map((col) => col.Header).join(';') + '\n';
            csvContent += section.data
              ?.map((row: any) =>
                section.columns
                  ?.map((col) => {
                    const value = col.accessor ? row[col.accessor] : '';
                    const formattedValue = formatNumber(value);
                    const stringValue = typeof formattedValue === 'string' ? formattedValue : String(formattedValue);
                    let encodedValue = stringValue.replace(/"/g, '""');
                    if (encodedValue?.length > 0 && encodedValue[0] === '+' || (/^-?\d+\.?\d*$/.test(encodedValue))) {
                        encodedValue = encodedValue.replace('+', ''); 
                        return `${encodedValue}`;
                    }
                    return ` ${encodedValue}`;
                  })
                  .join(';')
              )
              .join('\n');
            csvContent += `\n\n\n`;
          });
          
        
          const csvData = new Blob(['\ufeff', csvContent], { type: 'text/csv;charset=utf-8;' });
          const link = document.createElement('a');
          const url = URL.createObjectURL(csvData);
          
          link.setAttribute('href', url);
          link.setAttribute('download', `${'riscos'}.csv`);
          link.style.visibility = 'hidden';
          
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          downloadImages();
    }

    const downloadImages = () => {
        const zip = new JSZip();

        risks.forEach((risk, riskIndex) => {
          const proposalFolder = zip.folder(`${risk.proposta}`); 
      
          const images = risk.images || [];
      
          images.forEach((base64: string, imageIndex: number) => {
            // Remover o prefixo "data:image/jpg;base64,"
            const base64WithoutPrefix = base64.replace(/^data:image\/jpg;base64,/, '');
      
            // Decodificar a imagem base64
            const binaryString = atob(base64WithoutPrefix);
            const byteArray = new Uint8Array(binaryString.length);
      
            for (let i = 0; i < binaryString.length; i++) {
              byteArray[i] = binaryString.charCodeAt(i);
            }
      
            if (proposalFolder) {
                proposalFolder.file(`risk_${riskIndex + 1}_image_${imageIndex + 1}.jpg`, byteArray, { binary: true });
            }
          });
        });
      
        zip.generateAsync({ type: 'blob' }).then((content) => {
          const link = document.createElement('a');
          link.href = URL.createObjectURL(content);
          link.download = 'risks_images.zip';
      
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
    }


    useEffect(() => {
        applyFilters();
    }, [numeroProposta, numeroContrato]);


    const searchByDocumentOrPhone = () => {
       toast.loading("Buscando informações...")
       
        api.get(`https://assinaturabradesco.nexyon.com.br/api/dashboard/suspect/${cpfCnpj}/document`).then((response) => {
            setRisks(response.data)
            setFilteredRisks(response.data)
            setNumeroProposta("")
            setNumeroContrato("")
            toast.dismiss()
        }).catch(() => {
            toast.dismiss()
            toast.error("Não foi possível encontrar informações")
        })
        
    }

    useEffect(() => {
        if (cpfCnpj.length === 11 || cpfCnpj.length === 14) {
            searchByDocumentOrPhone()
        }
    }, [cpfCnpj])

    return <PageStructureWrapper title="Riscos" >
        <SearchWrapper>
            <SearchFields>
                <DateRangeInput titleHeader="Datas:"
                    startDate={startDate} setStartDate={setStartDate}
                    endDate={endDate} setEndDate={setEndDate}
                />
                <Input
                    titleHeader="Número da Proposta:"
                    placeholder="Ex: 123456"
                    value={numeroProposta}
                    onChange={(e) => setNumeroProposta(e.target.value)}
                />
                {/* <Input
                    titleHeader="Número do Contrato:"
                    placeholder="Ex: 123456"
                    value={numeroContrato}
                    onChange={(e) => setNumeroContrato(e.target.value)} disabled={true}
                /> */}
                <Input
                    titleHeader="CPF/CNPJ:"
                    placeholder="Ex: 1234567890"
                    value={cpfCnpj}
                    onChange={(e) => setCpfCnpj(e.target.value)}
                />
            </SearchFields>
            <Button disabled={filteredRisks.length == 0} onClick={downloadAll} leftIcon={<FiDownload />} label="Download" theme="primary" height="40px" />
        </SearchWrapper>
        <ConsultaResultWrapper>
            <CustomTable
                maxHeight="calc(100vh - 300px)"
                columnMinWidths={columnMinWidths}
                columns={columnRisks}
                onRowClick={(row: any) => { setRisksSelected(row); setOpenModalDetails(true) }}
                data={filteredRisks} />
        </ConsultaResultWrapper>
        <Modal isOpen={openModal} style={
            {
                overlay: { backgroundColor: 'rgba(0,0,0,0.5)', zIndex: 999 }, content: {
                    borderRadius: '10px',
                    height: '530px',
                    width: '500px',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    gap: '10px',
                }
            }
        } >
            <ModalHeaderWrapper>
                <ModalHeaderTitle>Deseja Confirmar?</ModalHeaderTitle>
                <MdClose size={20} onClick={() => setOpenModal(false)} style={{ cursor: 'pointer' }} />
            </ModalHeaderWrapper>
            <ModalBodyWrapper>
                <Subtitle>Informe uma justificativa para proesseguir:</Subtitle>
                <TextArea rows={15} placeholder="Digite aqui..." />
            </ModalBodyWrapper>
            <ActionsWrapper>
                <Button leftIcon={<GrInsecure />} label="Liberar" theme="primary" height="40px" />
                <Button label="Cancelar" onClick={() => setOpenModal(false)} theme="text" height="40px" />
            </ActionsWrapper>
        </Modal>
        <Modal isOpen={openModalDetails} style={{ overlay: { backgroundColor: 'rgba(0,0,0,0.5)', zIndex: '99' }, content: { borderRadius: '10px' } }} >
            <ModalHeaderWrapper>
                <ModalHeaderTitle>Detalhes</ModalHeaderTitle>
                <MdClose size={20} onClick={() => setOpenModalDetails(!openModalDetails)} style={{ cursor: 'pointer' }} />
            </ModalHeaderWrapper>
            <ModalBodyWrapper>
                <InfoWrapper>
                    <Info>
                        <span>CPF/CNPJ:</span>
                        <span>{risksSelected?.cpfCnpj}</span>
                    </Info>
                    <Info>
                        <span>Proposta:</span>
                        <span>{risksSelected?.proposta}</span>
                    </Info>
                    <Info>
                        <span>Telefone:</span>
                        <span>{risksSelected?.telefone}</span>
                    </Info>
                    <Info>
                        <span>Data Hora:</span>
                        <span>{risksSelected?.dataHora}</span>
                    </Info>
                    <Info>
                        <span>Motivo:</span>
                        <span>{risksSelected?.motivo}</span>
                    </Info>
                </InfoWrapper>
                <ImagesWrapper>
                    {risksSelected && risksSelected.images.length > 0 ? risksSelected?.images?.map((image: any) => {
                       return <ImageContent>
                            <Image src={image} alt="Imagem" />
                            <span>{risksSelected?.dataHora}</span>
                        </ImageContent>
                    }) : <WarningMessage>As imagens são armazenadas por 7 dias</WarningMessage>}
                </ImagesWrapper>
            </ModalBodyWrapper>
            <ActionsWrapper>
                <Button leftIcon={<GrInsecure />} label="Liberar" theme="primary" height="40px" disabled={!UserContainsRole(['RISKS'])} />
            </ActionsWrapper>
        </Modal>
    </PageStructureWrapper>
}