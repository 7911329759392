import { CustomButtom, LoadingIcon } from "./styles";


interface ButtonProps {
    theme: 'primary' | 'secondary' | 'text';
    label: string;
    width?: string;
    height?: string;
    align?: string;
    isLoading?: boolean;
    leftIcon?: React.ReactNode;
    disabled?: boolean;
    onClick?: () => void;
}

const Button = (props: ButtonProps) => {

    return <CustomButtom
        onClick={props.onClick}
        wdith={props.width}
        height={props.height}
        theme={props.theme}
        disabled={props.isLoading || props.disabled}
        align={props.align}>
        {props.leftIcon && props.leftIcon}
        {props.isLoading && <LoadingIcon />} {props.label}
    </CustomButtom>
}

export default Button;