
export const formatDate = (date: Date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export const dateToTimestamp = (date: Date) => {
  return Math.floor(date.getTime() / 1000);
}


export function compararDatas(dataString: string): boolean {

  const partes = dataString.split('-');

  if (partes.length === 3) {
    const ano = parseInt(partes[0], 10);
    const mes = parseInt(partes[1], 10) - 1;
    const dia = parseInt(partes[2], 10);
    const data = new Date(ano, mes, dia);

    return data.toDateString() === new Date().toDateString();
  }
  return false;
}


export function formatHourToString(hours: number): string {
  const totalSeconds = Math.floor(hours * 3600);
  const hoursPart = Math.floor(totalSeconds / 3600);
  const minutesPart = Math.floor((totalSeconds % 3600) / 60);
  const secondsPart = totalSeconds % 60;

  let result = "";
  if (hoursPart > 0) result += `${hoursPart} horas `;
  if (minutesPart > 0 || (hoursPart > 0 && secondsPart > 0)) result += `${minutesPart} minutos `;
  if (secondsPart > 0 || totalSeconds === 0) result += `${secondsPart} segundos`;

  return result.trim();

}


export function formatHour(hours: number): string {
  const totalSeconds = Math.floor(hours * 3600);
  const hoursPart = Math.floor(totalSeconds / 3600);
  const minutesPart = Math.floor((totalSeconds % 3600) / 60);
  const secondsPart = totalSeconds % 60;

  const hoursString = hoursPart.toString().padStart(2, '0');
  const minutesString = minutesPart.toString().padStart(2, '0');
  const secondsString = secondsPart.toString().padStart(2, '0');

  return `${hoursString}:${minutesString}:${secondsString}`;
}

export const formatDateAndHour = (data: Date) => {
  if (!data) return '';
  const dia = data.getDate().toString().padStart(2, '0');
  const mes = (data.getMonth() + 1).toString().padStart(2, '0');
  const ano = data.getFullYear();
  const hora = data.getHours().toString().padStart(2, '0');
  const minuto = data.getMinutes().toString().padStart(2, '0');
  const segundos = data.getSeconds().toString().padStart(2, '0');

  return `${dia}/${mes}/${ano} ${hora}:${minuto}:${segundos}`;
}