import React from 'react';
import styled from 'styled-components';
import { StyledTextArea } from './style';

interface TextAreaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
}

const TextArea: React.FC<TextAreaProps> = ({ ...rest }) => {
  return <StyledTextArea {...rest} />;
};

export default TextArea;