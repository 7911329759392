import { useState } from "react";
import { FiDownload } from "react-icons/fi";
import { toast } from "react-toastify";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import { PageStructureWrapper } from "../../../components/PageStructureWrapper";
import api from "../../../service/api";
import { ConsultaPropostasWrapper } from "../../ConsutaDePropostas/style";
import { ConsultaResultWrapper } from "../SlaPropostaTodos/style";
import { InputWrapper, SearchFieldsWrapper } from "./style";

export const TrackingPage = () => {
  const [numeroProposta, setNumeroProposta] = useState<any>('');
  const [numeroContrato, setNumeroContrato] = useState<any>('');
  const [downloading, setDownloading] = useState(false);

  const downloadTracking = () => {

    if (!numeroProposta && !numeroContrato) {
      toast.error('Preencha pelo menos um campo para realizar a busca');
      setDownloading(false);
      return;
    }
    toast.loading('Gerando tracking...');
    setDownloading(true);

    const formData = new FormData();
    if (numeroProposta) {
      formData.append('proposal', numeroProposta);
    }
    if (numeroContrato) {
      formData.append('contract', numeroContrato);
    }
    api.post(`/dashboard/generate-tracking`, formData, {
      responseType: 'blob',
    },).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.download = 'tracking.pdf';
      document.body.appendChild(link);
      link.click();
      link?.parentNode?.removeChild(link);
    }).catch((error) => {
      console.log('tracking', error)
      toast.error('Ocorreu um erro ao gerar o tracking');
    }).finally(() => {
      toast.dismiss();
      setDownloading(false);
    });
  };


  return <PageStructureWrapper title="Tracking">
    <ConsultaPropostasWrapper>

      <ConsultaResultWrapper>
        <span>Para realizar o download do tracking preencha o campo abaixo:</span>
        <SearchFieldsWrapper>
          <InputWrapper>
            <Input titleHeader="Número da Proposta:" placeholder="Ex: 123456" onChange={(e) => setNumeroProposta(e.target.value)} disabled={downloading} />
            <span>ou</span>
            <Input titleHeader="Número do Contrato:" placeholder="Ex: 12345678" onChange={(e) => setNumeroContrato(e.target.value)} disabled={downloading} />
          </InputWrapper>
          <Button width="300px" onClick={downloadTracking} leftIcon={<FiDownload />} label="Download" theme="primary" height="40px" isLoading={downloading} />
        </SearchFieldsWrapper>
      </ConsultaResultWrapper>
    </ConsultaPropostasWrapper>
  </PageStructureWrapper>
}