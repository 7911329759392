import Chart, { ChartConfiguration } from 'chart.js/auto';
import React, { useEffect, useRef } from 'react';

interface LineChartProps {
  data: {
    received: any[];
    running: any[];
    done: any[];
    not_done: any[]
  };
}

const LineChart: React.FC<LineChartProps> = ({ data }) => {
  const chartRef = useRef<HTMLCanvasElement>(null);
  const chartInstance = useRef<Chart<'line', unknown> | null>(null);

  useEffect(() => {
    if (chartRef && chartRef.current) {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }

      const ctx = chartRef.current.getContext('2d');
      if (ctx) {
        chartInstance.current = new Chart(ctx, {
          type: 'line',
          data: {
            labels: Array.from({ length: data.received?.length }, (_, i) => (i + 1).toString()), // Dias do mês
            datasets: [
              {
                label: 'Recebidos',
                data: data.received,
                borderColor: '#485184',
                backgroundColor: '#485184',
                borderWidth: 2,
                fill: false,
              },
              {
                label: 'Em andamento',
                data: data.running,
                borderColor: '#FF9E00',
                backgroundColor: '#FF9E00',
                borderWidth: 2,
                fill: false,
              },
              {
                label: 'Concluídas',
                data: data.done,
                borderColor: '#4CAF50',
                backgroundColor: '#4CAF50',
                borderWidth: 2,
                fill: false,
              },
              // {
              //   label: 'Não Concluidas',
              //   data: data.not_done,
              //   borderColor: '#b1b1b1',
              //   backgroundColor: '#b1b1b1',
              //   borderWidth: 2,
              // },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              y: {
                beginAtZero: true,
              },
            },
            plugins: {
              tooltip: {
                callbacks: {
                  label: (context) => {
                    console.log('context', context)
                    const label = context.dataset.label || '';
                    const value = context.parsed.y || 0;
                    return ` ${value.toLocaleString('pt-br')}${data.received[context.dataIndex] > 0 ? ' - ' + ((value / data.received[context.dataIndex]) * 100).toFixed(2) + '%' : ''}`;
                  },
                },
              },
            },
          },
        } as ChartConfiguration<'line', number[], string>);
      }
    }

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [data]);

  return <canvas ref={chartRef} />;
};

export default LineChart;
