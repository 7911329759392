import { DashboardPage } from "../../components/Dashboard";
import { PageStructureWrapper } from "../../components/PageStructureWrapper";
import { ConsultaResultWrapper } from "../ConsutaDePropostas/style";

export const VolumeOperacoesPage = () => {

    return <PageStructureWrapper title="Volume de Operações">
        <ConsultaResultWrapper>
             <DashboardPage/>
        </ConsultaResultWrapper>
    </PageStructureWrapper>
}