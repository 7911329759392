import { MenuButtonWrapper, MenuItem } from "./style";

interface MenuButtonProps {
    title: string
    icon: any;
    isActive?: boolean;
    isSubItem?: boolean;
    onClick?: () => void;
}

export const MenuButton = (props: MenuButtonProps) => {
    return <MenuButtonWrapper isSubItem={props.isSubItem} onClick={props.onClick} isActived={props.isActive}>
        <MenuItem isSubItem={props.isSubItem}>
            {props.icon}
            <span>{props.title}</span>
        </MenuItem>
    </MenuButtonWrapper>
}