import { useState } from "react";
import { BsGraphUp } from "react-icons/bs";
import { CiWarning } from "react-icons/ci";
import { TbReportAnalytics, TbReportSearch } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import NexyonLogo from '../../assets/nexyon_logo.png';
import { UserContainsRole } from "../../service/auth.service";
import { MenuButton } from "../MenuButton";
import { MenuWrapper, SideContent, SideFooter, SideHeader, SideWrapper } from "./style";


export const SideMenu = () => {
    const navigate = useNavigate();

    const [menuItems, setMenuItems] = useState([
        {
            title: "Home",
            icon: <BsGraphUp />,
            isActive: true,
            roles: ['USER', 'MANAGER'],
            navigate: '/dashboard'
        },
        {
            title: "Consultas",
            icon: <TbReportSearch />,
            isActive: false,
            roles: ['USER', 'MANAGER'],
            navigate: '',
            child: [
                {
                    title: "Propostas",
                    icon: <TbReportSearch />,
                    isActive: false,
                    roles: ['USER', 'MANAGER'],
                    navigate: '/consulta-propostas'
                },
                {
                    title: "Riscos",
                    icon: <CiWarning />,
                    isActive: false,
                    roles: ['MANAGER', 'RISKS'],
                    navigate: '/riscos'
                }
            ]
        },
        {
            title: "Relatorios",
            icon: <TbReportAnalytics />,
            isActive: false,
            roles: ['USER', 'MANAGER', 'TRACKING'],
            navigate: '',
            child: [
                // {
                //     title: "Relatório de Login",
                //     icon: <TbReportAnalytics />,
                //     isActive: false,
                //     roles: ['USER', 'MANAGER'],
                //     navigate: '/relatorios/login'
                // },
                {
                    title: "Tracking",
                    icon: <TbReportAnalytics />,
                    isActive: false,
                    roles: ['USER', 'MANAGER', 'TRACKING'],
                    navigate: '/relatorios/tracking'
                },
                {
                    title: "Propostas",
                    icon: <TbReportAnalytics />,
                    isActive: false,
                    roles: ['USER', 'MANAGER'],
                    navigate: '/relatorios/antigo'
                },
                {
                    title: "Sessão",
                    icon: <TbReportAnalytics />,
                    isActive: false,
                    roles: ['USER', 'MANAGER'],
                    navigate: '/relatorios/sessao'
                },
                {
                    title: "Liveness",
                    icon: <TbReportAnalytics />,
                    isActive: false,
                    roles: ['USER', 'MANAGER'],
                    navigate: '/relatorios/liveness'
                },
                {
                    title: "Biometria",
                    icon: <TbReportAnalytics />,
                    isActive: false,
                    roles: ['USER', 'MANAGER'],
                    navigate: '/relatorios/serpro'
                },
                {
                    title: "Sla por Proposta",
                    icon: <TbReportAnalytics />,
                    isActive: false,
                    roles: ['USER', 'MANAGER'],
                    navigate: '/relatorios/sla/propostas'
                },
                {
                    title: "Sla por Função",
                    icon: <TbReportAnalytics />,
                    isActive: false,
                    roles: ['USER', 'MANAGER'],
                    navigate: '/relatorios/proposta'
                },
            ]
        },
        {
            title: "Gráficos",
            icon: <BsGraphUp />,
            isActive: false,
            roles: ['USER', 'MANAGER'],
            navigate: '',
            child: [
                {
                    title: "Volume de Operações",
                    icon: <BsGraphUp />,
                    isActive: false,
                    roles: ['USER', 'MANAGER'],
                    navigate: '/volume-operacoes'
                },
                {
                    title: "Desempenho",
                    icon: <BsGraphUp />,
                    isActive: false,
                    roles: ['USER', 'MANAGER'],
                    navigate: '/analise-operacoes'
                }, {
                    title: "Volume de Sessões",
                    icon: <BsGraphUp />,
                    isActive: false,
                    roles: ['USER', 'MANAGER'],
                    navigate: '/volumes/sessoes'
                },
                {
                    title: "Volume de Serviços",
                    icon: <BsGraphUp />,
                    isActive: false,
                    roles: ['USER', 'MANAGER'],
                    navigate: '/volumes/servicos'
                },
                {
                    title: "Volume de Riscos",
                    icon: <BsGraphUp />,
                    isActive: false,
                    roles: ['USER', 'MANAGER'],
                    navigate: '/volumes/riscos'
                },
                {
                    title: "Funis",
                    icon: <BsGraphUp />,
                    isActive: false,
                    roles: ['USER', 'MANAGER'],
                    navigate: '/funis'
                },

            ]
        },

    ])

    return <SideWrapper>
        <SideContent>
            <SideHeader>
                <img src={NexyonLogo} alt="Logo" width={110} height={28} />
            </SideHeader>
            <MenuWrapper>
                {menuItems.map((item, index) => {
                    const isChildActive = item.child && item.child.some(child => window.location.pathname === child.navigate);
                    const isActive = window.location.pathname === item.navigate || (item.child && item.isActive) || isChildActive;

                    if (item.roles && !UserContainsRole(item.roles)) {
                        return null;
                    }

                    return (
                        <>
                            <MenuButton
                                onClick={() => {
                                    if (!item.child) {
                                        navigate(item.navigate);
                                    } else {
                                        menuItems[index].isActive = !menuItems[index].isActive;
                                        setMenuItems([...menuItems]);
                                    }
                                }}
                                key={index}
                                title={item.title}
                                icon={item.icon}
                                isActive={isActive}
                            />
                            {item.child && isActive && item.child.map((child, childIndex) => {
                                if (child.roles && !UserContainsRole(child.roles)) {
                                    return null;
                                }
                                return <MenuButton
                                    isSubItem={true}
                                    onClick={() => navigate(child.navigate)}
                                    key={childIndex}
                                    title={child.title}
                                    icon={child.icon}
                                    isActive={window.location.pathname === child.navigate}
                                />
                            })}
                        </>
                    );
                })}
            </MenuWrapper>
        </SideContent>
        <SideFooter>
            Versão 2.0
        </SideFooter>
    </SideWrapper>
}