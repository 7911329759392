import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'tippy.js/dist/tippy.css';
import './App.css';
import { AnaliseOperacoesPage } from './pages/AnaliseOperacaoes';
import { ConsultaDePropostasPage } from './pages/ConsutaDePropostas';
import { FunisPage } from './pages/Funis';
import { HomePage } from './pages/Home';
import LoginPage from './pages/Login';
import { NotFoundPage } from './pages/NotFound';
import { RelatorioLivenessPage } from './pages/Relatorios/Liveness';
import { RelatorioLoginPage } from './pages/Relatorios/Login';
import { RelatorioPropostasPage } from './pages/Relatorios/Propostas';
import { RelatorioAntigoPage } from './pages/Relatorios/Relatorio';
import { RelatorioSerproPage } from './pages/Relatorios/Serpro';
import { RelatorioSessaoPage } from './pages/Relatorios/Sessao';
import { RelatorioSlaPropostasPage } from './pages/Relatorios/SlaPropostaTodos';
import { TrackingPage } from './pages/Relatorios/Tracking';
import { RiscosPage } from './pages/Riscos';
import { VolumeDeRiscosPage } from './pages/VolumeDeRiscos';
import { VolumeDeServicosPage } from './pages/VolumeDeServicos';
import { VolumeDeSessoesPage } from './pages/VolumeDeSessoes';
import { VolumeOperacoesPage } from './pages/VolumeOperacoes';
import PrivateRoute from "./security/PrivateRoute";

function App() {


  return (
    <>
      <ToastContainer position='top-center' />
      <BrowserRouter>
        <Routes>
          <Route path='/analise-operacoes' Component={PrivateRoute} >
            <Route path='/analise-operacoes' Component={AnaliseOperacoesPage} />
          </Route>
          <Route path='/funis' Component={PrivateRoute} >
            <Route path='/funis' Component={FunisPage} />
          </Route>
          <Route path='/volume-operacoes' Component={PrivateRoute} >
            <Route path='/volume-operacoes' Component={VolumeOperacoesPage} />
          </Route>
          <Route path='/dashboard' Component={PrivateRoute} >
            <Route path='/dashboard' Component={HomePage} />
          </Route>
          <Route path='/consulta-propostas' Component={PrivateRoute} >
            <Route path='/consulta-propostas' Component={ConsultaDePropostasPage} />
          </Route>
          <Route path='/relatorios/login' Component={PrivateRoute} >
            <Route path='/relatorios/login' Component={RelatorioLoginPage} />
          </Route>
          <Route path='/relatorios/sessao' Component={PrivateRoute} >
            <Route path='/relatorios/sessao' Component={RelatorioSessaoPage} />
          </Route>
          <Route path='/relatorios/antigo' Component={PrivateRoute} >
            <Route path='/relatorios/antigo' Component={RelatorioAntigoPage} />
          </Route>
          <Route path='/relatorios/tracking' Component={PrivateRoute} >
            <Route path='/relatorios/tracking' Component={TrackingPage} />
          </Route>
          <Route path='/relatorios/proposta' Component={PrivateRoute} >
            <Route path='/relatorios/proposta' Component={RelatorioPropostasPage} />
          </Route>
          <Route path='/relatorios/sla/propostas' Component={PrivateRoute} >
            <Route path='/relatorios/sla/propostas' Component={RelatorioSlaPropostasPage} />
          </Route>
          <Route path='/relatorios/liveness' Component={PrivateRoute} >
            <Route path='/relatorios/liveness' Component={RelatorioLivenessPage} />
          </Route>
          <Route path='/relatorios/serpro' Component={PrivateRoute} >
            <Route path='/relatorios/serpro' Component={RelatorioSerproPage} />
          </Route>
          <Route path='/volumes/sessoes' Component={PrivateRoute} >
            <Route path='/volumes/sessoes' Component={VolumeDeSessoesPage} />
          </Route>
          <Route path='/volumes/servicos' Component={PrivateRoute} >
            <Route path='/volumes/servicos' Component={VolumeDeServicosPage} />
          </Route>
          <Route path='/volumes/riscos' Component={PrivateRoute} >
            <Route path='/volumes/riscos' Component={VolumeDeRiscosPage} />
          </Route>
          <Route path='/riscos' Component={PrivateRoute} >
            <Route path='/riscos' Component={RiscosPage} />
          </Route>
          <Route path='/nova-senha/:token' Component={LoginPage} />
          <Route path='/' Component={LoginPage} />
          <Route path='*' Component={NotFoundPage} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
