import { InputWrapper } from "./style";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    titleHeader?: string;
}


const Input = (props: InputProps) => {
    return (
       <InputWrapper>
        {props.titleHeader && <span>{props.titleHeader}</span>}
        <input {...props}/>
       </InputWrapper>
    )
}

export default Input;