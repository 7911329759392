import { useEffect, useState } from "react";
import VolumeDeServicos from "../../components/Chart/Bars/VolumeDeServicos";
import { PageStructureWrapper } from "../../components/PageStructureWrapper";
import api from "../../service/api";
import { ConsultaResultWrapper } from "../ConsutaDePropostas/style";
import { GraphicsWrapper, MonthLine, Section } from "../VolumeOperacoes/style";


export const VolumeDeServicosPage = () => {

    type NameMapping = {
        acrofields: string;
        login: string;
        token: string;
        "proposal-selection": string;
        liveness: string;
        "facial-biometry": string;
        "document-upload": string;
        "others-upload": string;
        "cet-signature": string;
        "ccb-signature": string;
        finished: string;
    };



    const [volumeDeLiveness, setVolumeDeLiveness] = useState<any>({total: []});
    const [volumeDeBiometrias, setVolumeDeBiometrias] = useState<any>({total: []});
  

    const processVolumeLiveness = () => {
        api.get('dashboard/v3/liveness-volume-by-days').then((response) => {
            const dadosTransformados: any = []
    
            response.data?.forEach((dados: any) => {
                dadosTransformados.push(dados.total);
            });
    
            setVolumeDeLiveness(dadosTransformados);
            
        })
    }

    const procesVolumeDeBiometria = () => {
        api.get('dashboard/v3/get_volume_biometria').then((response) => {
            const dadosTransformados: any = []
    
            response.data?.forEach((dados: any) => {
                dadosTransformados.push(dados.total);
            });
    
            setVolumeDeBiometrias(dadosTransformados);
        })
    }

  
    useEffect(() => {
        processVolumeLiveness();
        procesVolumeDeBiometria();
    }, []);

    return <PageStructureWrapper title="Volume de Serviços">
        <ConsultaResultWrapper>
            <Section>
                <h3>Liveness e Biometria por Dia</h3>
                <GraphicsWrapper>
                    <MonthLine>
                        <VolumeDeServicos data={{liveness: volumeDeLiveness, biometria: volumeDeBiometrias}} />
                    </MonthLine>
                </GraphicsWrapper>
            </Section>
            {/* <Section>
                <h3>Biometria por Dia</h3>
                <GraphicsWrapper>
                    <MonthLine>
                        <VolumeDeBiometrias data={volumeDeBiometrias} />
                    </MonthLine>
                </GraphicsWrapper>
            </Section> */}
        </ConsultaResultWrapper>
    </PageStructureWrapper>
}