import { registerLocale } from "react-datepicker";
import { InputWrapper, StyledDateRangePicker } from "./style";
import ptBR from 'date-fns/locale/pt-BR';

interface DateRangeInputProps {
    titleHeader?: string;
    className?: string;
    startDate: Date | null;
    endDate: Date | null;
    setStartDate: (date: Date | null) => void;
    setEndDate: (date: Date | null) => void;
  }

registerLocale('pt-BR', ptBR);

const DateRangeInput: React.FC<DateRangeInputProps> = ({ titleHeader, className,
    startDate, setStartDate, endDate, setEndDate, ...rest }: DateRangeInputProps) => {
  
    return (
      <InputWrapper className={className}>
        {titleHeader && <span>{titleHeader}</span>}
        <StyledDateRangePicker
          selectsRange
          startDate={startDate}
          endDate={endDate}
          onChange={(dates: [Date | null, Date | null]) => {
            if (Array.isArray(dates)) {
              setStartDate(dates[0]);
              setEndDate(dates[1]);
            }
          }}
          locale="pt-BR"
          dateFormat="dd/MM/yyyy"
          {...rest}
        />
      </InputWrapper>
    );
  };
  
  export default DateRangeInput;