import Chart, { ChartConfiguration, ChartData } from 'chart.js/auto';
import React, { useEffect, useRef } from 'react';

interface LineChartProps {
  data: {
    total: number[];
    targetWeek?: number;
    targetWeekend?: number;
    monthlyChange?: number[];
  };
}

const VolumeProducaoDiaria: React.FC<LineChartProps> = ({ data }) => {
  const chartRef = useRef<HTMLCanvasElement>(null);
  const chartInstance = useRef<Chart<'bar' | 'line', (number | undefined)[], string> | null>(null);

  useEffect(() => {
    if (chartRef && chartRef.current) {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }

      const ctx = chartRef.current.getContext('2d');
      if (ctx) {
        const visibilityFactor = 1; // Factor to scale the percentage for display

        const chartData: ChartData<'bar' | 'line', (number | undefined)[], string> = {
          labels: Array.from({ length: data?.total?.length }, (_, i) => (i + 1).toString()),
          datasets: [
            {
              label: 'Finalizadas',
              data: data.total,
              borderColor: '#485184',
              backgroundColor: '#485184',
              borderWidth: 2,
              type: 'bar',
              stack: 'combined',
            },
          ],
        };

        if (data?.targetWeek) {
          chartData.datasets.push({
            label: 'Média Semanal dos últimos 3 meses',
            data: Array(data.total.length).fill(data.targetWeek),
            borderColor: '#FF0000',
            backgroundColor: 'rgba(255, 0, 0, 0.5)',
            borderWidth: 2,
            type: 'line',
            fill: false,
          });
        }

        if (data?.targetWeekend) {
          chartData.datasets.push({
            label: 'Média Final de Semana dos últimos 3 meses',
            data: Array(data.total.length).fill(data.targetWeekend),
            borderColor: '#ff9900',
            backgroundColor: 'rgba(255, 153, 0, 0.5)',
            borderWidth: 2,
            type: 'line',
            fill: false,
          });
        }

        if (data?.monthlyChange) {
          let myData = data.total.slice(0, new Date().getMonth() + 1);

          chartData.datasets.push({
            label: 'Variação Mensal',
            data: myData,
            borderColor: '#00FF00',
            backgroundColor: 'rgba(0, 255, 0, 0.5)',
            borderWidth: 2,
            type: 'line',
            fill: false,
            yAxisID: 'y',
          })
        }

        chartInstance.current = new Chart(ctx, {
          type: 'bar',
          data: chartData,
          options: {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              x: {
                stacked: true,
              },
              y: {
                beginAtZero: true,
              },
            },
            plugins: {
              tooltip: {
                callbacks: {
                  label: function (context) {
                    let label = context.dataset.label || '';
                    if (context.dataset.label === 'Variação Mensal') {
                      const currentIndex = context.dataIndex;
                      const currentValue = context.raw as number;
                      if (currentIndex === 0) {
                        label += `: 0.0%`;
                      } else {
                        const previousValue = data.total[currentIndex - 1] || 0;
                        const percentageChange = ((currentValue - previousValue) / previousValue) * 100;
                        label += `: ${percentageChange.toFixed(1)}%`;
                      }
                    } else if (context.parsed.y !== null) {
                      label += `: ${context.parsed.y.toLocaleString('pt-BR', { maximumFractionDigits: 0, minimumFractionDigits: 0 })}`;
                    }
                    return label;
                  },
                },
              },
            },
          },
        } as ChartConfiguration<'bar' | 'line', (number | undefined)[], string>);
      }
    }

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [data]);

  return <canvas ref={chartRef} />;
};

export default VolumeProducaoDiaria;
