import { useEffect, useMemo, useState } from "react";
import { FiDownload } from "react-icons/fi";
import { toast } from "react-toastify";
import Button from "../../../components/Button";
import CustomTable from "../../../components/CustomTable";
import DateRangeInput from "../../../components/DatePicker";
import DropDown from "../../../components/Dropdown";
import Input from "../../../components/Input";
import { PageStructureWrapper } from "../../../components/PageStructureWrapper";
import api from "../../../service/api";
import { formatDate } from "../../../utils/Date";
import { toastStyle } from "../../../utils/toastStyles";
import { ConsultaPropostasWrapper, ConsultaResultWrapper, SearchFields, SearchWrapper } from "../../ConsutaDePropostas/style";

export const RelatorioSerproPage = () => {
  const [openModal, setOpenModal] = useState(false);
  const [typeFilter, setTypeFilter] = useState<string>('maior_que');

  const columnsProposal = useMemo(
    () => [
      {
        Header: 'Proposta',
        accessor: 'proposal',
      },
      {
        Header: 'Similaridade',
        accessor: 'similarity',
        Cell: (data: any) => (
          <span>{data.value}%</span>
        ),
      },
      {
        Header: 'Data e Hora',
        accessor: 'datetime',
      }
    ],
    []
  );


  const columnMinWidths = { firstName: '20px' };

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [time, setTime] = useState<any>(10);
  const [proposalsReport, setProposalsReport] = useState([]);
  const [numeroProposta, setNumeroProposta] = useState<string>('');
  const [numeroContrato, setNumeroContrato] = useState<string>('');
  const [cpfCnpj, setCpfCnpj] = useState<string>('');
  const [filteredData, setFilteredData] = useState<any[]>([]);

  useEffect(() => {
    if (startDate && endDate && proposalsReport) {
      setFilteredData(proposalsReport);
    }
  }, [startDate, endDate, proposalsReport]);

  const applyFilters = () => {
    let filteredResult = proposalsReport;

    if (numeroProposta && numeroProposta.length === 8) {
      filteredResult = filteredResult.filter((item: any) =>
        item.proposal.includes(numeroProposta)
      );
    }

    // if (numeroContrato && numeroContrato.length === 10) {
    //   filteredResult = filteredResult.filter((item: any) =>
    //     item.contract.includes(numeroContrato)
    //   );
    // }

    // if (cpfCnpj && (cpfCnpj.length === 11 || cpfCnpj.length === 14)) {
    //   filteredResult = filteredResult.filter((item: any) =>
    //     item.document.includes(cpfCnpj)
    //   );
    // }

    setFilteredData(filteredResult);
  };

  const clearFilters = () => {
    setNumeroProposta('');
    setNumeroContrato('');
    setCpfCnpj('');
    setFilteredData(proposalsReport);
  }

  useEffect(() => {
    if (numeroContrato || numeroProposta || cpfCnpj) {
      applyFilters()
    } else {
      clearFilters()
    }

  }, [numeroContrato, numeroProposta, cpfCnpj])

  useEffect(() => {
    if (startDate && endDate && typeFilter) {
      toast.loading("Buscando dados...", { style: toastStyle })
      let endDateMoreOneDay = new Date(endDate)
      endDateMoreOneDay.setDate(endDate.getDate() + 1)
      api.get(`/dashboard/v3/get_proposals_with_high_similarity_attempts/${formatDate(startDate)}/${formatDate(endDateMoreOneDay)}/${time}/${typeFilter}`).then(response => {
        console.log(response.data)
        setProposalsReport(response.data)
        toast.dismiss()
      }).catch((err: any) => {
        toast.error("Erro ao buscar dados " + err)
      })
    }
  }, [startDate, endDate, time, typeFilter])

  const generateCSV = () => {
    const formatNumber = (value: any) => {
      if (!value)
        return value;

      return value.toLocaleString('pt-BR');
    };

    const csvContent =
      columnsProposal.map((col: any) => col.Header).join(';') +
      '\n' +
      proposalsReport
        .map((row: any) =>
          columnsProposal
            .map((col) => {
              const value = row[col.accessor];
              const formattedValue = formatNumber(value);
              const stringValue = typeof formattedValue === 'string' ? formattedValue : String(formattedValue);
              const encodedValue = stringValue.replace(/"/g, '""'); // Escapar as aspas
              return `"${encodedValue}"`;
            })
            .join(';')
        )
        .join('\n');

    const csvData = new Blob(['\ufeff', csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(csvData);

    link.setAttribute('href', url);
    link.setAttribute('download', `${startDate?.toLocaleDateString()}_${endDate?.toLocaleDateString()}.csv`);
    link.style.visibility = 'hidden';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return <PageStructureWrapper title="Propostas com Biometria">
    <ConsultaPropostasWrapper>
      <SearchWrapper>
        <SearchFields>
          <DateRangeInput titleHeader="Datas:"
            startDate={startDate} setStartDate={setStartDate}
            endDate={endDate} setEndDate={setEndDate}
          />
          <DropDown titleHeader="Tipo" options={[
            { label: 'Maior que', value: 'maior_que' },
            { label: 'Maior igual que', value: 'maior_igual_que' },
            { label: 'Igual', value: 'igual' },
            { label: 'Menor que', value: 'menor_que' },
            { label: 'Menor igual que', value: 'menor_igual_que' },
          ]} onChange={(e) => { console.log(e); setTypeFilter(e) }} />
          <DropDown titleHeader="Similaridade" options={[
            { label: '70%', value: '70' },
            { label: '60%', value: '60' },
            { label: '50%', value: '50' },
            { label: '40%', value: '40' },
            { label: '30%', value: '30' },
            { label: '20%', value: '20' },
            { label: '10%', value: '10' }
          ]} onChange={(e) => { console.log(e); setTime(e) }} />
          <Input titleHeader="Número da Proposta:" placeholder="Ex: 123456" onChange={(e) => setNumeroProposta(e.target.value)} disabled={!(startDate || endDate)} />
          {/* <Input titleHeader="Número do Contrato:" placeholder="Ex: 123456" onChange={(e) => setNumeroContrato(e.target.value)} disabled={!(startDate || endDate)} />
          <Input titleHeader="CPF/CNPJ:" placeholder="Ex: 1234567890" onChange={(e) => setCpfCnpj(e.target.value)} disabled={!(startDate || endDate)} /> */}
        </SearchFields>
        <Button onClick={generateCSV} leftIcon={<FiDownload />} label="Download" theme="primary" height="40px" />
      </SearchWrapper>
      <ConsultaResultWrapper onClick={() => setOpenModal(!openModal)}>
        {startDate && endDate && proposalsReport &&
          <CustomTable maxHeight="calc(100vh - 300px)" minWidth="100%"
            columns={columnsProposal}
            data={filteredData}
            columnMinWidths={columnMinWidths} />}
      </ConsultaResultWrapper>
    </ConsultaPropostasWrapper>
  </PageStructureWrapper>
}