import styled from "styled-components";

export const StepWrapper = styled.div<{ align?: 'center' | 'start' | 'end' }>`
    display: flex;
    justify-content: ${props => props.align && props.align === 'start' ? 'flex-start' : 'center'};
    align-items: ${props => props.align || 'center'};
    flex-direction: column;
    width: 100px;
    text-align: center;

    span {
        font-size: 12px;
    }
`

const themeStep = {
    P: "#ccc",
    error: "#DB4141",
    D: "#5FC53B",
    W: "#FFD700"
}

interface StepProps {
    status: keyof typeof themeStep,
    size?: string;

}

export const Step = styled.div<StepProps>`
    width: ${props => props.size || "60px"};
    height: ${props => props.size || "60px"};
    border-radius: 50%;
    background-color: ${props => themeStep[props.status]};
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
`
