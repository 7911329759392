import Chart, { ChartConfiguration } from 'chart.js/auto';
import React, { useEffect, useRef } from 'react';

interface LineChartProps {
  data: { date: string; average_duration_hours: number; proposalCount: number }[];
}

const VolumeDeGeral: React.FC<LineChartProps> = ({ data }) => {
  const chartRef = useRef<HTMLCanvasElement>(null);
  const chartInstance = useRef<Chart<'bar', {x: string, y: number, proposalCount: number}[], string> | null>(null);

  useEffect(() => {
    if (chartRef.current) {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }

      const ctx = chartRef.current.getContext('2d');
      if (ctx) {
        chartInstance.current = new Chart(ctx, {
          type: 'bar',
          data: {
            labels: data.map((item) => item.date),
            datasets: [
              {
                label: 'Sla',
                data: data.map((item) => ({
                  x: item.date,
                  y: item.average_duration_hours,
                  proposalCount: item.proposalCount // Armazenando a contagem de propostas junto aos dados
                })),
                borderColor: '#009688',
                backgroundColor: '#009688',
                borderWidth: 2,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              y: {
                beginAtZero: true,
              },
            },
            plugins: {
              tooltip: {
                callbacks: {
                  label: (context) => {
                    const value = context.parsed.y;
                    const proposalCount = (context.raw as { proposalCount: number })?.proposalCount;

                    const hours = Math.floor(value);
                    const minutes = Math.floor((value - hours) * 60);
                    const seconds = Math.floor(((value - hours) * 60 - minutes) * 60);

                    const formattedDuration = `${hours} Horas ${minutes} Min ${seconds} Seg`;

                    // Exibindo a quantidade de propostas
                    return `${formattedDuration} - Propostas: ${proposalCount}`;
                  },
                },
              },
              legend: {
                display: false,
              },
            },
          },
        } as ChartConfiguration<'bar', {x: string, y: number, proposalCount: number}[], string>);
      }
    }

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [data]);

  return <canvas ref={chartRef} />;
};

export default VolumeDeGeral;
