import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { useTable, Column, TableInstance } from 'react-table';
import { StickyHeader, Table, TableBody, TableContainer, TableHeader, Title } from './style';

interface CustomTableProps {
  columns: Array<Column<any>>;
  data: Array<object>;
  columnMinWidths: { [key: string]: string };
  title?: string;
  maxHeight?: string;
  minWidth?: string;
  onRowClick?: (row: any) => void;
}

const CustomTable = ({ columns, data, columnMinWidths, title, maxHeight, minWidth, onRowClick }: CustomTableProps) => {
  const tableInstance: TableInstance<any> = useTable({ columns, data });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  const [displayData, setDisplayData] = useState<Array<any>>([]);
  const [visibleCount, setVisibleCount] = useState<number>(20);

  useEffect(() => {
    setDisplayData(data.slice(0, visibleCount));
  }, [data, visibleCount]);

  const observer = useRef<IntersectionObserver | null>(null);

  const lastRowRef = useCallback(
    (node: HTMLTableRowElement | null) => {
      if (node) {
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver((entries) => {
          const lastRow = entries[0];
          if (lastRow.isIntersecting && visibleCount < data.length) {
            setVisibleCount((prevCount) => prevCount + 20);
          }
        });
        observer.current.observe(node);
      }
    },
    [data.length, visibleCount]
  );

  return (
    <>
      {title && <Title>{title}</Title>}
      <TableContainer maxHeight={maxHeight}>
        <Table {...getTableProps()} minWidth={minWidth}>
          <StickyHeader>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    style={{
                      minWidth: columnMinWidths[column.id] || 'auto',
                    }}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </StickyHeader>
          <TableBody {...getTableBodyProps()}>
            {displayData.map((row, index) => {
              const rowData = tableInstance.rows[index];
              if (rowData) {
                prepareRow(rowData);
                return (
                  <tr
                  onClick={() => onRowClick && onRowClick(row)}
                    {...rowData.getRowProps()}
                    ref={(index + 1 === visibleCount) ? lastRowRef : null}
                  >
                    {rowData.cells.map((cell) => (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    ))}
                  </tr>
                );
              }
              return null; // Evita renderizar se rowData for indefinido
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default CustomTable;