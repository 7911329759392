import { Navigate, Outlet } from 'react-router-dom';
import { toast } from "react-toastify";

const endSession = () => {
    if(!localStorage.getItem('fs'))
        return true;
    const endDate = new Date(new Date(localStorage.getItem('fs') || '').getTime() + 20*60000)
    console.log('endDate', endDate)
    console.log('Ends', new Date() > endDate)
    return new Date() > endDate
    
}

const PrivateRoute = () => {
    const auth = localStorage.getItem('token') != null;
    console.log('auth', !auth)
    console.log('endSession', endSession())
    if (!auth || endSession()) {
        toast.warning('Você não está logado!');
        return <Navigate to="/" />;
    } else {
        localStorage.setItem('fs', new Date().toString())
        return <Outlet />;
    }
}

export default PrivateRoute;