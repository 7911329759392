import styled from "styled-components";

export const InfoWrapper = styled.div`
    display: flex;
    gap: 20px;
    align-content: center;
    justify-content: center;
` 

export const Info = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;

    span:first-child {
        font-weight: 500;
    }
`

export const ImagesWrapper = styled.div`
    display: flex;
    gap: 20px;
    align-items: center;
    overflow: auto;
    flex-wrap: wrap;
    padding: 10px;    
    border-radius: 8px;
    justify-content: center;
`

export const Image = styled.img`
    max-width: 400px;
    border-radius: 8px;
`

export const WarningMessage = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #FFC107;
    padding: 10px;
    border-radius: 16px;
    font-weight: 600;
    color: #000;
`

export const ImageContent  = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;

    span {
        font-size: 14px;
        text-align: center;
    }
` 