import Chart, { ChartConfiguration } from 'chart.js/auto';
import React, { useEffect, useRef } from 'react';
import { formatHourToString } from '../../../utils/Date';

interface LineChartProps {
  data: {
    sla: number[];
  };
}

const SlaGeral: React.FC<LineChartProps> = ({ data }) => {
  const chartRef = useRef<HTMLCanvasElement>(null);
  const chartInstance = useRef<Chart<'line', unknown> | null>(null);

  useEffect(() => {
    if (chartRef && chartRef.current) {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }

      const ctx = chartRef.current.getContext('2d');
      if (ctx) {
        chartInstance.current = new Chart(ctx, {
          type: 'line',
          data: {
            labels: Array.from({ length: data?.sla?.length }, (_, i) => (i + 1).toString()), // Dias do mês
            datasets: [
              {
                label: 'Minutos de SLA',
                data: data.sla,
                borderColor: '#009688',
                backgroundColor: '#009688',
                borderWidth: 2,
                fill: false,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              y: {
                beginAtZero: true,
              },
            },
            plugins: {
              tooltip: {
                callbacks: {
                  label: (context) => {
                    const label = context.dataset.label || '';
                    const value = context.parsed.y || 0;
                    return ` ${formatHourToString(value)}`;
                  },
                },
              },
            legend: {
              display: false, 
            },
            },
          },
        } as ChartConfiguration<'line', number[], string>);
      }
    }

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [data]);

  return <canvas ref={chartRef} />;
};

export default SlaGeral;
