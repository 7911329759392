
import React from 'react';
import { FunnelChart } from 'react-funnel-pipeline';
import 'react-funnel-pipeline/dist/index.css';

interface LineChartProps {
  data: any
}

const CustomFunnel: React.FC<LineChartProps> = ({ data }) => {

  return (
    <FunnelChart
      data={data}
      showNames={true}
      getToolTip={(data: any) => {
        return `${data.name} : ${data.value}`;
      }}
      pallette={['#686c94', '#686c94', '#686c94', '#686c94', '#686c94']}
    />
  )
}



export default CustomFunnel;
